import React from 'react';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import { loginUrl } from './url';
import UserProfile from './UserProfile';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import FaceRoundedIcon from '@material-ui/icons/FaceRounded';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';


Axios.defaults.withCredentials = true;

const Header = (props) => {
    const adminPage = window.location.pathname === '/app' ? false : true;
    const [labelMsg, setLabelMsg] = React.useState(undefined);
    const [openProfile, setOpenProfile] = React.useState(false);
    const [snackState, setSnackState] = React.useState({ open: false });

    React.useEffect(() => {
        Axios.post(loginUrl.getUserRole)
            .then(res => {
                if (!res.err) {
                    const { userRole } = res.data;
                    setLabelMsg(makeLabel(userRole));
                } else {
                    setLabelMsg(undefined);
                }
            })
            .catch(err => {
                console.error(err);
            })
    }, []);

    const makeLabel = (userRole) => {
        if (!userRole) {
            return undefined;
        }

        const roles = Object.keys(userRole);

        return userRole ? roles[0] !== 'admin' ? `To ${userRole[roles[0]].title} Manage` : 'To ' + userRole[roles[0]].title : undefined;
    }

    //logout
    const handleLogOut = async () => {
        //api call to session destroy
        try {
            let res = await Axios.post(loginUrl.logout);
            if (res.data) {
                props.history.push('/');
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleChangePage = () => {
        adminPage ? props.history.push('/app') : props.history.push('/admin');
    }

    const handleProfile = {
        open: () => setOpenProfile(true),
        close: () => setOpenProfile(false)
    }

    const closeSnackBar = () => {
        setSnackState({ ...snackState, open: false });
    }

    return (
        <React.Fragment>
            <AppBar position='static' style={{ backgroundColor: 'cadetblue' }}>
                <Toolbar>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        Timesheet
                    </Typography>
                    {labelMsg ?
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={adminPage}
                                    onChange={handleChangePage}
                                />
                            }
                            label={adminPage ? 'To User' : labelMsg}
                        />
                        : null
                    }
                    <Tooltip
                        key="open-profile-button"
                        title="Change Profile"
                        arrow={true}
                    >
                        <IconButton
                            color="inherit"
                            onClick={handleProfile.open}
                        >
                            <FaceRoundedIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        key="logout-button"
                        title="Logout"
                        arrow={true}
                    >
                        <IconButton
                            color="inherit"
                            onClick={handleLogOut}
                        >
                            <LockOpenRoundedIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            {openProfile ? <UserProfile openProfile={openProfile} closeProfile={handleProfile.close} setSnack={setSnackState}/> : null}
            <Snackbar
                open={snackState.open}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={closeSnackBar}
            >
                <Alert severity={snackState.type || 'info'} onClose={closeSnackBar}>
                    {snackState.message}
                </Alert>
            </Snackbar>
        </React.Fragment>

    )
}

export default withRouter(Header);