export const fieldErrorMsg = {
    length: '두 글자 이하로 입력하십시오.',
    number: '숫자를 입력하십시오',
    format: '0.5 단위로 입력하십시오.',
    range: '0 초과 24 이하의 값을 입력하십시오.',
    overTime: '한 주 근무시간 합산이 52시간이 초과되지 않도록 입력하십시오.'
};

export const loginMsg = {
    missingField : ( field ) => {
        let fieldNm;
        if (field === 'id') {
            fieldNm = '아이디'
        } else if (field === 'pw') {
            fieldNm = '패스워드'
        } else {
            return '';
        }
        return `${fieldNm}를 입력해주세요.`;
    },
    invalidId : '아이디가 존재하지 않습니다.',
    invalidPw : '비밀번호가 일치하지 않습니다. 계정 정보를 확인하십시오',
    internalError : '로그인 할 수 없습니다. 관리자에게 문의하십시오.',
    noGr : '그룹 정보가 존재하지 않습니다.',
    noSession : '세션 정보가 만료되었습니다. 다시 로그인 해주세요.',
    mustChangeExpiredPw : '비밀번호를 변경하지 않는 경우 로그인 할 수 없습니다.',
    required : '필수 입력 정보입니다.',
    length: '패스워드는 5글자 이상 입력하십시오.',
    unmatched: '입력한 패스워드와 일치하지 않습니다.',
    unchanged: '현재 사용 중인 패스워드와 다른 패스워드를 입력하십시오.',
    includeId: '패스워드에는 사용자 아이디가 포함될 수 없습니다.',
    failChangePassword: '패스워드 변경에 실패하였습니다. 다시 시도해주시기 바랍니다.',
    passwordChanged: '패스워드를 변경하였습니다.',
    invalidEmail: '올바른 양식의 메일 주소를 입력하십시오.',
    invalidPattern: '올바른 양식으로 입력하십시오.',
    noCompanyEmail: '사내 이메일을 입력하십시오. ',
    duplicatedId: '이미 사용 중인 ID입니다.',
    invalidPermissions: '권한명과 권한 범위를 모두 지정하십시오.'
};

export const UpdateUserProfileMessage = {
    failedUpdateSession: '정보를 업데이트하였으나 현재 세션 적용에 실패해 다음 로그인부터 정보가 적용됩니다.',
    failedUpdateUser: '정보 변경에 실패했습니다.',
    updated: '정보를 변경하였습니다.',
}

export const role_label = {
    admin : '전체 운영',
    adminHome: '관리자 홈',
    project: '프로젝트 관리',
    timesheet: '월별 사용자 타임시트 관리',
    userStatus: '사용자 타임시트 상태 확인'
}


export const userTimeTableMsg = {
    noDataToSave: '하나 이상의 데이터를 수정한 후 저장하십시오.',
    saveFieldError: '입력 정보를 올바르게 수정한 후 저장하십시오.',
    resubmitDialogTitle: '데이터를 관리자에게 다시 제출합니다.',
    resubmitDialogContent: `이미 관리자에게 제출한 데이터를 수정하는 경우, 새로운 값을 관리자에게 송신합니다.`,
    internalError: '서버 내부 오류가 발생했습니다. 같은 오류가 반복될 경우 관리자에게 문의하십시오.',
    noAffectedResults : '수정된 값이 존재하지 않습니다. 다시 시도해주십시오.',
    resetAlertMsgTitle: '저장하지 않은 데이터가 초기화 됩니다.',
    resetAlertMsgContent: '타임시트 표시 프로젝트를 편집하면 저장하지 않은 입력 데이터가 모두 초기화 됩니다. '
    + '프로젝트 편집을 계속 진행하시겠습니까?',
    failToGetTableData: '정보를 가져올 수 없습니다.',
    noDataToSubmit: '제출할 데이터가 존재하지 않습니다. 근무 시간 데이터 작성 완료 후 다시 시도하십시오.',
    invalidResultCnt : '작업을 실패하였습니다. 다시 시도해 주십시오.',
    save : '데이터가 저장되었습니다.',  
    resubmit: `새로운 데이터를 운영자에게 다시 제출하였습니다.`,
    submitted: (yrMon) => `${yrMon}의 데이터를 운영자에게 제출하였습니다.`,
    submitDialogTitle: (yrMon) => `${yrMon}의 데이터를 관리자에게 제출합니다`,
    submitDialogContent: `한 달 간의 프로젝트 당 근무 시간의 통계치가 계산되어 관리자에게 전달됩니다.  
    제출 후 관리자가 데이터를 SAP에 전송하기 전까지는 저장 버튼을 눌러 수정할 수 있습니다.`,
    saveBeforeAction: '저장하지 않은 데이터가 존재합니다. 저장 후 제출을 진행하십시오.',
    requesting: '작업을 진행 중입니다. 먼저 요청한 작업이 끝난 후 다시 시도하십시오.',
    submittedData: '데이터가 재무회계팀에 이미 제출되어 수정할 수 없습니다.',
    submitValidate: '세 달 이전 혹은 익월 이후의 데이터는 제출 처리할 수 없습니다.',
};

export const userSubmitButtonTooltipMsg = (firstDayOfWeek) => {
    const month = new Date(firstDayOfWeek).getMonth() + 1
    return `제출 버튼을 눌러 ${month}월 데이터를 제출`;
}

export const addPrjCdDialogMsg = {
    error: {
        noDeptSelected: '부서를 먼저 선택하십시오.',
        duplicatedReq: '이미 등록 중입니다. 먼저 요청한 작업이 끝난 후 다시 시도하십시오.',
        require: "필수 항목을 올바르게 작성해 주시기 바랍니다.",
        createCd: "프로젝트 코드 생성에 실패했습니다.",
        createPrj : "새 프로젝트를 추가하는데 실패했습니다.",
        timeout: "Your request timed out. Please retry.",
        changeDept : "부서 정보를 찾을 수 없습니다. 다시 시도하십시오.",
        noGroup : "선택한 부문 혹은 부서가 존재하지 않습니다. 관리자에게 문의하십시오.",
        pct: "퍼센트의 합계가 100이 되도록 입력하십시오.",
        internalError: "서버 내부 오류가 발생했습니다. 다시 시도하십시오.",
    },
   successMsg : prjCd => `새로운 프로젝트 (${prjCd})가 생성되었습니다.`,
};

export const projectCodeManageMsg = {
    openAddPrj: "프로젝트를 편집하는 동안에는 프로젝트 추가 창을 열 수 없습니다.",
    //fieldError: '옳지 않은 입력 사항이 있습니다.',
    editMsg : ( prjCd, msgCd ) => {
        switch(msgCd) {
            case 'editErr':
                return "수정을 실패했습니다. 처음부터 다시 시도하십시오. ";
            case 'alreadyUpdated':
                return `해당 데이터(${prjCd})는 다른 유저가 이미 수정하였습니다. 처음부터 다시 시도해주세요.`;
            case 'editPrj':
                return `프로젝트 ${prjCd}를 수정했습니다.`;
            case 'deleteErr':
                return "삭제를 실패했습니다."
            case 'deletePrj':
                return `프로젝트 ${prjCd}를 삭제했습니다.`
            default:
                return 'Unknown';
        }
    },
    fieldError: {
        getFieldNm: (field) => {
            switch (field) {
                case 'prjCd':
                    return '프로젝트 Code';
                case 'sapCds':
                    return 'SAP Code'
                case 'prjNm':
                    return '프로젝트 명칭';
                case 'startDate':
                    return '시작시점'
                case 'endDate':
                    return '종료시점'
                default:
                    return '';
            }
        },
        getErrorMsg : {
            required : '필수 항목을 입력하십시오',
            percent: '퍼센트의 합이 100이 되도록 입력하십시오',
            validDate: '올바른 형식의 날짜를 입력하십시오',
            dateBefore: '종료시점보다 이전의 날짜를 입력하십시오',
            dateAfter: '시작시점 이후의 날짜를 입력하십시오'
        }
    }
}

export const adminTimesheetMsg = {
    noRecords : '표시할 데이터가 존재하지 않습니다.',
    tableModeLabel : { user : '성명', project: '프로젝트' },
}

export const exptModalMsg = {
    noUserSelected: '선택된 사용자가 없습니다.',
    noGrSelected : '선택된 그룹이 없습니다.',
    exptWarning: invalidList =>  {
        let msg = ''
        const { unchecked, submitted } = invalidList;
        if(Object.keys(unchecked).length) { 
            msg += `미완성 ${Object.keys(unchecked).length}명 `
        }

        if(Object.keys(submitted).length) {
            msg += `제출 ${Object.keys(submitted).length}명 `
        }

        return msg.slice(0, -1).concat('의 데이터가 존재합니다. 제외하고 Export 하시겠습니까?');
    },
    noListItem: '타임시트 데이터를 제출할 사용자, 혹은 그룹을 선택해 주십시오',
    exptSuccess: '성공적으로 데이터를 SAP에 내보냈습니다.',
    error: {
        noValidUser: 'SAP에 송출가능한 상태의 사용자가 존재하지 않습니다. 데이터 작성이 완료되었고, 아직 송출되지 않은 사용자 데이터가 존재하는 지 확인하십시오',
        userAlreadyExists : '제출 대상의 근무 데이터가 이미 SAP에 존재하여 데이터를 송출할 수 없습니다.',
        noTimesheetData : '타임시트 데이터가 존재하지 않아 작업을 중단하였습니다.',
        unexpectedResult :  '데이터가 다른 곳에서 갱신되어 데이터 내보내기를 중단했습니다. 확인 후 작업을 진행하십시오',
        internalError: '서버 내부 오류로 인해 데이터 내보내기에 실패하였습니다.',
        duplicateReq: '내보내기 작업을 진행 중입니다. 먼저 요청한 작업이 끝난 후 다시 시도하십시오.',
        dataFormatUnmatched : '근무율 서식이 이 달에 이미 저장된 SAP 데이터와 일치하지 않아 작업을 중단하였습니다.',
        undefined : '데이터 이상으로 데이터 내보내기에 실패하였습니다.',
        invalidUser : '타임시트 데이터 미제출, 혹은 SAP에 이미 데이터를 내보낸 사용자가 존재하여 데이터 송신을 진행하지 못했습니다. '
    }
}

export const userCheckStatMsg = {
    inaccessible: '더 이상 해당 화면에 접근할 수 없습니다.',
    notAdmin: '운영자 권한이 존재하지 않습니다.',
    noSession: '세션이 종료되었습니다. 다시 로그인 해주세요.',
}

export const userManagementMsg = {
    success: (type) => `사용자 ${type === 'add'  ? '추가' : '정보 수정'}에 성공했습니다.`,
    error: (type) => `사용자  ${type === 'add' ? '추가' : '정보'}에 실패했습니다.`,
}

export const groupManagementMsg = {
    success: '정보 수정을 완료하였습니다.',
    error: '정보 수정에 실패하였습니다.'
}

const actionMode = {
    delete: '삭제',
    edit: '수정',
    add: '추가'
}
export const exportScheduleMsg = {
    invalidRequest: '다른 사용자가 자동 송출 스케줄을 수정하였습니다. 새로고침 후 다시 시도하십시오',
    success: mode => `스케줄 ${actionMode[mode] ?? '변경'}에 성공하였습니다. `,
    fail: mode => `스케줄 ${actionMode[mode] ?? '변경'}에 실패하였습니다. `,
}

export const EditUserProfileDialogMsg = {
    fieldNm: {
        id: 'ID',
        email: '이메일',
        empNo: '사원번호',
        empNm: '사원명',
        npw: '비밀번호',
        cpw: '비밀번호 확인',
        department: '소속',
        permissions: '권한',
    },
    fieldError: '입력정보에 오류가 있어 작업을 수행할 수 없습니다.',
    TIMSDBError: `TIMS 3.0 데이터베이스 이상으로 작업을 수행할 수 없습니다. 
    TIMS 3.0의 데이터베이스와 사용자 테이블이 존재하는지 확인 후 작업을 계속 하십시오`,
    nonAffectedRowsOnTIMS: 'TIMS 3.0에 사용자 정보 입력 중 오류가 발생하였습니다. 관리자에게 문의하시기 바랍니다.',
    nonAffectedRows: '사용자 정보 입력 중 오류가 발생하였습니다. 관리자에게 문의하시기 바랍니다.',
    notAllUserRolesInserted: '사용자 접근 권한 수정 중 오류가 발생하였습니다. 관리자에게 문의하시기 바랍니다.',
    noDataToUpdate: '변경된 데이터가 존재하지 않아 수정을 진행할 수 없습니다.',
}