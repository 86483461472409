import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import Axios from 'axios';
import { loginMsg } from './Messages';
import { Link, Container, createTheme, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { styles } from '../styles/common/loginStyle'
import { MuiThemeProvider } from '@material-ui/core/styles';
import { loginUrl } from './url';
import withCacheBuster from './CacheBuster';
import { Fragment } from 'react';
Axios.defaults.withCredentials = true;

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            field: {},
            fieldError: {},
            auth: false,
            msg: '',
            openDialog: false,
            passField: {},
            passFieldError: {},
            // passErrMsg : 
        }
    }

    theme = createTheme({
        typography: { fontFamily: 'Roboto' }
    });

    componentDidMount() {
        //TODO:
        // if(this.props.location.state && this.props.location.state.key) {
        //     this.setState({msg : loginMsg[this.props.location.state.key]});
        //     return;
        // }

        if (this.props.location.search === "?redir=1") {
            this.setState({ msg: loginMsg.noSession });
            return;
        }

        this.handleGetSession()

    }

    async handleGetSession() {
        try {
            let res = await Axios.post(loginUrl.hasSession);
            if (res.data) {
                this.props.history.push("./app");
            }
        } catch (err) {
            console.error(err);
        }
    }

    handleTextChange(event) {
        const { id, value } = event.target;
        this.setState({
            field: { ...this.state.field, [id]: value }
        });
    }

    handleLogin = async () => {
        try {
            const { field } = this.state;
            const hasError = this.validateLogin(field);
            if (!hasError) {
                const res = await Axios.post(loginUrl.login, { field });
                let { result, msg, fieldError, daysFromPwdChanged } = res.data;

                if (daysFromPwdChanged) {
                    this.setState({
                        openDialog: true,
                        showPwdChangeForm: false,
                        daysFromPwdChanged
                    });
                    return;
                }

                if (result) {
                    this.setState({
                        redirect: result,
                    })
                    //this.props.history.push("./app");
                } else {
                    if (fieldError) {
                        let fe = { [fieldError]: loginMsg[msg] }
                        this.setState({ fieldError: fe });
                        return;
                    }

                    this.setState({ msg: loginMsg.internalError });
                }
                return;
            }

        } catch (err) {
            console.error(err);
            this.setState({ msg: loginMsg.internalError });
        }
    }

    validateLogin(field) {
        const keys = ["id", "pw"];
        let fieldError = {}
        for (let k of keys) {
            if (field[k] === null || field[k] === undefined || field[k] === '') {
                fieldError[k] = loginMsg.missingField(k);
            }
        }

        this.setState({ fieldError });
        return Object.keys(fieldError).length;
    }

    handleLoginKeyPress(event) {
        if (event.key === 'Enter') {
            this.handleLogin();
        }
    }

    handleCloseDialog = (shouldRedirect) => {
        const msg = shouldRedirect ? undefined : loginMsg.mustChangeExpiredPw;
        this.setState({
            passField: {},
            openDialog: false,
            redirect: Boolean(shouldRedirect),
            msg,
        })
    }

    handleClickToChangePwForm = () => {
        this.setState({
            showPwdChangeForm: true
        })
    }

    handleChangePw = (e) => {
        this.setState({
            passField: {
                ...this.state.passField,
                [e.target.id]: e.target.value
            }
        })
    }

    validateChangePwForm = (e) => {
        let { passFieldError, passField } = this.state;
        const { id, value } = e.target;
        if (!value) {
            passFieldError[id] = loginMsg.required;
        } else if (id === 'npw' && value.length < 5) {
            passFieldError[id] = loginMsg.length;
        } else if (id === 'cpw' && passField.npw !== passField.cpw) {
            passFieldError[id] = loginMsg.unmatched;
        } else {
            delete passFieldError[id]
        }

        this.setState({ passFieldError })
    }

    handleChangePassKeyPress(event) {
        if (event.key === 'Enter') {
            this.handleClickChangePw();
        }
    }

    handleClickChangePw = async () => {
        try {
            //send request
            const { field, passField, daysFromPwdChanged } = this.state;
            const res = await Axios.post(loginUrl.changePasswordAndSetSession, { field, passField, daysFromPwdChanged });
            const { passFieldError, error, msg } = res.data;

            //error == true && msg exists : DB update 실패
            //error == true && passFieldError exists : new-password, confirm-password의 validation 오류
            if (error) {
                if (msg) {
                    //update 실패 ->TODO: 어디 메시지 표시할 것인지?
                    this.setState({ passFormMsg: loginMsg[msg] })

                } else {
                    const fieldErrorMsg = {};
                    Object.entries(passFieldError).forEach(([field, error]) => {
                        fieldErrorMsg[field] = !error || !error.length
                            ? undefined
                            : (<Fragment>
                                {error.map(e => <p>{loginMsg[e]}</p>)}
                            </Fragment>)
                    });
                    this.setState({
                        passFieldError: fieldErrorMsg,
                        passFormMsg: null,
                    });
                }


            } else {

                this.setState({
                    passChanged: true
                })
                setTimeout(() => {
                    this.setState({
                        passFormMsg: null,
                        redirect: true,
                        //redirectState : { msg: loginMsg.passwordChanged }
                    })
                }, 1000)
            }
        } catch (e) {
            //status => error 로 response 받은 경우 :
            //field에 입력한 userId나 session 유실된 경우 혹은 password가 변경된 경우 등 발생
            //→ login부터 다시 시작할 것
            if (e.res && e.res.data && e.res.data.error) {
                this.setState({
                    openDialog: false,
                    msg: loginMsg.failChangePassword
                });
            } else {
                this.setState({
                    passFormMsg: loginMsg.failChangePassword
                });
            }
            console.error(e)
        }
    }

    render() {
        const { classes, loading, isLatestVersion, refreshCacheAndReload } = this.props;
        const { fieldError, redirect, daysFromPwdChanged, passFieldError, openDialog, msg } = this.state;

        if (redirect) {
            return <Redirect
                to={{
                    pathname: '/app',
                    state: this.state.redirectState
                }}
            />
        }

        if (!loading && !isLatestVersion) {
            console.log('Clearing cache and hard reloading...')
            refreshCacheAndReload();
        }

        return (
            <MuiThemeProvider theme={this.theme}>
                <Container
                    className={classes.container}
                    component="div"
                    maxWidth="sm"
                >
                    {/* <CssBaseline /> */}
                    <div className={classes.paper}>
                        <img
                            alt="logo"
                            src={process.env.PUBLIC_URL + '/logo_.png'}
                            className={classes.logo}
                        />

                        <Typography component="h1" variant="h4">
                            Sign in to Timesheet 1.0
                        </Typography>
                        {!msg
                            ? null
                            : (
                                <Typography className={classes.msg} variant="subtitle2" color="error">
                                    {msg}
                                </Typography>
                            )}
                        <form className={classes.form}>
                            <TextField
                                className={classes.formChild}
                                fullWidth
                                autoFocus
                                id="id"
                                label="ID"
                                error={Boolean(fieldError && fieldError.id)}
                                helperText={!fieldError || !fieldError.id ? null : fieldError.id}
                                variant="outlined"
                                onChange={this.handleTextChange.bind(this)}
                                onKeyPress={this.handleLoginKeyPress.bind(this)}
                                InputProps={{ className: classes.input }}
                            />
                            <TextField
                                className={classes.formChild}
                                fullWidth
                                id="pw"
                                label="Password"
                                type="password"
                                error={Boolean(fieldError && fieldError.pw)}
                                helperText={!fieldError || !fieldError.pw ? null : fieldError.pw}
                                variant="outlined"
                                onChange={this.handleTextChange.bind(this)}
                                onKeyPress={this.handleLoginKeyPress.bind(this)}
                                InputProps={{ className: classes.input }}
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                className={`${classes.formChild} ${classes.button}`}
                                onClick={this.handleLogin.bind(this)}
                                size="large"
                            >
                                sign in
                            </Button>
                            <Link
                                href="https://tims.tescom.org/"
                                className={`${classes.link} ${classes.formChild}`}
                            >
                                Go to TIMS
                            </Link>
                        </form>
                    </div>
                </Container>

                {/* Expired Password Change Request Dialog  */}
                {<Dialog open={openDialog} maxWidth='md'>
                    {!this.state.showPwdChangeForm ?
                        (
                            <Fragment>
                                <DialogTitle>패스워드 변경이 필요합니다.</DialogTitle>
                                <DialogContent className={classes.passChangeRequest}>
                                    <Typography variant="subtitle" >
                                        {`패스워드를 변경한 지 ${daysFromPwdChanged}일이 지났습니다.`}
                                        <br></br>
                                        {`패스워드를 변경하여 주시기 바랍니다.`}
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        key="to-change-pw"
                                        onClick={this.handleClickToChangePwForm.bind(this)}
                                    >
                                        OK
                                    </Button>
                                    {daysFromPwdChanged === 30 ?
                                        <Button
                                            variant="outlined"
                                            key="remind-me-later"
                                            onClick={this.handleCloseDialog.bind(this, true)}
                                        >
                                            Remind me later
                                        </Button> :
                                        <Button
                                            variant="outlined"
                                            key="cancel"
                                            onClick={this.handleCloseDialog.bind(this, false)}
                                        >
                                            cancel
                                        </Button>
                                    }
                                </DialogActions>
                            </Fragment>
                        ) :
                        (
                            !this.state.passChanged ?
                                <Fragment>
                                    <DialogTitle>패스워드 변경</DialogTitle>
                                    <DialogContent>
                                        {/* show error msg if update db failed */}
                                        {this.state.passFormMsg ?
                                            <Typography variant="subtitle2" color="error">
                                                {this.state.passFormMsg}
                                            </Typography>
                                            : undefined}
                                        <TextField
                                            fullWidth
                                            required
                                            autofocus
                                            key="npw"
                                            id="npw"
                                            label="패스워드"
                                            type="password"
                                            className={classes.pwdInput}
                                            onChange={this.handleChangePw.bind(this)}
                                            onBlur={this.validateChangePwForm.bind(this)}
                                            onKeyPress={this.handleChangePassKeyPress.bind(this)}
                                            error={Boolean(passFieldError && passFieldError.npw)}
                                            helperText={passFieldError && passFieldError.npw ? passFieldError.npw : null}
                                        />
                                        <TextField
                                            fullWidth
                                            required
                                            key="pwConfirm"
                                            id="cpw"
                                            label="패스워드 확인"
                                            type="password"
                                            className={classes.pwdInput}
                                            onChange={this.handleChangePw.bind(this)}
                                            onBlur={this.validateChangePwForm.bind(this)}
                                            onKeyPress={this.handleChangePassKeyPress.bind(this)}
                                            error={Boolean(passFieldError && passFieldError.cpw)}
                                            helperText={passFieldError && passFieldError.cpw ? passFieldError.cpw : null}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            variant="outlined"
                                            key="cancel-change-pw"
                                            onClick={this.handleCloseDialog.bind(this, daysFromPwdChanged > 30 ? false : true)}
                                        >
                                            {daysFromPwdChanged > 30 ? 'cancel' : 'change later'}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            key="save"
                                            onClick={this.handleClickChangePw.bind(this)}
                                        >
                                            save
                                        </Button>
                                    </DialogActions>
                                </Fragment>
                                : <DialogTitle>
                                    <span>✔</span> 패스워드 변경에 성공했습니다.
                                </DialogTitle>


                        )
                    }
                </Dialog>}

            </MuiThemeProvider>
        )
    }

}

// const loginCompo = process.env.NODE_ENV !== 'development' ?
//     withCacheBuster()(withStyles(styles)(Login)) :
//     withStyles(styles)(Login);
const loginCompo = withCacheBuster()(withStyles(styles)(Login));
export default loginCompo;