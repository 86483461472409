import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import { adminUrl } from '../common/url';
import { checkErr } from '../checkErr';
import { Button, makeStyles, Snackbar, TablePagination, Typography, } from '@material-ui/core';
import EditUserProfileDialog from './EditUserProfileDialog';
import MaterialTable, { MTableAction } from '@material-table/core';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { Alert } from '@material-ui/lab';

Axios.defaults.withCredentials = true;

const useStyles = makeStyles(theme => ({
  userStatusSpan: {
    height: '1em',
    borderRadius: '8px',
    padding: '2px 7px',
    fontSize: '11px',
    fontWeight: '500'
  },
  activeSpan : {
    color: '#2d8a8c',
    background: '#cdecec',
  }, 
  disabledSpan: {
    color: '#b72136',
    background: 'rgba(255, 72, 66, 0.16)',
  }
}))

const makeUserStatusSpan = (isUserDisabled) => {
  const userStatusSpan = {
    height: '1em',
    borderRadius: '8px',
    padding: '2px 7px',
    fontSize: '11px',
    fontWeight: '500'
  }
  const activeSpan = {
    color: '#2d8a8c',
    background: '#cdecec',
  }
  const disabledSpan = {
    color: '#b72136',
    background: 'rgba(255, 72, 66, 0.16)',
  }

  const makeStyle = () => {
    const color = isUserDisabled ? disabledSpan : activeSpan
    return {
      ...userStatusSpan,
      ...color
    }
  }
  return (
    <span style={makeStyle(isUserDisabled)}>
      {isUserDisabled ? 'DISABLED' : 'ACTIVE'}</span>
  )
}

// table options for user data table
const tableOptions = {
  search: true,
  maxBodyHeight: 'calc(100vh - 17em)',
  pageSize: 15,
  pageSizeOptions: [15, 20, 25],
  paginationType: 'stepped',
  headerStyle: { position: 'sticky', top: 0, backgroundColor: '#f5f5f5', fontWeight: 550, color: '#6c6c6c' },
}

//column options for user data table. Title values will be set as header
const tableColumns = [
  { title: '성명', field: 'empNm' },
  { title: 'ID', field: 'empId' },
  { title: '소속', field: 'grNm' },
  { title: '사번', field: 'empNo' },
  { title: 'Email', field: 'email' },
  { title: '상태', field: 'disabled', 
    render: rowData => makeUserStatusSpan(rowData.disabled)
},
]

const dialogMode = {
  editUser: "EDIT",
  addUser: "ADD"
}

const tableTitle = () => (
  <Typography variant="h6" style={{ fontWeight: 600, color: '#6c6c6c' }}>사용자 정보</Typography>
)
const ManageUsers = props => {
  const { viewId } = props;
  const [openAddUserDialog, setAddUserOpenDialog] = useState(false);
  const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
  const [snackState, setSnackState] = useState({ open: false });
  const [users, setUsers] = useState();
  const [editUserData, setEditUserData] = useState();
  const [groupInfo, setGroupInfo] = useState();
  const [reloadUsers, setReloadUsers] = useState(false);
  
  useEffect(() => {
    if (!groupInfo) {
      Axios.post(adminUrl.getGroupsForNav, { viewId, draggable: true }).then(res => {
        setGroupInfo(res.data.groups)
      }).catch(err => {
        console.error(err);
      });
    }
  }, [groupInfo, viewId]);

  const getUsersForTableData = useCallback(async () => {
    try {
      const res = await Axios.post(adminUrl.getUsersForUserManageTable);
      setEditUserData()
      setUsers(res.data);
    } catch (err) {
      checkErr(err, props.history)
      console.error(err)
    }
  }, [props.history])

  // add flg to update
  useEffect(() => {
    getUsersForTableData();
  }, [reloadUsers, getUsersForTableData])

  const handleDialog = {
    openAddUser: () => setAddUserOpenDialog(true),
    closeAddUser: () => setAddUserOpenDialog(false),
    openEditUser: () => setOpenEditUserDialog(true),
    closeEditUser: () => setOpenEditUserDialog(false),
  }

  const closeSnackBar = () => {
    setSnackState(prev => ({ ...prev, open: false }));
  }

const actions = [
    {
      icon: 'edit',
      tooltip: '회원 정보 수정',
      onClick: (e, rowData) => { 
        setEditUserData(rowData);
        setOpenEditUserDialog(true);
      }
    },
    {
      icon: 'add',
      tooltip: '회원 정보 수정',
      isFreeAction: true,
      onClick: handleDialog.openAddUser
    },
  ]


  return (
    <React.Fragment>
      <div style={{ padding: '5px 30px 12px 30px' }}>
        <MaterialTable
          options={tableOptions}
          title={tableTitle()}
          columns={tableColumns}
          data={users || []}
          actions={actions}
          style={{ boxShadow: 'none' }}
          components={{
            Action: props => (props.action?.isFreeAction ?
              <Button
                size="small"
                variant="outlined"
                startIcon={<AddCircleRoundedIcon style={{ color: 'grey' }} />}
                onClick={props.action.onClick}  
              >
                추가
              </Button> :
              <MTableAction {...props} />),
            Pagination: (props) => { return <TablePagination {...props} style={{ ...props.style, background: '#f5f5f5' }} />; }, }}
        />
      </div>

      {/* Dialog for adding NEW USER */}
      <EditUserProfileDialog
        key="add-user-dialog"
        mode={dialogMode.addUser}
        openDialog = {openAddUserDialog}
        closeDialog = {handleDialog.closeAddUser}
        setSnack = {setSnackState}
        history = {props.history}
        reloadFlg={setReloadUsers}
      />
      {/* Dialog for editing existing user*/}
      <EditUserProfileDialog
        key="edit-user-dialog"
        mode = {dialogMode.editUser}
        editUserData = {editUserData}
        openDialog = {openEditUserDialog}
        closeDialog = {handleDialog.closeEditUser}
        setSnack = {setSnackState}
        history = {props.history}
        reloadFlg={setReloadUsers}
      />
      <Snackbar
        open={snackState.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={closeSnackBar}
      >
        <Alert severity={snackState.type || 'info'} onClose={closeSnackBar}>
          {snackState.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  )
}

export default React.memo(ManageUsers);