import React, { Component } from 'react';
import UserTable from './user/UserTable'
import UserBottom from './user/UserBtnBottom'
import Header from './common/Header'
import axios from 'axios';
import './App.css'
import { fieldErrorMsg, userTimeTableMsg } from './common/Messages';
import { userUrl } from './common/url';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { isLastDayOfMonth, format, differenceInMonths } from 'date-fns';
import { checkErr } from './checkErr';
import { withStyles, Typography } from '@material-ui/core';
import { style } from './styles/user/appStyle';
axios.defaults.withCredentials = true;

function fmtDay(date) {
    if (date === null) {
        date = new Date();
    }
    return format(date, 'yyyy/MM/dd');
}

//TODO: delete debugger and console log
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userDate: '',
            displayProjPref: false,
            isFromAdmin: Boolean(props.isFromAdmin),
            isDateChanged: false,
            openResetAlert: false,
            openCheck: false,
            disableData: undefined,
            backdrop: false,
            isRequesting: false,
        }
    }

    getWeekDaysInfo = (txtDate) => {
        let date = new Date(txtDate);
        let dayNum = date.getDay();
        if (dayNum === 0) {
            date.setDate(date.getDate() - 7);
        }

        let weekDays = [];
        let endOfMonIndex;
        let lastWeek = false;
        date.setDate(date.getDate() - dayNum);
        for (let i = 0; i < 7; i++) {
            date.setDate(date.getDate() + 1);

            if (isLastDayOfMonth(date)) {
                if (i !== 6) {
                    endOfMonIndex = i;
                }
                lastWeek = true;
            }

            let fmtDate = fmtDay(date);
            weekDays.push(fmtDate);
        }
        return {
            weekDays, endOfMonIndex, lastWeek
        };
    }


    componentDidMount() {
        this.getUserData(fmtDay(new Date()), false);
    }

    componentDidUpdate() {
        if (this.props.changeTableFlg) {
            this.props.setChangeUserDataFlg(false);
            this.getUserData(this.state.date, this.state.isDateChanged);
        }
    }

    //FIXME: show warning dialog if data changed
    //calendar 선택
    handleChangeUserDate(date) {
        this.setState({
            displayDate: fmtDay(date),
        });
    }

    //FIXME: show warning dialog if data changed
    //last week & next week 버튼
    handleUserDateButton(e) {
        const id = e.currentTarget.id;
        let ud = new Date(this.state.userDate);
        let date;
        if (id === "lastweek") {
            ud.setDate(ud.getDate() - 7);
        } else if (id === "nextweek") {
            ud.setDate(ud.getDate() + 7);
        } else if (id === "day") {
            date = this.state.displayDate;
        }

        if (!date) {
            date = fmtDay(ud);
        }

        this.setState({
            isDateChanged: true,
            userDate: date,
        }, this.getUserData(date, true));
    }

    //copy from lastweek
    handleCopyLastWeek = async () => {
        try {
            const { weekDays, userDate, dataList } = this.state;
            //지난 주 data 가져오기
            let date = new Date(userDate);
            date.setDate(date.getDate() - 7);
            const weekDaysInfo = this.getWeekDaysInfo(fmtDay(date));
            const url = userUrl.getUserTableData;
            let result = await axios.post(url, { weekDays: weekDaysInfo.weekDays, oriData: dataList });

            const copiedData = result.data.list;

            let changed = [];
            copiedData.forEach((data, i) => {
                data.weekly_arr.forEach((d, j) => {
                    if (d !== data.weekly_obj[j]) {
                        changed.push(`${weekDays[j]}_${i}`);
                    }
                })
            });

            this.setState({
                dataList: copiedData,
                Msg: '',
                fieldError: {
                    //field: new Map(),
                    field: {},
                    msg: []
                },
                changed,
                lastWeekOfMonth: weekDaysInfo.lastWeek,
            })
        } catch (err) {
            console.log(err);
            checkErr(err, this.props.history);
        }
    }

    //when +- project button is clicked
    handleClickProjPref = async () => {
        let { changed } = this.state;
        if (changed && changed.length !== 0) {
            //if timesheet has changed value, open alert dialog to warn the data could be reset
            this.setState({
                //openResetAlert: true,
                checkType: 'openPrjPref', openCheck: true,
            });
        } else {
            this.handleOpenProjPref();
        }
    }


    //open +- project modal
    handleOpenProjPref = () => {
        this.setState({
            displayProjPref: true,
            openCheck: false,
            checkType: undefined,
        })
    }

    //TODO: DELETE BELOW not fetching project data here
    setProjectList = async (err, choice, chosen) => {
        try {
            if (err) {
                this.setState({
                    openSnack: true,
                    snackMsg: userTimeTableMsg[err]
                });
                return;
            }
            this.setState({
                projectList: {
                    ...this.state.projectList,
                    chosen,
                    choice,
                    fieldErr: {
                        msg: [],
                        field: {},
                    }
                }
            });
        } catch (error) {
            console.error(error);
            checkErr(err, this.props.history);
        }
    }

    //close +-pref modal
    handleCloseProjPref = async (prjEdited) => {
        this.setState({
            displayProjPref: false,
            // openCheck: false,
            // checkType: undefined,
            backdrop: true,
        });

        if (prjEdited === true) {
            const url = userUrl.getUserTableData;
            const { weekDays } = this.state;
            try {
                let res = await axios.post(url, { weekDays })
                if (res.data.list) {
                    this.setState({
                        dataList: res.data.list,
                        changed: undefined,
                        backdrop: false,
                        fieldError: {
                            field: {},
                            msg: []
                        },
                    });
                }
            } catch (err) {
                console.error(err);
                if (!checkErr(err, this.props.history)) {
                    this.setState({
                        backdrop: false,
                    });
                }
            }
        }

        this.setState({
            backdrop: false,
            displayProjPref: false
        });
    }

    //table 데이터 가져오기
    getUserData = (userDate, dateChanged) => {
        var date = userDate;
        if (this.props.userInfo && this.props.userInfo.userDate && !dateChanged) {
            date = this.props.userInfo.userDate;
        }
        let { userInfo } = this.props;
        let weekDaysInfo = this.getWeekDaysInfo(date);

        const weekDays = weekDaysInfo.weekDays;
        const endOfMon = weekDaysInfo.endOfMonIndex;

        axios.post(userUrl.getUserTableData, { userInfo, userDate, weekDays, endOfMon })
            .then(res => {
                this.setState({
                    userDate: date,
                    displayDate: date,
                    dataList: res.data.list,
                    weekDays,
                    snackMsg: '',
                    fieldError: {
                        field: {},
                        msg: []
                    },
                    displayProjPref: false,
                    disableData: res.data.disableData,
                    endOfMon,
                    lastWeek: weekDaysInfo.lastWeek,
                    isDateChanged: false,
                    changed: undefined,
                    isSubmittedMsg: res.data.isSubmittedMsg,
                    monChk: res.data.monChk,
                });
            }).catch(err => {
                if (!checkErr(err, this.props.history)) {
                    this.setState({
                        userDate: date,
                        displayDate: date,
                        weekDays,
                        snackMsg: userTimeTableMsg.failToGetTableData,
                        openSnack: true,
                        fieldError: {
                            field: {},
                            msg: []
                        },
                        displayProjPref: false,
                        lastWeek: weekDaysInfo.lastWeek,
                        isDateChanged: false,
                        changed: undefined,
                        isSubmittedMsg: undefined,
                    });
                }
            });
    }

    //save button
    async handleClickSave(e) {
        const { weekDays, changed, fieldError, endOfMon, lastWeek } = this.state;
        try {
            //validation : check if anything has changed
            if (!changed || changed.length === 0) {
                this.setState({ snackMsg: userTimeTableMsg.noDataToSave, openSnack: true, });
                return;
                //validation : check if field has error
            } else if (fieldError.msg.length !== 0) {
                this.setState({ snackMsg: userTimeTableMsg.saveFieldError, openSnack: true, });
                return;
                //validation : check if data have to be submitted(checked)
            } else {
                let res = await axios.post(userUrl.checkUserDataSaved, { lastWeek, endOfMon, weekDays, changed });
                let { openCheck, error } = res.data;
                if (error) {
                    this.setState({ snackMsg: userTimeTableMsg[error], openSnack: true, });
                    return;
                } else if (openCheck) {
                    this.setState({ openCheck, checkType: 'resubmit' });
                    return;
                }
            }
            this.saveTimeData(false);
        } catch (err) {
            console.log(err);
            if (!checkErr(err, this.props.history)) {
                this.setState({
                    snackMsg: userTimeTableMsg.internalError,
                    openSnack: true,
                });
            }
        }
    }

    //save data
    async saveTimeData(openCheck) {
        if (this.state.isRequesting) {
            this.setDblReqMsg();
            return;
        } else {
            try {
                this.setState({ isRequesting: true });
                const url = userUrl.saveUserTable;
                const { userDate, weekDays, dataList, endOfMon, lastWeek, changed } = this.state;
                const result = await axios.post(url, { userDate, weekDays, dataList, endOfMon, lastWeek, changed, openCheck });
                const { success, action, errorMsg, userData, isSubmittedMsg } = result.data;
                let msg = success ? userTimeTableMsg[action] : errorMsg;
                let tableData = userData ? userData : dataList;
                this.setState({
                    snackMsg: msg,
                    dataList: tableData,
                    changed: undefined,
                    openCheck: false,
                    checkType: undefined,
                    openSnack: true,
                    isSubmittedMsg: isSubmittedMsg,
                    isRequesting: false,
                });
            } catch (err) {
                console.error(err);
                if (!checkErr(err, this.props.history)) {
                    this.setState({
                        snackMsg: userTimeTableMsg.internalError,
                        dataList: this.state.dataList,
                        openCheck: false,
                        checkType: undefined,
                        openSnack: true,
                        isRequesting: false,
                    });
                }
            }
        }

    }

    async handleClickSubmit() {
        try {
            const { changed } = this.state;
            if (!changed || changed.length === 0) {
                this.setState({ openCheck: true, checkType: 'submit' })
            } else {
                //show alert if any unsaved data exists
                this.setState({
                    snackMsg: userTimeTableMsg.saveBeforeAction,
                    openSnack: true
                })
            }
        } catch (err) {
            console.error(err);
        }
    }

    async runSubmit() {
        const { weekDays, endOfMon, isRequesting, isFromAdmin } = this.state;
        const { userInfo, isDisabledUser } = this.props
        if (isRequesting) {
            this.setDblReqMsg();
            return;
        } else {
            let snackMsg = userTimeTableMsg.internalError;
            try {
                this.setState({ isRequesting: true });
                let submitUser;
                if (isFromAdmin) {
                    submitUser = userInfo.userId
                }
                const res = await axios.post(userUrl.runSubmit,
                    { weekDays, endOfMon, isDisabledUser, isFromAdmin, submitUser });
                const { result, errorMsg, dataList, disableData, isSubmittedMsg, monChk } = res.data;
                if (result) {
                    let yrMon = format(new Date(weekDays[0]), 'yyyy년 MM월');
                    snackMsg = userTimeTableMsg.submitted(yrMon);
                    this.setState({ dataList, disableData, isSubmittedMsg, monChk })
                } else if (errorMsg) {
                    snackMsg = userTimeTableMsg[errorMsg]
                }
            } catch (err) {
                console.log(err);
                if (checkErr(err, this.props.history)) {
                    return;
                }
            }
            this.setState({
                snackMsg, openSnack: true, openCheck: false,
                checkType: undefined, isRequesting: false,
            });
        }
    }

    hideSubmitBtn = () => {
        const { monChk, weekDays, lastWeek } = this.state;
        if (!lastWeek || !monChk) {
            return true;
        }
        const submitDate = new Date(weekDays[0]);
        const monDiff = differenceInMonths(new Date(), submitDate);
        const yrMon = format(submitDate, 'yyyyMM');
        return monDiff < 0 || monDiff > 2
            || Boolean(monChk[yrMon] && (monChk[yrMon].isChecked || monChk[yrMon].isSubmitted))
    }

    //Dialog 타입 지정
    warningDialog = {
        resubmit: {
            title: userTimeTableMsg.resubmitDialogTitle,
            msg: userTimeTableMsg.resubmitDialogContent,
            action: this.saveTimeData.bind(this, true)
        },
        submit: {
            title: (yrMon) => userTimeTableMsg.submitDialogTitle(yrMon),
            msg: userTimeTableMsg.submitDialogContent,
            action: this.runSubmit.bind(this, true)
        },
        openPrjPref: {
            title: userTimeTableMsg.resetAlertMsgTitle,
            msg: userTimeTableMsg.resetAlertMsgContent,
            action: this.handleOpenProjPref.bind(this),
        }
    }

    setDblReqMsg = () => {
        this.setState({
            snackMsg: userTimeTableMsg.requesting,
            openSnack: true
        })
    }

    //textbox 입력 시
    handleChangeWorktime(e, proj, dayNum) {
        let target = e.currentTarget;
        let value = target.value
        let { dataList, changed } = this.state;

        //array 객체 state를 수정된 값으로 갱신
        for (let [index, data] of dataList.entries()) {
            if (data.projCd === proj) {
                data.weekly_arr[dayNum] = value;
                this.validateWorktime(data, value, dayNum, target.id, index);

                //find out if values are changed : for openProjPref
                let ori = data.weekly_obj[dayNum];

                if (ori === Number(value) || !(Boolean(ori) || Boolean(Number(value)))) {
                    changed = changed ? changed.filter(c => c !== target.id) : changed;
                } else {
                    changed = changed ? Array.from(new Set([...changed, target.id])) : [target.id];
                }
                break;
            }
        }
        this.setState({ dataList, changed });
    }

    //textfield validation
    validateWorktime(data, value, dayIndex, id, dataIndex) {
        let numValue = Number(value);
        let fieldErr = this.state.fieldError;
        let field = fieldErr.field;

        let errList = [];

        if (isNaN(numValue)) {
            errList.push("number");
        } else {
            if (numValue % 0.5 !== 0) {
                errList.push("format");
            }
            if (numValue > 24 || numValue < 0) {
                errList.push("range");
            }

            let total = 0;
            let { dataList, weekDays } = this.state;
            for (let d of dataList) {
                total += d.weekly_arr.reduce((a, b) => {
                    a = isNaN(a) ? 0 : Number(a);
                    b = isNaN(b) ? 0 : Number(b);
                    return a + b;
                })
            }

            if (total > 52 && numValue !== 0) {
                errList.push("overTime");
            } else if (total <= 52) {
                //합계가 52를 초과하지 않으면 FieldErr에서 overtime error 모두 삭제
                for (let [key, value] of Object.entries(field)) {
                    const val = value.filter(v => v !== 'overTime');
                    let [dayIdx, dataIdx] = key.split("_");
                    dayIdx = weekDays.indexOf(dayIdx);
                    if (val.length === 0) {
                        delete field[key];
                        dataList[dataIdx].errors[dayIdx] = false;
                    } else {
                        field[key] = val;
                    }
                }
            }
        }

        if (errList.length === 0 && field[id]) {
            delete field[id];
            data.errors[dayIndex] = false;
        } else if (errList.length > 0) {
            data.errors[dayIndex] = true;
            field[id] = errList;
        }

        let errors = [];
        for (let value of Object.values(field)) {
            errors = errors.concat(value);
        }

        let uniqueErrors = Array.from(new Set(errors));
        fieldErr.msg = uniqueErrors;
        fieldErr.field = field;
        this.setState({ fieldError: fieldErr });

        return errList.length > 0;
    }

    //방향키 텍스트 포커스 이동
    handleKeyDown = e => {
        const cId = e.target.id.split("_");
        switch (e.key) {
            case "ArrowLeft":
                if (e.target.selectionStart === 0 && e.target.selectionEnd === 0) {
                    this.handleMoveFocusHorizontally(cId, -1)
                }
                break;
            case "ArrowRight":
                const length = e.target.value.length;
                if (e.target.selectionStart === length && e.target.selectionEnd === length) {
                    this.handleMoveFocusHorizontally(cId, 1);
                }
                break;
            case "ArrowUp":
                this.handleMoveFocusVertically(cId, -1);
                break;
            case "ArrowDown":
                this.handleMoveFocusVertically(cId, 1);
                break;
            // case "S":
            //     if(e.ctrlKey && e.shiftKey) {
            //         this.handleClickSave();
            //     }
            //     break;
            default:
                break;
        }
    }

    handleMoveFocusHorizontally(cId, incr) {
        const { weekDays } = this.state;
        const dayIndex = weekDays.indexOf(cId[0]) + incr;
        if (dayIndex >= 0 && dayIndex <= 6) {
            const id = weekDays[dayIndex].concat('_').concat(cId[1]);
            document.getElementById(id).focus();
        }
    }

    handleMoveFocusVertically(cId, incr) {
        const idx = Number(cId[1]) + incr;
        if (idx >= 0 && idx <= this.state.dataList.length - 1) {
            const id = `${cId[0]}_${idx}`;
            document.getElementById(id).focus();
        }
    }

    getBackdrop() {
        return (
            <Backdrop open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }
    
    _renderUser = () => {
        let { disableData, fieldError, checkType } = this.state;
        const { classes } = this.props;
        const hideSubmitBtn = this.hideSubmitBtn();
        return (
            <div>
                {this.props.isFromAdmin
                    ? null
                    : (
                        <React.Fragment>
                            <Header style={{ display: 'none' }} />
                            {this.state.Msg ? this.state.Msg : ''}
                            {fieldError ? this.msgArea() : null}
                        </React.Fragment>
                    )
                }
                <UserTable data={this.state}
                    onChangeInput={this.handleChangeWorktime.bind(this)}
                    onClickCopy={this.handleCopyLastWeek.bind(this)}
                    onChangeUserDate={this.handleChangeUserDate.bind(this)}
                    onClickChangeWeek={this.handleUserDateButton.bind(this)}
                    isFromAdmin={this.props.isFromAdmin}
                    fromAdminUserInfo={this.props.userInfo}
                    onKeyDown={this.handleKeyDown.bind(this)}
                />
                <UserBottom
                    isFromAdmin={this.props.isFromAdmin}
                    //submittable = {adminSubmittable}
                    onClickSubmit={this.handleClickSubmit.bind(this)}
                    onClickSave={this.handleClickSave.bind(this)}
                    btnDisable={disableData ? disableData.btnDisable || fieldError.msg.length !== 0 : false}
                    onClickProjPref={this.handleClickProjPref.bind(this)}
                    onCloseProjPref={this.handleCloseProjPref.bind(this)}
                    displayProjPref={this.state.displayProjPref}
                    projectList={this.state.projectList}
                    setProjectList={this.setProjectList.bind(this)}
                    isLastWeek={this.state.lastWeek}
                    firstDayOfWeek={this.state.weekDays ? this.state.weekDays[0] : null}
                    hideSubmitBtn={hideSubmitBtn}
                /> 
                {/* 경고 다이얼로그 */}
                {!checkType ? null
                    : (<Dialog
                        open={this.state.openCheck}
                        onClose={() => this.setState({ openCheck: false, })}
                        key="openCheck"
                    >
                        <DialogTitle>
                            <div className={classes.dialogTitle} >
                                <WarningRoundedIcon className={classes.dialogTitleIcon} />
                                <span>{
                                    checkType === 'submit' ?
                                        this.warningDialog[checkType].title(format(new Date(this.state.weekDays[0]), 'yyyy년 MM월'))
                                        : this.warningDialog[checkType].title
                                }</span>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.warningDialog[checkType].msg}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                className={classes.dialogButton}
                                onClick={this.warningDialog[checkType].action}
                            >
                                OK
                        </Button>
                            <Button
                                className={classes.dialogButton}
                                onClick={() => this.setState({ openCheck: false })}
                            >
                                Cancel
                        </Button>
                        </DialogActions>
                    </Dialog>
                    )}

                {/* 메세지*/}
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={this.state.openSnack}
                    onClose={e => { this.setState({ openSnack: false }) }}
                    message={<span id="message-id">{this.state.snackMsg}</span>}
                />
            </div>
        );
    }

    //render field error message
    msgArea() {
        const { classes } = this.props;
        const msg = this.state.fieldError ? this.state.fieldError.msg : [];

        return (
            msg.length !== 0
                ? <div className={classes.msgContainer}>
                    <span className={classes.msgTitle}>
                        <WarningRoundedIcon className={classes.msgTitleItem} />
                        <Typography variant="h6" className={classes.msgTitleItem}>
                            Error
                    </Typography>
                    </span>
                    <ul>
                        {msg.map((m, i) =>
                            <li key={i} className={classes.msgItem}>
                                {fieldErrorMsg[m]}
                            </li>
                        )}
                    </ul>
                </div>
                : null
        )
    }


    render() {
        return (
            <div>
                {this.state.weekDays && !this.state.backdrop ? this._renderUser() : this.getBackdrop()}

            </div>

        );
    }
}

export default withStyles(style)(App);