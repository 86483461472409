import React, { Component } from 'react';
import packageJson from '../../package.json';
global.appVersion = packageJson.version;
const buildDateGreaterThan = (latestDate, currentDate) => {
    const latestDateTime = new Date(latestDate).getTime();
    const currentDateTime = new Date(currentDate).getTime();

    return latestDateTime > currentDateTime ? true : false;
};

const withCacheBuster = () => (WrappedComponent) => {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true,
                isLatestVersion: false,
                refreshCacheAndReload: () => {
                    console.log('Clearing cache and hard reloading...')
                    if (caches) {
                        caches.keys().then(async (names) =>
                            await Promise.all(names.map(name => caches.delete(name)))
                        );
                    }
                    window.location.reload(true)
                }
            };
        }

        setCacheBusterState = async () => {
            const meta = await (await fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })).json();
            if (!meta) {
                return;
            }
            const latestVersion = meta.buildDate;
            const currentVersion = global.buildDate;
            const shouldForceRefresh = buildDateGreaterThan(latestVersion, currentVersion);

            if (shouldForceRefresh) {
                console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                this.setState({ loading: false, isLatestVersion: false });
            } else {
                console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                this.setState({ loading: false, isLatestVersion: true });
            }
        }

        componentDidMount() {
            this.setCacheBusterState();
        }

        render() {
            const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
            return (
                <WrappedComponent 
                    {...this.props}
                    loading={loading}
                    isLatestVersion={isLatestVersion}
                    refreshCacheAndReload={refreshCacheAndReload}
                />
            )
        }
    }
}

export default withCacheBuster;