import React from 'react';
import Axios from 'axios';
import AddPrjCdDialog from './AddPrjCdDialog'
import SapPrjCdTable from './SapPrjCdTable';
import SapCdPctInput from './SapCdPctInput';
import { projectCodeManageMsg } from '../common/Messages';
import { adminUrl } from '../common/url';
import CloseIcon from '@material-ui/icons/Close';
import HistoryIcon from '@material-ui/icons/History';

import MaterialTable from '@material-table/core';
import { checkErr } from '../checkErr'
import {
  IconButton, Dialog, DialogTitle, DialogContent,
  TextField, Button,
  Divider, Snackbar, Grid, DialogActions, TablePagination, Typography
} from '@material-ui/core';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import koLocale from 'date-fns/locale/ko';
import { withStyles } from '@material-ui/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

Axios.defaults.withCredentials = true;

const styles = {
  close: {
    float: 'right'
  },
  prjNmInput: {
    width: 300,
  }
};

const getTableTitle = () => (
  <Typography variant="h6" style={{ fontWeight: 600, color: '#6c6c6c' }}>프로젝트</Typography>
)
class ProjectCodeManage extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      projects: undefined,
      openAddPrj: false,
      openSapPrjTbl: false,
      openHistory: false,
      editable: false,
      sapPrj: undefined,
      grList: undefined,
      openSnack: false,
      openFilter: false,
      editing: false,
    }
  }

  handleInit() {
    Axios.post(adminUrl.getPrjInfo, { viewId: this.props.viewId })
      .then(res => {
        const { gr, user, userLookup, prj } = res.data;
        this.setState({
          projects: prj,
          userList: user
        });
        this.setTables(gr, user, userLookup);
      }).catch(err => {
        checkErr(err, this.props.history);
        console.log(err);
      });
  }

  componentDidMount() {
    this.handleInit();
  }

  setTables = async (grList, userList, userLookup) => {
    const { classes } = this.props;
    const prjTable = {
      columns: [
        { title: 'No', field: 'no', filtering: false, editable: 'never', cellStyle: { fontSize: '1rem' }, maxWidth: 50},
        { title: 'Project Code', field: 'prjCd', editable: 'never', cellStyle: { fontSize: '1rem' } },
        {
          title: 'SAP Code', field: 'sapCdList',
          cellStyle: { fontSize: '1rem' },
          render: rowData => {
            return (
              <React.Fragment>
                {rowData.sapCdList.map((c, i) =>
                (
                  <React.Fragment key={c}>
                    <span >
                      {c}
                    </span>
                    {rowData.sapCdList.length - 1 === i || rowData.sapCdList.length === 1 ?
                      null
                      : <Divider className={classes.divider} />}
                  </React.Fragment>
                )
                )}
              </React.Fragment>
            )
          },
          editComponent: props => {
            let rd = this.state.editProps ? this.state.editProps.rowData : undefined;
            if (rd && rd.prjCd === props.rowData.prjCd
              && JSON.stringify(rd.sapCdList.sort()) !== JSON.stringify(props.value.sort())) {
              props.rowData.sapCds = rd.sapCds;
              props.onChange(rd.sapCdList);
            }

            let value = "";
            props.value.forEach((v, i) => {
              value += v;
              if (props.value.length > 1 && i !== props.value.length - 1) {
                value += "\n";
              }
            })
            return (
              <TextField
                placeholder="CLICK TO ADD SAP CODE"
                value={value}
                multiline={true}
                rows={1}
                rowsMax={10}
                InputProps={{ style: { textAlign: 'center' } }}
                onClick={this.onEdit.sapCd.bind(this, props)}
                onChange={this.onEdit.sapCd.bind(this, props)}
              />
            )
          }
        },

        {
          title: 'Project 명칭', field: 'prjNm',
          cellStyle: { fontSize: '1rem' },
          editComponent: props => {
            return (
              <TextField
                value={props.value}
                margin="dense"
                className={classes.prjNmInput}
                onChange={e => this.onEdit.textFields(e.target.value, props)}
              //helperText="*Required"
              />
            )
          }
        },

        {
          title: '시작 시점', field: 'startDate',
          cellStyle: { fontSize: '1rem' },
          editComponent: props =>
          (
            <KeyboardDatePicker
              autoOk
              clearable="true"
              variant="inline"
              margin="dense"
              InputAdornmentProps={{ position: "start" }}
              value={props.value}
              format="yyyy-MM"
              views={["year", "month"]}
              onChange={date => this.onEdit.date(date, props)}
            //onChange={date => this.onEdit.textFields(format(date, 'yyyy-MM-dd'), props)}
            />
          )
        },

        {
          title: '종료 시점', field: 'endDate',
          cellStyle: { fontSize: '1rem' },
          editComponent: props =>
          (
            <KeyboardDatePicker
              autoOk
              clearable="true"
              variant="inline"
              margin="dense"
              InputAdornmentProps={{ position: "start" }}
              value={props.value}
              format="yyyy-MM"
              views={["year", "month"]}
              onChange={date => this.onEdit.date(date, props)}
            //onChange={date => this.onEdit.textFields(format(date, 'yyyy-MM-dd'), props)}
            />
          )
        },

        {
          title: '담당자', field: 'manager',
          cellStyle: { fontSize: '1rem' },
          lookup: userLookup ? userLookup : [],
          editComponent: props => {
            return (
              <Autocomplete
                freeSolo
                options={userList}
                getOptionLabel={option => option && option.userNm ? option.userNm : option}
                style={{ width: 150 }}
                defaultValue={props.value}
                onChange={(event, newValue) => {
                  let userId = undefined;
                  if (newValue) {
                    userId = newValue.userId
                  }
                  this.onEdit.textFields(userId, props)
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    margin="dense"
                    style={{ width: 150 }}
                  />
                )}
              />
            )
          }
        },
        { title: '그룹', field: 'grId', cellStyle: { fontSize: '1rem' }, lookup: grList ? grList : [] },
      ],
    }


    const logTable = {
      columns: [
        { field: 'updated', title: 'time' },
        { field: 'action', title: 'action' },
        {
          field: 'prjCd', title: 'Code',
          headerStyle: { width: 160, maxWidth: 160 },
          cellStyle: { width: 160, maxWidth: 160 }
        },
        { field: 'prjNm', title: '프로젝트 명칭' },
        {
          field: 'startDate', title: '시작일',
          headerStyle: { width: 180, maxWidth: 180 },
          cellStyle: { width: 180, maxWidth: 180 }
        },
        {
          field: 'endDate', title: '종료일',
          headerStyle: { width: 180, maxWidth: 180 },
          cellStyle: { width: 180, maxWidth: 180 }
        },
        { field: 'managerNm', title: '담당자' },
        { field: 'grNm', title: '그룹명' },
        { field: 'userNm', title: '사용자' },
      ],
      options: {
        search: false,
        showTitle: false,
        pageSize: 10,
      }
    }

    this.setState({ prjTable, logTable });
  }

  async handleEdit(newData, oldData) {
    const prjCd = oldData.prjCd;
    try {
      const param = { newData, oldData, viewId: this.props.viewId }
      const res = await Axios.post(adminUrl.editPrjInfo, param);
      const { fieldErr, error } = res.data;

      if (fieldErr) {
        let msg = [];
        for (let [field, errorType] of Object.entries(fieldErr)) {
          msg.push(<br />);
          msg.push(`${projectCodeManageMsg.fieldError.getFieldNm(field)}: `);
          errorType.forEach(e => msg.push(projectCodeManageMsg.fieldError.getErrorMsg[e]))
        }
        msg.shift();

        this.setState({
          openSnack: true,
          msg,
        });

        return false;

      } else if (!error || error === 'alreadyUpdated') {
        let msgCd = error ? error : 'editPrj';
        this.setState({
          fieldErr: {},
          openSnack: true,
          msg: projectCodeManageMsg.editMsg(prjCd, msgCd),
        }, this.handleInit());

        return true;
      }
      return false;
    } catch (err) {
      console.log(err);
      if (!checkErr(err, this.props.history)) {
        this.setState({
          fieldErr: {},
          openSnack: true,
          msg: projectCodeManageMsg.editMsg(prjCd, 'editErr'),
        })
      }
      return false;
    }

  }

  onEdit = {
    textFields: (value, editProps) => {
      editProps.onChange(value);
    },
    date: (date, editProps) => {
      if (date === undefined || date === '') {
        editProps.onChange(null);
      }
      editProps.onChange(date);
    },
    sapCd: async (editProps, event) => {
      const { prjCd, sapCds } = editProps.rowData;
      const selSapCd = !sapCds || Object.keys(sapCds).length === 0 ? undefined : Object.assign({}, sapCds);
      try {
        //let sapPrj = await this.getSapPrjList(prjCd);
        let sapPrj = await this.getSapPrjList();
        this.setState({
          openSapPrjTbl: true,
          sapPrj: sapPrj,
          selSapCd: selSapCd,
          onEditPrj: prjCd,
          editProps: editProps
        });
        //editProps.onChange(editProps.rowData.sapCdList);
      } catch (err) {
        console.log(err);
      }
    },
  }

  async handleDelete(oldData) {
    let msgCd;
    const { prjCd, version } = oldData;
    try {
      let res = await Axios.post(adminUrl.deletePrjInfo, { prjCd, version, viewId: this.props.viewId });
      msgCd = res.data.error ? 'alreadyUpdated' : 'deletePrj';
    } catch (err) {
      console.log(err);
      if (!checkErr(err, this.props.history)) {
        msgCd = 'deleteErr';
      }
    }

    this.setState({
      openSnack: true,
      msg: projectCodeManageMsg.editMsg(prjCd, msgCd),
    }, this.handleInit());

    return;
  }



  async getSapPrjList(onEditPrj) {
    try {
      let res = await Axios.post(adminUrl.getSapPrj, { onEditPrj, viewId: this.props.viewId });
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  handleCloseSapPrjTbl(isDone = false) {
    const openSapPrjTbl = false;
    const onEditPrj = undefined;
    let { selSapCd, editProps } = this.state;
    const editedSapCd = selSapCd ? Object.keys(selSapCd) : [];
    if (isDone) {
      editProps.rowData.sapCds = selSapCd;
      editProps.rowData.sapCdList = editedSapCd;
      editProps.onChange(editedSapCd);
    }

    this.setState({ openSapPrjTbl, onEditPrj, });
  }

  getSapCd(selSapCd) {
    for (let s in selSapCd) {
      if (!selSapCd[s]) {
        selSapCd[s] = 100;
        //uncomment below when multiple select is needed 
        //selSapCd[s] = 0;
      }
    }

    this.setState({ selSapCd });
  }

  handleCloseAddPrj(updated = undefined) {
    this.setState({
      openAddPrj: false
    });
    if (updated) {
      this.handleInit();
    }
  }

  handlePctChange(cd, value) {
    let { selSapCd } = this.state;
    selSapCd[cd] = value;
    this.setState({ selSapCd });
  }

  async handleLog(prjCd) {
    try {
      let res = await Axios.post(adminUrl.getPrjLog, { prjCd, viewId: this.props.viewId });
      this.setState({
        logData: res.data.prj,
        openHistory: true,
      })
      console.log(res)
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }

  makeLogPanel() {
    const { logData } = this.state;
    if (!this.state.logData) {
      return;
    }
    return (
      <MaterialTable
        options={this.state.logTable.options}
        columns={this.state.logTable.columns}
        data={logData}
      //parentChildData={(row, rows) => rows.find(r => r.version === row.prjVer && r['prjNm'])}
      //components={{ Container: props => <Paper {...props} elevation={0} /> }}
      />
    );
  }

  async handleClickAdd() {
    if (this.tableRef && this.tableRef.current.state.lastEditingRow) {
      this.setState({
        openSnack: true,
        msg: projectCodeManageMsg.openAddPrj
      })
      return;
    }

    try {
      let sapPrj = await this.getSapPrjList();
      this.setState({
        openAddPrj: true,
        sapPrj: sapPrj
      });

    } catch (err) {
      console.log(err);
      this.setState({
        openAddPrj: true,
        sapPrj: []
      });
    }
  }

  render() {
    const { classes } = this.props;
    const options = {
      showTitle: true,
      pageSize: 10,
      maxBodyHeight: 'calc(100vh - 17em)',
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#f5f5f5',
        fontWeight: 550,
        color: '#6c6c6c'
      },

      //filtering: false,
      filtering: this.state.openFilter,
      paginationType: 'stepped',
    }

    const actions = [
      {
        icon: 'add',
        tooltip: 'Add Project',
        isFreeAction: true,
        onClick: this.handleClickAdd.bind(this),
      },
      {
        icon: 'filter_list',
        tooltip: 'Open Filter',
        isFreeAction: true,
        //onClick: e => this.state.prjTable ? this.setState({ openFilter: !this.state.prjTable.options.openFilter }) : null
        onClick: e => this.setState({ openFilter: !this.state.openFilter })
      },
      {
        icon: () => <HistoryIcon />,
        tooltip: 'History',
        isFreeAction: false,
        onClick: (e, rowData) => { this.handleLog(rowData.prjCd) }
      }

    ];

    const editable = {
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          this.handleEdit(newData, oldData)
            .then(succeed => succeed ? resolve() : reject())
            .catch(err => {
              console.log(err);
              resolve();
            }); // end of promise
        }),
      onRowDelete: oldData =>
        new Promise((resolve, reject) => {
          this.handleDelete(oldData)
            .then(res => resolve())
            .catch(err => {
              console.log(err);
              resolve();
            });
        })
    }

    const { selSapCd, sapPrj, onEditPrj } = this.state;

    //Project Code 의 업무 코드가 공통(C)일 경우에는 공통 sap 코드만 선택 가능하도록 변경
    let sapPrjList = !sapPrj
      ? []
      : (onEditPrj && onEditPrj.split("-")[1][0] === 'C'
        ? sapPrj.filter(s => s.prjCode.indexOf("공통") >= 0 && s.prjCode !== 'P.BOX 공통')
        : sapPrj);
    return (
      <React.Fragment>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
          <MaterialTable
            tableRef={this.tableRef}
            columns={this.state.prjTable ? this.state.prjTable.columns : []}
            //options={this.state.prjTable ? this.state.prjTable.options : []}
            //options={this.state.projects ? options : undefined}
            title={getTableTitle()}
            options={options}
            data={this.state.projects ? this.state.projects : []}
            actions={actions}
            editable={editable}
            style={{ boxShadow: 'none' }}
            components={{
              Pagination: (props) => { return <TablePagination {...props} style={{ ...props.style, background: '#f5f5f5' }} />; }
            }}
          />
        </MuiPickersUtilsProvider>

        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={this.state.openSapPrjTbl}
          onClose={this.handleCloseSapPrjTbl.bind(this, false)}
        >
          <DialogTitle>
            <span>Add SAP Code</span>
            <IconButton className={classes.close} aria-label="close" onClick={this.handleCloseSapPrjTbl.bind(this, false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={3}>
              <Grid
                key="sapPrjTable"
                item
                xs={selSapCd && Object.values(selSapCd).length !== 0 ? 8 : 12}
              >
                <SapPrjCdTable
                  //sapPrj={this.state.sapPrj ? this.state.sapPrj : []}
                  sapPrj={sapPrjList}
                  setSapCd={this.getSapCd.bind(this)}
                  selSapCd={selSapCd ? selSapCd : {}}
                  mode="edit"
                />
              </Grid>
              <Grid
                key="selectedCd"
                item
                xs={selSapCd && Object.values(selSapCd).length ? 4 : false}
              >
                {selSapCd && Object.keys(selSapCd).length !== 0
                  ? <SapCdPctInput
                    selSapCd={this.state.selSapCd}
                    setSapCd={this.getSapCd.bind(this)}
                    pctChange={this.handlePctChange.bind(this)}
                    mode="edit"
                  />
                  : null}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleCloseSapPrjTbl.bind(this, false)}>
              Close
            </Button>
            <Button color="primary" variant="contained" onClick={this.handleCloseSapPrjTbl.bind(this, true)}>
              Done
            </Button>
          </DialogActions>
        </Dialog>
        {!this.state.projects ? null :
          (<AddPrjCdDialog
            openAddPrj={this.state.openAddPrj}
            onClose={this.handleCloseAddPrj.bind(this)}
            sapPrj={this.state.sapPrj ? this.state.sapPrj : []}
            viewId={this.props.viewId}
            userList={this.state.userList ? this.state.userList : []}
          />)}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.openSnack}
          onClose={e => { this.setState({ openSnack: false }) }}
          message={this.state.msg}
          bodystyle={{ height: 'auto', lineHeight: '28px', maxHeight: '28px', padding: 24, whiteSpace: 'pre-line' }}
        />
        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={this.state.openHistory}
          onClose={e => this.setState({ openHistory: false })}
        >
          <DialogTitle>
            <span>history</span>
            <IconButton className={classes.close} aria-label="close" onClick={e => this.setState({ openHistory: false })}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent>
            {this.makeLogPanel()}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ProjectCodeManage);