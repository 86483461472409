import { TableRow, TableCell } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        //position: "absolute",
        height: '75vh',
        // height: 720,
        
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        overflowY: 'auto',
        clear: 'both',
        boxShadow: 'none',
    },
    table: {
        //minWidth: 700,
        minWidth: '100%',
        justify: 'center',
        width: '100%',
        borderCollapse: 'separate'
    },
    total: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        overflow: 'auto',
        clear: 'both',
        //position: '-webkit-sticky',
        position:'sticky',
        bottom: 0,
    },
    button: {
        fontSize: '1em',
        fontWeight: 'bold',
        borderColor: 'orange',
        color: 'darkorange',
        '&:hover':{
            backgroundColor: '#fff4e7'
        }
    },
    smallButton: {
        marginRight: theme.spacing(2),
        marginBottom: '2px',
        maxWidth: '40px',
        maxHeight: '42px',
        minWidth: '40px',
        minHeight: '42px',
        fontSize: 14,
    },
    input: {
        fontSize: '1.2em',
        maxLength: 4,
    },
    textField: {
        width: 40,
        margin: 5,
        maxLength: 4
    },
    hasErrorInput: {
        fontSize: '1.2em',
        maxLength: 4,
        color: 'firebrick',
    },
    hasErrorTextField: {
        width: 40,
        margin: 5,
        backgroundColor: 'lavenderblush',
        borderRadius: '4px',
        maxLength: 4,
        borderBottomColor: 'red'
    },
    buttonTop: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        clear: 'both',
        overflow: 'hidden',
        display:'flex',
        justify: 'center',
        alignItems: 'center',
        boxShadow: 'none',
    },
    dayOfWeekLabel: {
        fontSize: '1em',
    },
    dayLabel: {
        fontSize: '1.1em',
    },
    offDay: {
        color: '#b9b9b9',
    },
    noDataRow: {
        textAlign: 'center',
        height:'600px'
    },
    btnLeft: {
        flexGrow: 1,
        display:'flex',
        alignItems: 'center'
    },
    msgPanelIcon: {
        color: 'orange',
        marginRight: 8
    },
    msgPanelSpan : {
        color: 'rosybrown' 
    }
}));

const StyledTableCell = withStyles(theme => ({
    root: {
        padding: '10px 40px 10px 28px'
    },
    head: {
        color: theme.palette.black,
        backgroundColor: '#f9f9f9',
        border: 'lightGrey',
        fontSize: '1.3em',
        top: 0,
        textAlign: 'center',
        borderTop: '1px solid lightGrey',
        borderBottom: '1px solid lightGrey',
        // '&:first-child': {
        //     borderLeft: '1px solid lightGrey',
        // },
    },
    body: {
        fontSize: '1.1em',
        clear: 'both',
        textAlign: 'center',
    },
    footer: {
        padding: '12px 48px 12px 32px',
        fontSize: '1.2em',
        textAlign: 'center',
        color: theme.palette.black,
        overflow: 'auto',
        position: 'sticky',
        bottom: 0,
        backgroundColor: '#f9f9f9',
    }
})) (TableCell);


const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#f9f9f9',
            zIndex: -1,
        },
    },
    footer : {
        position: 'sticky',
        bottom: 0,
    }

}))(TableRow);

export { useStyles, StyledTableCell, StyledTableRow};