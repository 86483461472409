export const styles = {
    alignCenterDiv: {
        display:'flex',
        alignItems:'center',
    },
    justifyFlexEnd: {
        display:'inline-block',
        textAlign:'right'
    },
    groupSelect: {
        //border: '1px solid rgba(0, 0, 0, 0.23)',
        //borderRadius: '4px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        width: "200px",
        height: '40px',
        '&:hover': {
            borderBottom: "1.5px solid black",
        },
        position: 'relative',
        marginRight: 16,
        cursor: 'pointer',
    },
    groupSelectDropDownIcon: {
        position: 'absolute',
        top: 'calc(50% - 12px)',
        right: 0
    },
    groupSelectSpan: {
        position: 'absolute',
        top: 'calc(50% - 12px)',
        marginLeft: '10px',
        marginRight: '10px'
    },
    userSelect: {
        width: "100px",
        height: '40px',
    },
    centerDivOuter: {
        width:'100%', 
        textAlign:'center'
    },
    tableContainer: {
        boxShadow: 'none',
        borderRadius: 'none',
        height: 'calc( 70vh )',
        minWidth: '99%',
        width:'99%',
        overflow: 'auto',
        display:'inline-block'
    },
    underTotal: {
        borderTop: "2px solid #bbbbbb",
    },
    noBorderBottom: {
        borderBottom: "1px solid white !important",
    },
    borderTop: {
        borderTop: "1px solid #bbbbbb",
    },
    lastLine: {
        borderBottom: "1px solid #bbbbbb"
    },
    tableBody: {
        overflowY: 'auto',
        maxHeight: '600px',
        height: '600px',
    },
    noRecords: {
        height: 'calc(60vh)',
    },
    tHead2ndRow: {
        top: '37px'
    },
    table :{
        borderCollapse: 'separate'
    },
    btnArea : {
        padding: '10px',
        overflow: 'hidden',
    },
    sortPanel : {
        display:'flex',
        justifyContent:'center',

        '&:hover':{
            cursor: 'pointer',
            color: 'darkgrey'
        }
    },
    sort: {
        webkitTransition:'all .2s linear',
        mozTransition: 'all .2s linear',
        transition: 'all .2s linear',
        color:'dimgrey'
    },
    sortDesc: {
        webkitTransform:'rotate(180deg)',
        mozTransform:'rotate(180deg)',
        transform:'rotate(180deg)',
    },
    modalList : {
        minHeight: 400
    },

    chipGr: {
        '&:not(:first-child)': {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
        '&:not(:last-child)': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            //borderRight:'1px solid darkgrey'
        }
    },

}

export const groupTimesheetTableTheme = {
    overrides: {
        MuiTableHead: {
            root: {
                //boxShadow: '0 20px 70px -12.125px rgba(0,0,0,0.3)'
            }
        },
        MuiTableCell: {
            head: {
                fontSize: '1.0em',
                font: 'bold',
                align: 'center',
                textAlign: 'center',
                backgroundColor: 'white',
                borderCollapse: 'separate',
                position: 'sticky',
                top: 0,
                // borderTop: '1px solid lightGrey',
                //zIndex: 3,
                //backgroundColor: '#e9e9e9',
                padding: '5px',
                alignItems:'center',

            },
            body: {
                fontSize: '1.0em',
                border: 'none',
                textAlign: 'center',

            }
        },
        MuiTableRow: {
            head: {
                '&:first-child': {
                    borderTop: '1px solid #bbbbbb',
                },

            },
            root: {
                '&:nth-of-type(odd)': {
                    backgroundColor: '#f3f3f3',
                },
            },
            hover: {
                '&:hover': {
                   // backgroundColor: '#F2F5FF !important'
                },
            }
        }
    }
}
