import React from 'react';
import Table from '@material-ui/core/Table';
import { Paper, TableFooter, TableBody, TableHead, TableRow, Box, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import IconButton from '@material-ui/core/IconButton';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import PlayIcon from '@material-ui/icons/PlayArrowRounded';

import { useStyles, StyledTableCell, StyledTableRow } from '../styles/user/userTableStyle';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
const sumRow = (arr) => {
    let sum = 0;
    arr.forEach(function (a) {
        a = Number(a);
        if (isNaN(a)) {
            a = NaN;
        }
        sum += a;
    })
    return sum;
}

const sumCol = (list) => {
    if (list === null || list === undefined) {
        return;
    }
    let sumCol = [0, 0, 0, 0, 0, 0, 0];
    let sum = 0;
    list.forEach(function (data) {
        data.weekly_arr.forEach((weekly, index) => {
            weekly = Number(weekly);
            if (isNaN(weekly)) { weekly = NaN; }
            if (weekly !== null) { sumCol[index] += weekly; }
        })
    })
    sum = sumRow(sumCol);
    sumCol.push(sum);

    if (sum >= 52) {
        // throw new Error('too much time')
    }

    return sumCol
}

const UserTable = (props) => {
    const classes = useStyles();
    var week = props.data.weekDays;
    let dd = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];


    let displayDate = new Date(props.data.displayDate);

    return (
        <form onSubmit={props.onClickButtonSave}
            method="post">
            <Paper className={classes.buttonTop} >
                <div className={classes.btnLeft}>
                    <IconButton
                        variant="contained"
                        id="lastweek"
                        onClick={props.onClickChangeWeek}>
                        <NavigateBefore fontSize="large" />
                    </IconButton>
                    <IconButton
                        variant="contained"
                        id="nextweek"
                        onClick={props.onClickChangeWeek}
                    >
                        <NavigateNext fontSize="large" />
                    </IconButton >
                    <DatePicker
                        className='datepicker-input'
                        selected={displayDate}
                        dateFormat="yyyy/MM/dd"
                        onChange={props.onChangeUserDate}
                        onSelect={props.onChangeUserDate}
                    />
                    <IconButton
                        variant="contained"
                        id="day"
                        onClick={props.onClickChangeWeek}
                    >
                        <PlayIcon fontSize="large" />
                    </IconButton>

                    {!props.data.isSubmittedMsg
                        ? null
                        : <React.Fragment>
                            <CheckCircleRoundedIcon className={classes.msgPanelIcon} />
                            <Typography variant="subtitle1" className={classes.msgPanelSpan}>
                                {props.data.isSubmittedMsg}
                            </Typography>
                        </React.Fragment>
                    }

                </div>

                {props.fromAdminUserInfo ?
                    (
                        <Paper style={{ height: '40px', padding: '5px', margin: '5px', display: 'inline-block' }}>
                            <Box fontSize={12} fontWeight="fontWeightLight" letterSpacing={3} style={{textAlign:'left'}} key="gr">
                                {props.fromAdminUserInfo.userGroup}
                            </Box>
                            <Box fontSize={16} letterSpacing={2}  style={{textAlign:'right'}} key="usernm">
                                {props.fromAdminUserInfo.userNm}
                            </Box>
                        </Paper>
                    )
                    : (
                        <Button
                            variant="outlined"
                            className={classes.button}
                            size="large"
                            boxshadow={1}
                            disabled={props.data.disableData ? (props.data.disableData.txtDisableIdx ? true : false) : false}
                            onClick={props.onClickCopy}

                        >
                            copy from last week
                            </Button>
                    )}
            </Paper>
            <Paper className={classes.root} borderbottom={0} key='body' >
                <Table
                    className={classes.table}
                    stickyHeader
                >
                    <TableHead>
                        <TableRow
                            //ref={thReference}
                            id='th'
                        >
                            <StyledTableCell key='projCd' id='th-projCd'>Project Code</StyledTableCell>
                            <StyledTableCell key='sapCd' id='th-sapCd'>SAP Project</StyledTableCell>
                            <StyledTableCell > Project Name </StyledTableCell>
                            {week.map((d, i) => {
                                return <StyledTableCell key={dd[i]} id={d} style={{ maxWidth: '55px' }}>
                                    <span className={i < 5 ? classes.dayOfWeekLabel : `${classes.offDay} ${classes.dayOfWeekLabel}`}>{dd[i]}</span>
                                    <br />
                                    <span className={i < 5 ? classes.dayLabel : `${classes.offDay} ${classes.dayLabel}`}>{d.slice(5)}</span>
                                </StyledTableCell>
                            })}
                            <StyledTableCell> Total </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    {props.data.dataList && props.data.dataList.length !== 0
                        ? <UserTableBody props={props} />
                        :
                        <TableBody>
                            <TableRow>
                                <StyledTableCell colSpan={"11"} className={classes.noDataRow}>
                                    <Typography variant="h6">No data to display</Typography>
                                </StyledTableCell>

                            </TableRow>
                        </TableBody>
                    }
                </Table>
            </Paper>
            <Paper className={classes.total} key='footer'>

            </Paper>
        </form>
    )
}


function UserTableBody({ props }) {
    let dataList = props.data.dataList;
    let disableData = props.data.disableData;
    let week = props.data.weekDays;
    let sumC = sumCol(dataList);
    const classes = useStyles();
    const inputProps = {
        classes: {
            root: classes.textField,
            input: classes.input
        }
    };

    const errorInputProps = {
        classes: {
            root: classes.hasErrorTextField,
            input: classes.hasErrorInput
        }
    }

    return (
        <React.Fragment>
            <TableBody className={classes.body}>
                {dataList.map((dl, i) =>
                    <StyledTableRow key={dl.projCd} id={i}>
                        <StyledTableCell id="projCd" key={dl.projCd}>
                            {dl.projCd}
                        </StyledTableCell>
                        <StyledTableCell id="sapCd" key={'sap ' + dl.sapCd}>
                            {dl.sapCd}
                        </StyledTableCell>
                        <StyledTableCell id="projNm" key={'prjNm ' + dl.projNm}>
                            {dl.projNm}
                        </StyledTableCell>
                        {dl.weekly_arr.map((wk, j) =>
                                <StyledTableCell key={j}>
                                <TextField
                                    //style={styles.textField}
                                    id={week[j] + "_" + i}
                                    InputProps={dl.errors[j] ? errorInputProps : inputProps}
                                    inputProps={{
                                        maxLength: 4
                                    }}
                                    error={dl.errors[j]}
                                    disabled={ disableData || props.isFromAdmin ?
                                         props.isFromAdmin || (disableData.txtDisableIdx < 0 ? j > 6 + disableData.txtDisableIdx : j <= disableData.txtDisableIdx )
                                         : false }
                                    //disabled={disableData || props.isFromAdmin ? props.isFromAdmin || j <= disableData.txtDisableIdx : false}
                                    type="text" value={wk !== null ? wk : ''}
                                    onChange={e => props.onChangeInput(e, dl.projCd, j)}
                                    onKeyDown={props.onKeyDown}
                                />
                            </StyledTableCell>
                        )}
                        <StyledTableCell id="sumRow">
                            {isNaN(sumRow(dl.weekly_arr)) ? '-' : sumRow(dl.weekly_arr)}
                        </StyledTableCell>
                    </StyledTableRow>
                )}
            </TableBody>
            {dataList.length !== 0 ? (
                <TableFooter className={classes.total}>
                    <StyledTableRow>
                        <StyledTableCell colSpan="3" >Total</StyledTableCell>
                        {sumC.map((sum, i) =>
                            <StyledTableCell key={i}> {isNaN(sum) ? '-' : sum}</StyledTableCell>
                        )}
                    </StyledTableRow>
                </TableFooter>
            ) : null}
        </React.Fragment>
    );
}

export default UserTable;