import React, { useEffect, useState } from 'react';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Header from "../common/Header"
import { Switch, Route, Link, BrowserRouter } from "react-router-dom";
import SubmitStatus from "./SubmitStatus";
import AdminTimeTable from './GroupTimesheet';
import ProjectCodeManage from './ProjectCodeManage';
import CheckUserStat from './CheckUserStat';
import ManageGroups from './ManageGroups'
import ManageUsers from './ManageUsers'

import Axios from 'axios';
import HomeIcon from '@material-ui/icons/Home';
import { loginUrl } from '../common/url';

import ExportSchedule from './ExportSchedule';
import { Grid, MenuItem, Paper, Popover } from '@material-ui/core';
Axios.defaults.withCredentials = true;

const tab = { submitStatus: 'Submit Status', timesheet: 'Timesheet', project: 'project', usersAndGroups: 'Users & Groups' }
const Admin = (props) => {
  const [userRole, setUserRole] = useState();
  const [currentTabIdx, setCurrentTabIdx] = useState(0);
  const [anchorEl, setAnchorEl] = useState();
  useEffect(() => {
    const getUserRole = async () => {
      let viewId = window.location.href.split("/").pop();
      viewId = viewId ?? "admin"
      const res = await Axios.post(loginUrl.getUserRole, { viewId });
      setUserRole(Object.keys(res.data.userRole));
      return;
    }

    let currentTabIdx = window.location.href.split("/").pop();
    let index = Object.keys(tab).indexOf(currentTabIdx);
    if (index !== -1) {
      setCurrentTabIdx(index)
    }
    getUserRole()
  }, [])

  const handleOpenUserGroupMenu = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget)
  }

  const handleCloseUserGroupMenu = () => {
    setAnchorEl(null)
  }

  const handleChange = (e, value) => {
    setCurrentTabIdx(value)
  }
  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Header />
      <BrowserRouter>
        <div className="admin">
          <React.Fragment>
            <Tabs
              value={currentTabIdx}
              onChange={handleChange}
              indicatorColor="primary"
            >
              <Tab icon={<HomeIcon />} component={Link} to={`/admin`} style={{ position: 'relative' }} />
              {tab
                ? Object.keys(tab).map(t =>
                  t !== 'usersAndGroups' ?
                    <Tab
                      key={t}
                      label={tab[t]}
                      component={Link}
                      to={`/${t}`}
                      disabled={
                        userRole
                          ? (userRole.indexOf('admin') > -1 ? false : !userRole.includes(t))
                          : true
                      }
                    /> :
                    <Tab
                      key="user-and-group-manage"
                      label={tab[t]}
                      onClick={handleOpenUserGroupMenu}
                      style={{ flexDirection: "row" }}
                    />
                )
                : null}
              {/* FIXME: */}
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseUserGroupMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center"
                }}
              >
                <MenuItem
                  onClick={handleCloseUserGroupMenu}
                  component={Link}
                  key="manage-user-item"
                  to="/manageUsers"
                >
                  Users
                </MenuItem>
                <MenuItem
                  onClick={handleCloseUserGroupMenu}
                  component={Link}
                  key="manage-group-item"
                  to="/manageGroups"
                >
                  Groups
                </MenuItem>
              </Popover>
            </Tabs>
            {window.location.href.split("/").pop() !== 'admin' ?
              null :
              <Grid
                container
                spacing={3}
                style={{ height: '89%', maxWidth: '100%', padding: '2em' }}
              >
                <Grid item sm={6} xs={12}>
                  <Paper style={{ border: '1px solid lightgrey' }}>
                    <CheckUserStat
                      history={props.history}
                      viewId={"admin"}
                    />
                  </Paper>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Paper style={{ border: '1px solid lightgrey' }}>
                    <ExportSchedule
                      showTitle={true}
                      history={props.history}
                      viewId={"admin"}
                    />
                  </Paper>
                </Grid>
              </Grid>
            }
            <Switch>
              <Route
                path="/submitStatus"
                render={() => <SubmitStatus history={props.history} viewId="submitStatus" />}
                key="submitStatus"
              />
              <Route
                path="/timesheet"
                render={() => <AdminTimeTable history={props.history}
                  viewId="timesheet" />}
                key="timesheet"
              />
              <Route
                path="/project"
                render={() => <ProjectCodeManage history={props.history} viewId="project" />}
                key="project"
              />
              <Route
                path="/manageUsers"
                render={() => <ManageUsers history={props.history} viewId="manageUsers" />}
                key="manageUsers"
              />
              <Route
                path="/manageGroups"
                render={() => <ManageGroups history={props.history} viewId="manageGroups" />}
                key="manageGroups"
              />
            </Switch>
          </React.Fragment>
        </div>
      </BrowserRouter>
    </React.Fragment>
  )
}
export default Admin;

