import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20
    },
    controller: {
        flexGrow: 3,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    list: {
        overflowX: 'auto',
        maxHeight: 500
    },
    listSubheader: {
        backgroundColor: '#ffffff'
    },
    listItem: {
        '&:hover': {
            backgroundColor: '#e0e0e0'
        }
    },
    dateLabel: {
        '&:hover': {
            backgroundColor: '#f0f0f0',
            cursor: 'pointer'
        },
        padding: '0.3em'
    },
    msgContainer: {
        backgroundColor: 'lavenderblush',
        padding: '0.5rem',
        margin: '1.2rem',
        borderRadius: '0.2rem'
    },
    msgTitle: {
        display: 'flex',
        alignItems: 'center',
        color: 'firebrick',
        marginBottom: '0.3em'
    },
    msgTitleItem: {
        marginLeft: '0.5em'
    },

}));

export default useStyles;