import React, { useState, useEffect } from 'react';
import { Grid, List, ListItem, ListItemText, Typography, Divider, ListItemSecondaryAction, Switch, FormControlLabel, IconButton, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Axios from 'axios';
import { adminUrl } from '../common/url';
import { checkErr } from '../checkErr';
import { exptModalMsg, exportScheduleMsg } from '../common/Messages';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AddToPhotosRoundedIcon from '@material-ui/icons/AddToPhotosRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import GroupSelect from './GroupSelect';
Axios.defaults.withCredentials = true;

const viewType = { EDIT: 'edit', ADD: 'add', NORMAL:'normal' }
const newExportScheduleItem = JSON.stringify({
  id: null,
  title: '새로운 작업',
  group: null,
  user: null,
  disable: false,
  lastExported: null,
  hasError: false,
  executedTime: null,
  errorMsg: null,
  mailing: true,
  lastSended: null,
});

const ExportSchedule = props => {
  const [selTask, setSelTask] = useState(0);
  const [exptTarget, setExptTarget] = useState(null)
  const [disabledUserExport, setDisabledUserExport] = useState(null);
  const [contentType, setContentType] = useState('normal');
  const [editInitVal, setEditInitVal] = useState(null);
  const [editedTask, setEditedTask] = useState({});
  const [reloadFlg, setReloadFlg] = useState(false);
  const viewId = props.viewId
  const onClickTask = (taskId) => (e) => {
    setSelTask(taskId);
  }

  useEffect(() => {
    if (!exptTarget || reloadFlg) {
      Axios.post(adminUrl.getExportTasksForView, { viewId })
        .then(res => {
          const { exportTargetList, disabledUserExport } = res.data;
          setExptTarget(exportTargetList);
          setDisabledUserExport(disabledUserExport);
          setReloadFlg(false)
          setEditedTask({})
        }).catch(err => {
          console.log(err)
          checkErr(err, props.history)
        })
    }
  }, [reloadFlg, exptTarget, disabledUserExport, viewId, props.history]);

  useEffect(() => {
    if ([viewType.EDIT, viewType.ADD].indexOf(contentType?.type) > -1 && !editInitVal) {
      // exptTarget.user
      // exptTarget.group
      Axios.post(adminUrl.getGroupsAndUsers, { checkGrHeader: exptTarget[selTask].group }).then(res => {
        const { users, groups } = res.data;
        setEditInitVal({ users, groups })

        if (contentType.type === viewType.EDIT) {
          setEditedTask({
            title: exptTarget[selTask].title,
            group: exptTarget[selTask].group, //FIXME:
            user: exptTarget[selTask].user ? exptTarget[selTask].user.map(exportUser => users.find(user => user.empId === exportUser)) : null,
          })
        } else {
          setEditedTask({});
        }
      }).catch(err => {
        console.error(err);
      })
    }
  }, [contentType])

  const getTargetContent = (label, isForResigned) => {
    const EMPTY = "-";

    let printList;
    if (!isForResigned) {
      printList = !exptTarget?.length || selTask === undefined || selTask === null
        ? null
        : exptTarget[selTask];
    } else {
      printList = !disabledUserExport ? null : disabledUserExport
    }

    if (!printList) {
      return EMPTY;
    }

    const content = printList[label];
    if (content === undefined || content === null) {
      return EMPTY;
    } else {
      return label === 'error' && exptModalMsg.error[content]
        ? exptModalMsg.error[content]
        : content.toString();
    }
  }

  //set Mailing as !exptTarget[selTask].mailing
  const clickSwitch = async () => {
    try {
      const res = await Axios.post(adminUrl.toggleMailing, { exptTarget, selTask, viewId });
      if (res.data && res.data.exptTarget) {
        setExptTarget(res.data.exptTarget);
      }
    } catch (err) {
      checkErr(err, props.history);
      console.log(err)
    }
  }

  const handleCheckGroup = (checkedGroups, labelGroupIds) => {
    setEditedTask(prev => ({ ...prev, group: labelGroupIds }))
  }

  const scheduleContent = () => {
    if (!exptTarget?.length || !disabledUserExport) {
      return null;
    }

    const normalUserTarget = {
      title: "제목",
      grNm: "그룹",
      userNm: "사용자",
      lastExported: "마지막 전송 성공 데이터",
      error: "오류",
      executedTime: "마지막 실행시간",
      description: "설명"
    }

    const resignedTarget = {
      ...normalUserTarget,
      userNm: "전송한 사용자"
    }
    delete resignedTarget['grNm'];

    let targetLabel, isForResigned;
    if (selTask !== exptTarget.length) {
      targetLabel = normalUserTarget;
      isForResigned = false;
    } else {
      targetLabel = resignedTarget;
      isForResigned = true;
    }


    const onClickAdd = () => {
      let newItemIdx = exptTarget?.length || 0;
      setExptTarget(prev => [...prev, JSON.parse(newExportScheduleItem)]);
      setSelTask(newItemIdx);
      setContentType({ type: viewType.ADD, selTask: newItemIdx });
      setEditedTask({})
    }

    const onCancelEdit = async () => {
      try {
        if (contentType.type === viewType.ADD) {
          let canceledScheduleForm = exptTarget;
          canceledScheduleForm.pop()
          setSelTask(0);
          setExptTarget(canceledScheduleForm);
        } 

        setContentType({ type: viewType.NORMAL });
        setEditInitVal(null);
      } catch (error) {

      }
    }

    const onClickSave = async () => {
      try {
        const { user, group, title } = editedTask;
        if (!(user?.length || group?.length)) {
          // 사용자와 그룹 중 하나는 item이 한개라도 있어야 함
          alert('사용자와 그룹 중 하나에는 값을 입력하십시오.')
          return;
        }

        if (!title) {
          //제목있어야함
          alert('제목을 입력해주시기 바랍니다.')
          return;
        }

        const res = await Axios.post(adminUrl.editExportSchedule, { contentType, editedTask, exptTarget })
        const { result, message } = res.data;

        alert(result ? exportScheduleMsg.success(contentType.type) :
          (message && exportScheduleMsg[message] ? exportScheduleMsg[message] : exportScheduleMsg.fail(contentType.type)))
        setReloadFlg(true);
        setContentType({ type: viewType.NORMAL })
        setEditInitVal(null)
        setEditedTask({})
        console.log(res.data)
      } catch (error) {
        checkErr(error, props.history)
        console.log(error)
      }
    }

    const onClickDelete = async () => {
      if(window.confirm(`${exptTarget[selTask].title}을 삭제하시겠습니까?`)) {
        await deleteSchedule()
      }
    }

    const deleteSchedule = async () => {
      try {
        const res = await Axios.post(adminUrl.editExportSchedule, { contentType: { type: 'delete', selTask }, exptTarget })
        const { result, message } = res.data;
        alert(result ? exportScheduleMsg.success(contentType.type) :
          (message && exportScheduleMsg[message] ? exportScheduleMsg[message] : exportScheduleMsg.fail(contentType.type)))
        setSelTask(0);
        setReloadFlg(true); 
        setEditInitVal(null);
      } catch (error) {
        checkErr(error, props.history)

        console.log(error);
      }
    }
    const editableContents = () => {
      return (
        <List>
          {!editInitVal ? null : Object.keys(targetLabel).map(key => {
            switch (key) {
              case 'title':
                return (
                  <ListItem key={key} >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography variant="body2">
                          {targetLabel[key]}
                        </Typography>
                      }
                      secondary={
                        <TextField
                          fullWidth
                          variant="filled"
                          value={editedTask.title ?? ''}
                          onChange={e => {
                            const value = e.target.value
                            setEditedTask(prev => ({ ...prev, title: value }))
                          }}
                        />
                      }
                    />
                  </ListItem>
                )
              case 'grNm':
                return (
                  <ListItem key={key} >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography variant="body2">
                          {targetLabel[key]}
                        </Typography>
                      }
                      secondary={
                        <GroupSelect
                          type="check"
                          groupInfo={editInitVal?.groups || {}}
                          showHidden={false}
                          style={{ width: '100%' }}
                          onClickCheck={handleCheckGroup}
                          checkedGroupLabel={exptTarget[selTask].group}
                        />

                      }
                    />
                  </ListItem>
                )
              case 'userNm':
                return (
                  <ListItem key={key} >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography variant="body2">
                          {targetLabel[key]}
                        </Typography>
                      }
                      secondary={
                        <Autocomplete
                          fullWidth
                          multiple
                          options={editInitVal?.users || []}
                          getOptionLabel={user => user?.empNm}
                          value={editedTask.user || []}
                          onChange={(event, selected) => {
                            setEditedTask(prev => ({ ...prev, user: selected }));
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                            />
                          )}
                        />}
                    />
                  </ListItem>
                )
              default:
                return (
                  <ListItem key={key} >
                    <ListItemText
                      primary={targetLabel[key]}
                      secondary={getTargetContent(key, isForResigned)}
                    />
                  </ListItem>
                )
            }
          }
          )}
        </List>
      )
    }

    const isEditMode = [viewType.ADD, viewType.EDIT].indexOf(contentType?.type) > -1;
    const renderEditableItem = isEditMode && selTask === contentType.selTask
    return (
      <Grid container style={{ padding: '1.5em' }}>
        {props.showTitle ?
          <React.Fragment>
            <Grid item xs={12} sm={9} key="title">
              <Typography variant="h6" gutterBottom={true}>
                SAP 자동 제출 일정
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} key="btns">
              {isForResigned ? null :
                renderEditableItem ?
                  (<>
                    <IconButton key="save-button" onClick={onClickSave}>
                      <SaveRoundedIcon />
                    </IconButton>
                    <IconButton
                      key="cancel-button"
                      onClick={onCancelEdit} >
                      <ClearRoundedIcon />
                    </IconButton>
                  </>) :
                  (<>
                    <IconButton key="add-button" onClick={onClickAdd}>
                      <AddToPhotosRoundedIcon />
                    </IconButton>
                    <IconButton
                      key="edit-button"
                      onClick={e => setContentType({ type: viewType.EDIT, selTask })}>
                      <EditRoundedIcon />
                    </IconButton>
                    <IconButton key="delete-button" onClick={onClickDelete} >
                    {/* onClick => open Dialog to confirm */}
                      <DeleteRoundedIcon />
                    </IconButton>
                  </>)
              }
            </Grid>
            <Grid item xs={12} key="divider">
              <Divider />
            </Grid>
          </React.Fragment>
          : null}
        <Grid item xs={3} key="list">
          <List>
            {exptTarget.map(e =>
              <ListItem
                button
                key={e.id}
                selected={selTask === e.id}
                onClick={onClickTask(e.id)}
                disabled={isEditMode}
              >
                {e.title}
              </ListItem>)}
            <ListItem
              button
              key="disabledUserScheduleTitle"
              selected={selTask === exptTarget.length}
              onClick={onClickTask(exptTarget.length)}
              disabled={isEditMode}
            >
              {disabledUserExport.title}
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={9} key="contents">
          {selTask === undefined || selTask === null ?
            null :
            <List>
              {/* contents-area */}
              {renderEditableItem ?
                editableContents() :
                Object.keys(targetLabel).map(key =>
                  <ListItem key={key} >
                    <ListItemText
                      primary={targetLabel[key]}
                      secondary={getTargetContent(key, isForResigned)}
                    />
                    {key === 'title' && !isForResigned
                      ? <ListItemSecondaryAction style={{ display: 'flex', alignItems: 'center' }}>
                        <MailOutlineIcon style={{ color: 'grey' }} />
                        <FormControlLabel
                          labelPlacement="start"
                          control={<Switch
                            checked={exptTarget[selTask].mailing}
                            onClick={clickSwitch}
                          />}
                          label='제출 안내 메일 전송'
                        />
                      </ListItemSecondaryAction>
                      : null}
                  </ListItem>
                )
              }
              {/* end-of-contents */}
            </List>
          }
        </Grid>
      </Grid>)
  }


  return (scheduleContent())

}

export default ExportSchedule;
