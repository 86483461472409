import { Chip, makeStyles, Popover } from '@material-ui/core';
import React, { useState } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import NewGroupNav from './NewGroupNav';

const useStyles = makeStyles(() => ({
  groupCheck: {
    flexWrap:'wrap',
    display: 'flex',
    alignItems: 'center',
    padding: '0.25em 0.35em',
    border: '1px solid #cccccc',
    borderRadius: '3px',
    minHeight: '3em',
    '&:hover': {
      border: "1px solid black",
    },
    position: 'relative',
    cursor: 'pointer',
  },
  checkedGrChip : {
    margin: '3px'
  },
  groupSelect: {
    //border: '1px solid rgba(0, 0, 0, 0.23)',
    //borderRadius: '4px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    height: '2em',
    '&:hover': {
      borderBottom: "1px solid black",
    },
    position: 'relative',
    marginRight: '0.2em',
    cursor: 'pointer',
  },

  disableSelect: {
    borderBottom: '1px dotted #888888',
    height: '2em',
    position: 'relative',
    marginRight: '0.2em',
    color: 'grey'
  },
  groupSelectDropDownIcon: {
    position: 'absolute',
  top: 'calc(50% - 0.5em)',
    right: 0
  },
  groupSelectSpan: {
    position: 'absolute',
    top: 'calc(50% - 0.6em)',
    marginLeft: '10px',
    marginRight: '10px',
    fontSize: '0.95em',
  },
}))

const GroupSelect = props => {
  const { groupInfo, selectedGr, disable, type } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [checkedGroupLabel, setCheckedGroupLabel] = useState(props.checkedGroupLabel);
  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const handleClickGroupSelect = event => {
    if (disable) return
    let ae = anchorEl ? null : event.currentTarget;
    setAnchorEl(ae);
  }

  const handleGroupRowSelect = selectedGr => {
    setAnchorEl(null);
    // setSelectedGr(selectedGr)
    //pass to prop
    if (props.getSelectedGr) {
      props.getSelectedGr(selectedGr)
    }
  }

  const handleGroupNavCheck = (checkedGroups) => {
    const labelGroupIds = getLabelGroupIds(checkedGroups);
    setCheckedGroupLabel(labelGroupIds)
    if (props.onClickCheck) {
      props.onClickCheck(checkedGroups, labelGroupIds)
    }
  }

  const getLabelGroupIds = groups => {
    const { viewState } = groupInfo;
    let hasDescendant = new Set();
    for (let cg of groups) {
      const ancestors = viewState[cg].path.split('/');
      let addFlg = true;
      for (let i = 0; i < ancestors.length - 2; i++) {
        if (viewState[ancestors[i]].check) {
          addFlg = false;
          hasDescendant.add(Number(ancestors[i]));
          break;
        }
      }
      if (addFlg) {
        hasDescendant.add(Number(cg))
      }
    }
    return Array.from(hasDescendant)
  }


  return (
    <div id="gr-select-container" key="gr-select" style={{ display: 'inlineFlex' }} tabIndex={0}>
      {type === 'check' ? 
        <div style={{
          float: 'left', width: props.style?.width ?? '15em',
          
        }}>

      <div onClick={handleClickGroupSelect}
          className={disable ? classes.disableSelect : classes.groupCheck}
            style={{ maxWidth: props.style?.width ? `calc(${props.style.width} - 6px)` : '15em' }}
      > 
          {checkedGroupLabel && groupInfo?.viewState ?
          checkedGroupLabel.map(grId => 
            <Chip key={grId} className={classes.checkedGrChip} label={groupInfo?.viewState[grId].grNm}/>) :
        null}
        <ArrowDropDownIcon color="action" className={classes.groupSelectDropDownIcon} />
      </div> 
        </div>:
      <div
        onClick={handleClickGroupSelect}
        className={disable ? classes.disableSelect : classes.groupSelect}
        style={{ float: 'left', width: props.style?.width ?? '15em' }}
      >
        <span className={classes.groupSelectSpan}>
          {selectedGr?.grNm ?? null}
        </span>
        <ArrowDropDownIcon color="action" className={classes.groupSelectDropDownIcon} />
      </div>}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ zIndex: 9999 }}
      >

        {groupInfo ? <NewGroupNav
          type={type || "select"}
          groups={groupInfo?.groups ?? undefined}
          viewState={groupInfo?.viewState ?? undefined}
          showHidden={props.showHidden}
          onClickRow={handleGroupRowSelect}
          onClickCheck={handleGroupNavCheck}
        /> : 
        null}

      </Popover>
    </div>
  )
}

export default GroupSelect;