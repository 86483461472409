import React from 'react';
import MaterialTable from '@material-table/core';
class SapPrjCdTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openFilter: false,
            tableRef: React.createRef(),
            selectedCd : null,
        }
    }

    componentDidMount() {
        const columns = [
            { title: "Project Code", field: "prjCode" },
            { title: "Project 명칭", field: "prjName" }
        ];
        const prjList = this.props.sapPrj;
        this.setState({ columns, prjList });
    }

    handleOnRowClick(rowData) {
        rowData.tableData.checked = !rowData.tableData.checked;
        let { selSapCd } = this.props ? this.props : {};
        const { prjCode } = rowData;

        if (Object.keys(selSapCd).includes(prjCode)) {
            delete selSapCd[prjCode];
        }
        this.props.setSapCd(selSapCd);
    }

    handleSingularSelect(rowData) {
        let selSapCd = {};
        selSapCd[String(rowData.prjCode)] = 100;
        this.props.setSapCd(selSapCd);
        
        this.setState({selectedRow : true});
    }

    handleOnSelect(rows) {
        let selected = {};
        let { selSapCd } = this.props ? this.props : {};
        rows.forEach(r => {
            selected[r.prjCode] = selSapCd[r.prjCode];
        });

        this.props.setSapCd(selected);

    }

    setSapPrj(sapPrj) {
        let { selSapCd } = this.props;
        let cdList = Object.keys(selSapCd);
        for (let p of sapPrj) {
            if (p.tableData && p.tableData.checked === true) {
                p.tableData.checked = false;
            }
            //p.tableData.checked = false;
            for (let c of cdList) {
                if (p.prjCode === c) {
                    p.tableData ? p.tableData.checked = true : p['tableData'] = { checked: true };
                    break;
                }
            }
        }
        return sapPrj;
    }

    render() {
        //table settings for SAP prj 
        const options = {
            //selection: true,
            showTitle: false,
            pageSize: 10,
            filtering: this.state.openFilter,
            showTextRowsSelected  : Object.keys(this.props.selSapCd).length !== 0,
            rowStyle: rowData => ({
                backgroundColor: this.state.selectedRow
                && rowData.prjCode === Object.keys(this.props.selSapCd).pop() ?
                "lavenderblush":
                "white"
            })
        }

        const actions = [
            {
                icon: 'filter_list',
                tooltip: 'Open Filter',
                isFreeAction: true,
                //isFreeAction: Object.keys(this.props.selSapCd).length === 0,
                onClick: e => this.setState({ openFilter: !this.state.openFilter })
            },
        ];

        return (
            <MaterialTable
                columns={this.state.columns ? this.state.columns : []}
                options={options}
                actions={actions}
                data={this.props.sapPrj ? this.setSapPrj(this.props.sapPrj) : []}
                //data={this.state.prjList ? this.state.prjList : []}
                //components={{ Container: props => <Paper {...props} elevation={0}/> }}
                //**********************comment out below when singular select is no longer needed**********************
                onRowClick={(event, rowData) => { this.handleSingularSelect(rowData) }}
                //****************************uncomment below when multiple select is needed****************************
                //onSelectionChange={(rows) => this.handleOnSelect(rows)}
                //onRowClick={(event, rowData) => { this.handleOnRowClick(rowData) }}
            />
        );
    }
}

export default SapPrjCdTable;