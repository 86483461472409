export const styles = {
    container: {
        alignItems: 'center',
        margin: 'auto',

    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '1px solid #c0c0c0',
        borderRadius: '0.4em',
        paddingTop: '5em',
        paddingBottom: '5em',
        marginTop: '10em',
    },
    logo: {
        height: 60,
        marginBottom: 20,

    },
    form: {
        marginTop: '1em',
        paddingLeft: '3em',
        paddingRight: '3em'
    },
    formChild: {
        marginTop: '2rem',
    },
    msg: {
        marginTop: 20,
        marginBottom: -20
    },
    input: {
        // height: '4em',
        fontSize: '1.6rem',
        textAlign:'left',
    },
    button: {
        fontSize: '1.3em',
        boxShadow: 'none'
    },
    passChangeRequest : {
        marginBottom : '10px'
    },
    pwdInput : {
        margin : '10px 0px 10px 0px'
    }


}