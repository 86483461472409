export const checkErr = (err, history) => {
    if(!err.response || !err.response.data) {
        return undefined;
    }

    if(err.response.data.noSession) {
        redirectPathPush(history, '/', 'redir=1');
        return 'noSession';
    }

    if(err.response.data.notAdmin) {
        //TODO: add a message to APP to notify error 
        redirectPathPush(history, '/app', 'redir=2');
        return 'notAdmin';
    }

    if(err.response.data.inaccessible) {
        //TODO: add a message to ADMIN to notify error 
        redirectPathPush(history, '/admin', 'redir=3');
        return 'inaccessible';
    }

    return undefined;
}

//redirect to given parameter pathname
const redirectPathPush = (history, pathname, search) => {
    if (!history) {
        return;
    }
    history.push({ pathname, search });
}
 