 import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Admin from './admin/Admin';
import Login from './common/Login';
import { Switch, Route, BrowserRouter } from "react-router-dom";

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route exact path='/' component={Login}></Route>
            <Route path='/app' component={App}></Route>
            <Route path='/(admin|submitStatus|project|timesheet|manageGroups|manageUsers)' component={Admin}></Route>
        </Switch>
    </BrowserRouter>,
    document.getElementById('root')
);

// ReactDOM.render(<App />, document.getElementById('root'));
// ReactDOM.render(<Admin />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
