import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(() => ({
  checkContainer: {
    width: 290,
    maxHeight: 400,
    overflowY: 'auto',
  },
  selectContainer: {
    width: 230,
    maxHeight: 400,
    overflowY: 'auto',
  },
  noLabelSelectContainer: {
    maxHeight: 'calc(100vh - 12.5rem)',
    overflowY: 'auto',
    boxShadow: 'none',
  },
  nested: {
    paddingLeft: 30,
  },
  nested2: {
    paddingLeft: 30,
  },
  nested3: {
    paddingLeft: 60,
  },
}));

const CollapseWrapper = ({ condition, children, openState }) =>
  condition ?
    <Collapse in={openState} timeout="auto" unmountOnExit>
      <List dense component="div" disablePadding>
        {children}
      </List>
    </Collapse > :
    children

const NewGroupNav = props => {
  // const [groups, setGroups] = useState(props.groups);
  const { groups, } = props;
  const [viewState, setViewState] = useState(props.viewState);
  useEffect(() => {
    setViewState(props.viewState)
  }, [props.group, props.viewState])
  const handleClickExpand = grId => e => {
    viewState[grId].open = !viewState[grId].open;
    setViewState({ ...viewState });
  }

  const getCheckedList = viewState => {
    let checkedList = [];
    for (let [grId, state] of Object.entries(viewState)) {
      if (state.check) {
        checkedList.push(grId);
      }
    }
    props.onClickCheck(checkedList);
  }

  const handleClickRow = (grId, grNm) => () => {
    if (props.type !== "select") {
      return;
    } else {
      let parent, depth, path, position;
      grId = String(grId);
      for (let [key, value] of Object.entries(viewState)) {
        if (grId === key) {
          value.selected = true;
          ({ parent, depth, path, position } = value);
        } else if (value.selected === true) {
          value.selected = false;
        } else {
          continue
        }
      }
      const selected = {
        grId,
        grNm,
        parent,
        depth,
        path,
        position
      }

      if (props.onClickRow)
        props.onClickRow(selected);
    }
  }


  const handleClickCheck = (grId, depth) => () => {
    const check = !viewState[grId].check;
    //check: true => check all children
    //check: false => uncheck all children and grId
    const path = viewState[grId].path;

    for (let state of Object.values(viewState)) {
      if (!props.showHidden && state.hidden) {
        continue;
      }

      if (grId === "0" || state.path.indexOf(path) === 0) {
        state.check = check;
      }

      if (!check && path.indexOf(state.path) === 0) {

        state.check = check;
      }
    }

    if (check && grId !== "0") {
      const ancestors = path.split("/");
      for (let i = ancestors.length - 2; i >= 0; i--) {
        let p = viewState[ancestors[i]].path;
        let checkFlg = true;
        for (let state of Object.values(viewState)) {
          if (state.path.indexOf(p) < -1) {
            continue;
          } else if (state.path !== p && state.path.indexOf(p) === 0 && !state.check) {
            if (!props.showHidden && state.hidden) {
              continue;
            }
            checkFlg = false;
          }
        }

        if (checkFlg) {
          viewState[ancestors[i]].check = check;
        }
      }
    }
    setViewState({ ...viewState });
    if (props.onClickCheck) {
      getCheckedList(viewState);
    }
  }

  const classes = useStyles();
  const { option, showHidden, type, variant } = props;
  const showCheck = type === 'check';
  return (
    <Paper className={variant ? classes[`${variant}Container`] : classes[`${type}Container`]} style={option}>
      {/* 전사  */}
      {(!groups || !viewState) ? null :
        <List dense >
          <ListItem
            key="list-head"
            button
            onClick={handleClickRow(groups.grId, groups.grNm)}
            disabled={viewState[groups.grId].disabled}
            selected={viewState[groups.grId].selected}
          >
            {showCheck ?
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={viewState[groups.grId].check}
                  tabIndex={-1}
                  disableRipple
                  onChange={handleClickCheck(groups.grId, groups.depth)}
                />
              </ListItemIcon>
              : null}
            <ListItemText id={groups.grId} primary={groups.grNm} />
          </ListItem>
          {!groups.child ? null :
            <GroupChildItem
              parentId={groups.grId}
              child={groups.child}
              clickRow={handleClickRow}
              clickExpand={handleClickExpand}
              viewState={viewState}
              showHidden={showHidden}
              showCheck={showCheck}
              clickCheck={handleClickCheck}
            />
          }
        </List>
      }
    </Paper>
  )
}
const GroupChildItem = props => {
  const currentNode = props.child;
  const classes = useStyles();
  const { viewState, parentId, showHidden, showCheck } = props;
  return (
    <CollapseWrapper
      condition={viewState[parentId].depth > 0}
      openState={viewState[parentId].open}
    >
        {currentNode.map(current => {
          return !viewState[current.grId] || (!showHidden && current.hidden) ? null
            : (<React.Fragment key={current.grId}>
              <ListItem
                button
                style={{ paddingLeft: `${20 + (current.depth * 10)}px` }}
                key={`list-item-${current.grId}`}
                onClick={props.clickRow(current.grId, current.grNm)}
                className={classes[`nested${current.depth}`]}
                disabled={viewState[current.grId]?.disabled}
                selected={viewState[current.grId]?.selected}
              >
                {showCheck ? <ListItemIcon>
                  <Checkbox
                    checked={viewState[current.grId].check}
                    disableRipple
                    onChange={props.clickCheck(current.grId, current.depth)}
                  />
                </ListItemIcon> : null}
                <ListItemText id={current.grId} primary={current.grNm} />
                {!current.child ? null :
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="comments"
                      onClick={props.clickExpand(current.grId)}
                    >
                      {viewState[current.grId].open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </ListItemSecondaryAction>
                }
              </ListItem>
              {!current.child ?
                null :
                <GroupChildItem
                  parentId={current.grId}
                  child={current.child}
                  clickRow={props.clickRow}
                  clickCheck={props.clickCheck}
                  clickExpand={props.clickExpand}
                  viewState={viewState}
                  showCheck={showCheck}
                  showHidden={showHidden}
                />}
            </React.Fragment>)
        })}
    </CollapseWrapper>

  )
}

export default NewGroupNav;