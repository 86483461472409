const { makeStyles } = require("@material-ui/core")

exports.useStyles = makeStyles(theme => ({
    labelContainer: {
        position: "relative",
        padding: "0.5em",
        width: "100%",
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
        height: "1.1876em",
        marginTop: '16px',
    },
    labelTitle: {
        top: 0,
        left: 0,
        position: "absolute",
        display: "block",
        fontSize: "1rem",
        padding: 0,
        color: "rgba(0, 0, 0, 0.54)"
    },
    labelContent: {
        display: "block",
        position: "static",
        left: "2em",
        textAlign: "center",
        color: "rgba(0, 0, 0, 0.54)"
    },
    tableContainer: {
        overflow: 'auto',
        maxHeight: '20em'
    },
    roleItem: {
        fontSize: '0.95em'
    },
    colorBlue: {
        color: 'cadetblue'
    },
    colorOrange: {
        color: 'orange'
    },
    roleSelect: {
        width: '12em',
        fontSize: '0.95em'
    },
    buttonBlue: {
        backgroundColor: 'cadetblue',
        color: 'white',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
            backgroundColor: '#418b8c',
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
        },
    },
    buttonOrange : {
        backgroundColor: 'orange',
        color: 'white',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
            backgroundColor: 'darkOrange',
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
        },
    },
}));