import React, { useState, useEffect } from 'react';
import { Dialog, Button, DialogActions, AppBar, Tabs, Tab } from '@material-ui/core';

import { adminUrl } from '../common/url';
import Axios from 'axios';

import { checkErr } from '../checkErr';
import ExportSchedule from './ExportSchedule';
import ManualExport from './ManualExport';
Axios.defaults.withCredentials = true;


const ExportModal = props => {
  const [tabIdx, setTabIdx] = useState(0);
  const [exptTarget, setExptTarget] = useState(null)

  const scheduleContent = () => {
    return (
      <React.Fragment>
        {!exptTarget ?
          null :
          <ExportSchedule showTitle={true} exptTarget={exptTarget} viewId={props.viewId} />
        }
        <DialogActions>
          <Button
            onClick={props.onCloseModal}
          >
            Cancel
          </Button>
        </DialogActions>
      </React.Fragment>
    )
  }


  const tabContent = {
    Export: {
      label: 'SAP 수동 제출',
      compo: <ManualExport
        history={props.history}
        groups={props.groups}
        onCloseModal={props.onCloseModal}
      />
    },
    Schedule: { label: 'SAP 자동 제출 일정', compo: exptTarget ? scheduleContent() : null }
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTabIdx = async (event, newIdx) => {
    try {
      if (newIdx === 1) {
        const res = await Axios.post(adminUrl.getExportTasksForView);
        setExptTarget(res.data.exportTargetList)
      }
      setTabIdx(newIdx)
    } catch (err) {
      checkErr(err, props.history)
      console.log(err)
    }
  }

  const TabPanel = () => {
    return (
      <React.Fragment>
        {Object.keys(tabContent).map((t, index) =>
          <div
            key={t}
            role="tabpanel"
            hidden={tabIdx !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
          >
            {tabContent[t].compo}
          </div>
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth='md'
        open={props.openModal} onClose={props.onCloseModal}>
        <AppBar position="static">
          <Tabs value={tabIdx} onChange={handleChangeTabIdx}>
            {Object.keys(tabContent).map((t, i) =>
              <Tab label={tabContent[t].label} key={t} {...a11yProps(i)} />
            )}
          </Tabs>
        </AppBar>
        <TabPanel />
      </Dialog>
    </React.Fragment>
  )
}

export default ExportModal;