import React from 'react';
import SapPrjCdTable from './SapPrjCdTable';
import SapCdPctInput from './SapCdPctInput';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Dialog, DialogTitle, DialogContent, Stepper, Step, TextField,
  FormControl, InputLabel, Select, RadioGroup, FormControlLabel, Radio, Button,
  FormLabel, InputAdornment, StepButton, withStyles, Typography, Snackbar,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Axios from 'axios';
import { addPrjCdDialogMsg } from '../common/Messages';
import { adminUrl } from '../common/url';
import { checkErr } from '../checkErr'
import FaceIcon from '@material-ui/icons/Face';
import AssignmentIcon from '@material-ui/icons/Assignment';
import koLocale from 'date-fns/locale/ko';

Axios.defaults.withCredentials = true;

const styles = {
  input: {
    width: 400
  },
  inputIcon: {
    marginLeft: 15
  },

  stepperRoot: {
    margin: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // alginItems: 'center'
  },

}

class AddPrjCdDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      startDate: new Date(),
      endDate: undefined,
      selDept: undefined,
      selDiv: undefined,
      errCd: undefined,
      selSapCd: {},
      openSnack: false
    }
  }

  //TODO: ADD ERROR LABEL, store step label to messages
  getSteps() {
    return ['Create a project code', 'Select the corresponding SAP project code', 'Set optional information'];
  }

  componentDidMount() {
    this.getAddPrjCdDialogInit();
  }

  getSapCd(selSapCd) {
    try {
      const size = Object.keys(selSapCd).length;
      let pct = 100 / size;
      for (let c in selSapCd) {
        selSapCd[c] = pct;
      }

      this.setState({ selSapCd });
    } catch (err) {
      console.log(err)
    }
  }

  async getAddPrjCdDialogInit() {
    try {
      let res = await Axios.post(adminUrl.getAddPrjCdDialogInit, { viewId: this.props.viewId })
      if (res.data.dept && res.data.dept.length !== 0) {
        this.setState({
          deptList: res.data.dept,
          divList: res.data.div,
          workList: res.data.work,
          selDept: 0,
          selDiv: undefined,
          selWork: undefined,
          selSapCd: {},
          manager: undefined,
          errCd: undefined,
          endDate: undefined,
          startDate: new Date(),
          prjNm: undefined,
          activeStep: 0,
        });
      }

    } catch (err) {
      checkErr(err, this.props.history);
      console.log(err);
    }
  }

  async getPrjAttr(selDept, deptType) {
    if (!selDept) {
      return;
    }
    try {
      let res = await Axios.post(adminUrl.getPrjAttrByDept, { dept: selDept, deptType, viewId: this.props.viewId });
      return res.data;
    } catch (err) {
      checkErr(err, this.props.history);
      throw err;
    }
  }

  async handleChangeDept(e) {
    let selDept = String(e.target.value);
    //Do nothing if user selects empty
    if (selDept === '') {
      this.setState({ selDept });
      return;
    }

    try {
      let prjAttr = await this.getPrjAttr(selDept);
      if (prjAttr) {
        this.setState({
          selDept,
          divList: prjAttr.div,
          selDiv: undefined,
          selWork: undefined,
          workList: prjAttr.work,
          selSapCd: {} //idx : code
        });
      } else {
        this.setState({
          selDept,
          errCd: 'changeDept',
          openSnack: true,
        })
      }
    } catch (err) {
      console.log(err);
      checkErr(err, this.props.history);
      this.setState({
        errCd: 'changeDept',
        openSnack: true,
      });
    }
  }

  async handleChangeSptDept(e) {
    try {
      let selSptDept = e.target.value;
      if (selSptDept === '') {
        this.setState({ selSptDept });
        return;
      }
      let prjAttr = await this.getPrjAttr(selSptDept, 'spt');
      if (prjAttr) {
        this.setState({
          selSptDept,
          sptDivList: prjAttr.div,
          selSptDiv: undefined,
        });
      } else {
        this.setState({
          selSptDept,
          errCd: 'changeDept',
          openSnack: true,
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({
        errCd: 'changeDept',
        openSnack: true,
      });
    }
  }

  async getSapPrjList() {
    try {
      let res = await Axios.post(adminUrl.getSapPrj, { viewId: this.props.viewId });
      this.setState({
        sapPrj: res.data
      });
    } catch (err) {
      console.log(err);
    }
  }

  setSnackBarMsg(errCd, prjCd = undefined) {
    if (prjCd) {
      return addPrjCdDialogMsg.successMsg(prjCd);
    } else {
      return errCd ? (addPrjCdDialogMsg.error[errCd] ? addPrjCdDialogMsg.error[errCd] : errCd) : '프로젝트 추가에 실패했습니다'
    }
  }

  async handleCreatePrj() {
    if (this.state.requesting) {
      this.setState({
        errCd: 'duplicatedReq',
        openSnack: true,
      });
      return;
    } else {
      try {
        const prjInfo = { ...this.state, viewId: this.props.viewId };
        this.setState({
          requesting: true
        });
        let res = await Axios.post(adminUrl.createPrj, prjInfo);
        this.setState({
          requesting: false
        })

        if (!res.data.err) {
          this.setState({
            openSnack: true,
            snackMsg: this.setSnackBarMsg(undefined, res.data.prjCd)
          })
          this.props.onClose(true);
          this.getAddPrjCdDialogInit();
        } else {
          this.setState({
            errCd: res.data.err,
            openSnack: true,
            snackMsg: this.setSnackBarMsg(res.data.err)
          });
        }

      } catch (err) {
        checkErr(err, this.props.history);
        this.setState({
          requesting: false,
          errCd: 'internalError',
          openSnack: true,
          snackMsg: this.setSnackBarMsg('internalError')
        })

        console.log(err);
      }
    }
  }

  getContents(index) {
    let { classes } = this.props;
    switch (index) {
      case 0:
        return (<div className={classes.stepperRoot}>

          <div key="startDate">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
              <KeyboardDatePicker
                clearable
                autoOk
                className={classes.input}
                label="시작일"
                openTo="month"
                format="yyyy-MM"
                InputAdornmentProps={{ position: "start" }}
                views={["year", "month"]}
                value={this.state.startDate}
                onChange={date => this.setState({ startDate: date })}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }} key="departments">
            {this.state.selWork !== 'S'
              ? null
              : <label style={{ marginTop: '0.8rem', marginRight: '1rem', color: 'white', fontWeight: 600, padding: '0.5rem', background: 'cadetblue', borderRadius: '0.2rem' }}>지원 대상 부서</label>
            }
            <FormControl style={{ marginRight: '20px', width: '130px' }} key="dept">
              <InputLabel htmlFor='dept-helper'>부문</InputLabel>
              <Select
                native
                value={this.state.selDept}
                onChange={this.handleChangeDept.bind(this)}
                inputProps={{ id: 'dept-helper' }}
              >
                <option value="" />
                {!this.state.deptList ? null
                  : (this.state.deptList.map(d => (
                    <option key={d.code} value={d.code}>{d.name}</option>
                  )))}
              </Select>
            </FormControl>
            <FormControl style={{ width: '150px' }} key="div">
              <InputLabel>부서</InputLabel>
              <Select
                native
                value={this.state.selDiv ? this.state.selDiv : ""}
                onChange={e => this.setState({ selDiv: e.target.value })}
              >
                <option value="" />
                {!this.state.divList
                  ? null
                  : (this.state.divList.map(d => (
                    <option key={d.id} value={d.code}>{d.name}</option>
                  )))}
              </Select>
            </FormControl>
          </div>

          <div style={{ marginTop: '20px' }} key="work">
            <FormControl>
              <FormLabel>업무</FormLabel>
              <RadioGroup
                row={true}
                value={this.state.selWork ? this.state.selWork : ""}
                onChange={e => { this.setState({ selWork: e.target.value }) }}>
                {!this.state.workList ? null : this.state.workList.map(w => {
                  return <FormControlLabel value={String(w.code)} key={w.code} control={<Radio color="primary" />} label={w.name} />
                })}
              </RadioGroup>
            </FormControl>
            {this.state.selWork !== 'S'
              ? null
              : (<div key="support" style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ marginTop: '0.8rem', marginRight: '1rem', color: 'white', fontWeight: 600, padding: '0.5rem', background: 'cadetblue', borderRadius: '0.2rem' }}>지원 주체 부서</label>
                <FormControl style={{ marginRight: '20px', width: '130px' }} key="dept">
                  <InputLabel htmlFor='dept-helper'>부문</InputLabel>
                  <Select
                    native
                    value={this.state.selSptDept}
                    onChange={this.handleChangeSptDept.bind(this)}
                    inputProps={{ id: 'dept-helper' }}
                  >
                    <option value="" />
                    {!this.state.deptList ? null
                      : (this.state.deptList.map(d => (
                        <option key={d.code} value={d.code}>{d.name}</option>
                      )))}
                  </Select>
                </FormControl>
                <FormControl style={{ width: '150px' }} key="div">
                  <InputLabel>부서</InputLabel>
                  <Select
                    native
                    value={this.state.selSptDiv ? this.state.selSptDiv : ""}
                    onChange={e => this.setState({ selSptDiv: e.target.value })}
                  >
                    <option value="" />
                    {!this.state.sptDivList ? null
                      : (this.state.sptDivList.map(d => (
                        <option key={d.id} value={d.code}>{d.name}</option>
                      )))}
                  </Select>
                </FormControl>
              </div>)}
          </div>
          <div key="prjNm">
            <TextField
              label="Project 명칭"
              margin="normal"
              value={this.state.prjNm ? this.state.prjNm : ""}
              onChange={e => this.setState({ prjNm: e.target.value })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={classes.inputIcon}>
                    <AssignmentIcon style={{ color: 'grey' }} />
                  </InputAdornment>
                ),
                className: classes.input
              }}
            />
          </div>

        </div>);
      case 1:
        let sapPrj = this.props.sapPrj ? this.props.sapPrj : [];
        //업무가 공통일 경우, SAP Code는 공통만 선택 가능 하도록 수정 
        if (this.state.selWork === 'C') {
          sapPrj = sapPrj.filter(s => s.prjCode.indexOf('공통') >= 0 && s.prjCode !== 'P.BOX 공통')
        }
        return (
          <div>
            <SapPrjCdTable
              sapPrj={sapPrj}
              setSapCd={this.getSapCd.bind(this)}
              selSapCd={this.state.selSapCd}
            />
          </div>
        );
      case 2:
        return (
          <div className={classes.stepperRoot}>
            <div key="manager">
              <Autocomplete
                options={this.props.userList}
                getOptionLabel={option => option.userNm}
                style={{ width: 300 }}
                onChange={(event, newValue) => {
                  this.setState({ manager: !newValue || !newValue.userId ? undefined : newValue.userId })
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="담당자"
                    value={this.state.manager}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start" className={classes.inputIcon} >
                          <FaceIcon style={{ color: 'grey' }} />
                        </InputAdornment>
                      ),
                      className: `${classes.input} ${params.InputProps.className} `
                    }}
                    fullWidth
                  />
                )}
              />
            </div>
            <div style={{ marginTop: '20px' }} key="endDate">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
                <KeyboardDatePicker
                  className={classes.input}
                  autoOk
                  label="종료일"
                  openTo="month"
                  value={this.state.endDate ? this.state.endDate : null}
                  format="yyyy-MM"
                  InputAdornmentProps={{ position: "start" }}
                  views={["year", "month"]}
                  onChange={date => this.setState({ endDate: date })}
                />
              </MuiPickersUtilsProvider>
            </div>
            {this.state.selSapCd ?
              <SapCdPctInput
                selSapCd={this.state.selSapCd}
                pctChange={this.handlePctChange.bind(this)}
                setSapCd={this.getSapCd.bind(this)}
                mode="add"
              />
              : null}
          </div>
        )
      default:
        return 'Unknown stepIndex';
    }

  }

  handlePctChange(cd, value) {
    let { selSapCd } = this.state;
    selSapCd[cd] = value;
    this.setState({ selSapCd });
  }

  render() {
    const steps = this.getSteps();
    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={this.props.openAddPrj}
          onClose={this.props.onClose}>
          <DialogTitle style={{ marginBottom: '-2rem' }}>
            Add New Project
          </DialogTitle>
          <DialogContent>
            <div>
              <Stepper style={{ marginBottom: -10 }} nonLinear activeStep={this.state.activeStep} >
                {steps.map((label, index) => {
                  const stepBtnProps = {};
                  if (index === 2) {
                    stepBtnProps.optional = <Typography variant="caption">Optional</Typography>
                  } else {
                    stepBtnProps.optional = <Typography variant="caption">Required</Typography>
                  }
                  return (
                    <Step key={label} >
                      <StepButton
                        onClick={e => this.setState({ activeStep: index })}
                        {...stepBtnProps}
                      >
                        {label}
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>
              <div key="content">
                {this.getContents(this.state.activeStep)}
              </div>
              <div style={{ margin: 20 }} key="btnContainer">
                <div key="btn-left" style={{ float: 'left', }}>
                  <Button
                    disabled={this.state.activeStep === 0}
                    onClick={e => { this.setState({ activeStep: Number(this.state.activeStep) - 1 }) }}
                    size="small"
                  >
                    Back
                  </Button>
                  <Button
                    disabled={this.getSteps().length - 1 === this.state.activeStep}
                    onClick={e => { this.setState({ activeStep: Number(this.state.activeStep) + 1 }) }}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Next
                  </Button>
                </div>
                {this.state.activeStep === 0
                  ? null
                  : (<div key="btn-right" style={{ float: 'right' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={this.handleCreatePrj.bind(this)}
                    >
                      Create Project
                    </Button>
                  </div>)}
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.openSnack}
          onClose={e => { this.setState({ openSnack: false }) }}
          message={<span id="message-id">{this.state.snackMsg}</span>}
        />
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(AddPrjCdDialog);