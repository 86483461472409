import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Chip, TextField, InputAdornment } from '@material-ui/core';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import { addPrjCdDialogMsg } from '../common/Messages';

// TODO: ADD THIS TO PARENTS
// handlePctChange(cd, value) {
//     let { selSapCd } = this.state;
//     selSapCd[cd] = value;
//     this.setState({ selSapCd });
// }

const styles = {
    inputIcon: {
        marginLeft: 15
    },
    pctInput: {
        width: 220
    },

    chips: {
        marginRight: '20px',
    },
    chipTotal : {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 120,
        width: 120,
        textAlign: 'center',
        marginLeft: -1,
    },
    chipText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 100,
        width: 100,
        textAlign: 'center',
        marginLeft: -2,
    },
    pctContainer: {
        marginTop: 20,
        '&:last-child': { marginBottom: 20, },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    pctLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.8rem',
        fontWeight: 400,
        marginTop: 20,
        marginBottom: 0,
    }
};

class SapCdPctInput extends React.Component {
    //FIXME: 반올림없이 100으로 수정 
    getTotalPct(sapCd) {
        let total = 0;
        let pctError;
        for (let c in sapCd) {
            let pct = Number(sapCd[c]);
            if (isNaN(pct)) {
                total = "-";
                pctError = true;
                return { total, pctError };
            }
            total += pct;
        }

        total = Math.round(total * 100) / 100;
        if (total !== 100) {
            pctError = true;
        }

        return { total, pctError };
    }

    pctInputChange(cd, e) {
        let value = e.target.value.length < 5 ? e.target.value : e.target.value.slice(0, 5);
        if (!isNaN(value) && !value.endsWith(".")) {
            value = Math.round(Number(value) * 100) / 100;
        }
        this.props.pctChange(cd, value);
    }

    handleDelete(cd) {
        //delete item of selSapCd
        let { selSapCd } = this.props;
        delete selSapCd[cd];
        //pass codes to parent
        this.props.setSapCd(selSapCd)
    }

    render() {
        const { classes } = this.props;
        let { selSapCd } = this.props;
        let cdList = Object.entries(selSapCd);
        if (!cdList || cdList.length === 0) {
            return null;
        }

        const res = this.getTotalPct(selSapCd);
        const total = res.total;
        const err = Boolean(res.pctError);

        return (
            <React.Fragment>
                <label className={classes.pctLabel}>SAP Project</label>
                {cdList.map(([cd, pct]) => (
                    <div className={classes.pctContainer} key={cd}>
                        <Chip
                            label={<span className={classes.chipText}>{cd}</span>}
                            icon={<EqualizerIcon />}
                            className={classes.chips}
                            onDelete={this.handleDelete.bind(this, cd)}
                        />
                        <TextField
                            //disabled={cdList.length === 1 && this.props.mode === "add"}
                            disabled
                            error={err}
                            value={pct}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" className={classes.inputIcon} >
                                        %
                                    </InputAdornment>
                                ),
                                className: classes.pctInput
                            }}
                            fullWidth
                            onChange={event => this.pctInputChange(cd, event)}
                        />
                    </div>
                ))}
                <div className={classes.pctContainer} style={{ marginTop: 40 }} key="total">
                    <Chip variant="outlined"
                        label={<span className={classes.chipTotal}>TOTAL</span>}
                        icon={<AddCircleIcon />}
                        className={classes.chips}
                    />
                    <TextField
                        disabled
                        error={err}
                        value={total}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" className={classes.inputIcon} >
                                    %
                            </InputAdornment>
                            ),
                            className: classes.pctInput
                        }}
                        fullWidth
                        helperText={err ? addPrjCdDialogMsg.error.pct : ""}
                    />
                </div>
            </React.Fragment>
        );

    }
}

export default withStyles(styles)(SapCdPctInput);