import React from 'react';
import { Grid, List, ListSubheader, Paper, ListItem, ListItemText, IconButton, Typography, Divider, Tooltip } from "@material-ui/core";
import ArrowLeftIcon from '@material-ui/icons/NavigateBefore';
import ArrowRightIcon from '@material-ui/icons/NavigateNext';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import Axios from 'axios';
import format from 'date-fns/format';
import useStyles from '../styles/admin/CheckUserStatStyle';
import { adminUrl } from '../common/url';
import { checkErr } from '../checkErr';
import { userCheckStatMsg } from '../common/Messages';
import DateFnsUtils from '@date-io/date-fns';
import koLocale from 'date-fns/locale/ko';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import GroupSelect from './GroupSelect';
Axios.defaults.withCredentials = true;

const CheckUserStat = (props) => {
  const viewId = props.viewId
  const classes = useStyles();
  const date = new Date();
  const year = date.getFullYear();
  const [userList, setUserList] = React.useState(null);
  const [msg, setMsg] = React.useState(null);
  const [values, setValues] = React.useState({
    year: year,
    month: date.getMonth() + 1,
    yrMon: new Date(),
    //check 값 -> 'all': 전체, 'checked': 완료, 'unchecked': 미완료, 'exported':송신
    check: 'all',
    group: undefined
  });
  const [groups, setGroups] = React.useState(null);
  const [showResigned, setShowResigned] = React.useState(false); // 퇴직자는 기본적으로 표시하지 않는다

  const submitStatus = { unchecked: '미완료', checked: '완료', exported: '송신', undefined: '상태불명' };
  React.useEffect(() => {
    const param = {
      yrMon: format(values.yrMon, 'yyyyMM'),
      check: values.check,
      selectedGr: values.group ? values.group.path : undefined,
      showResigned,
      viewId,
    };
    Axios.post(adminUrl.getUsersAndMonthlyChecks, param)
      .then(res => {
        if (!res.err) {
          const ul = {};
          Object.keys(submitStatus).forEach(s => ul[s] = []);
          Object.defineProperty(ul, 'undefined', { enumerable: false });
          res.data.userList.forEach(u => {
            ul[u.status].push(u);
          })

          setUserList(ul);
          setMsg(null);
        } else {
          console.log(res.err);
        }
      })
      .catch(err => {
        console.error(err);
        let chkErr = checkErr(err, undefined);
        if (chkErr) {
          setMsg(userCheckStatMsg[chkErr])
        }
      });

    //get groups
    if (!groups) {
      handleGetGroup();
    }

  }, [values, groups, showResigned]);


  const handleDateBtn = incr => {
    let { yrMon } = values;
    let year = yrMon.getFullYear();
    let month = yrMon.getMonth() + incr;

    if (month < 0) {
      month = 11;
      year += incr;
    } else if (month > 11) {
      month = 0;
      year += incr;
    }

    setValues({
      ...values,
      yrMon: new Date(year, month)
    });
  }

  const handleGetGroup = async () => {
    try {
      let res = await Axios.post(adminUrl.getGroupsForNav, { disabled: true, viewId });
      const { groups, selectedGr } = res.data;
      setGroups(groups);
      setValues({ ...values, group: selectedGr })
    } catch (err) {
      console.error(err);
      checkErr(err, props.history);
      // if (chkErr) {
      //     setMsg(userCheckStatMsg[chkErr])
      // }
    }
  }

  const handleGroupRowSelect = selectedGr => {
    setValues({ ...values, group: selectedGr });
  }
  //퇴사자 보기 / 감추기 버튼 클릭 시 토글
  const handleToggleShowResigned = () => {
    //현재 showResigned 반대로 set
    setShowResigned(!showResigned);
  }

  return (
    <div className={classes.root}>

      <Grid container spacing={2}>
        <Grid item xs={12} key="title">
          <Typography variant="h5" gutterBottom={true}>
            제출 현황
          </Typography>
          <Divider />
        </Grid>
        <Grid item sm={8} xs={12} key="controller" style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={e => handleDateBtn(-1)}>
            <ArrowLeftIcon />
          </IconButton>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
            <DatePicker
              style={{ width: 110 }}
              autoOk
              variant="inline"
              format="yyyy년 MM월"
              openTo="month"
              views={["year", "month"]}
              value={values.yrMon}
              onChange={date => setValues({ ...values, yrMon: date })}
            />
          </MuiPickersUtilsProvider>
          <IconButton onClick={e => handleDateBtn(1)}>
            <ArrowRightIcon />
          </IconButton>
          <GroupSelect
            type="select"
            groupInfo={groups}
            getSelectedGr={handleGroupRowSelect}
            selectedGr={values?.group}
            showHidden={false}
            style={{ width: '10em' }}
          />
          <div key="resigned" style={{ flexGrow: 1 }}>
            <Tooltip title={showResigned ? "퇴직자 숨기기" : "퇴직자 확인"} arrow >
              <IconButton onClick={handleToggleShowResigned}>
                {showResigned ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="overline" display="block">
            {userList
              ? Object.getOwnPropertyNames(userList).map(u =>
                u === 'undefined' && userList[u].length === 0 ?
                  null :
                  `${submitStatus[u]} : ${userList[u].length}명 / `
              ).join('').slice(0, -2)
              : null
            }
          </Typography>
        </Grid>
        {!msg ? null
          : <Grid item xs={12} key="msg" className={classes.msgContainer}>
            <span className={classes.msgTitle}>
              <WarningRoundedIcon className={classes.msgTitleItem} />
              <label className={classes.msgTitleItem}>
                Error
              </label>
            </span>
            <label className={classes.msgTitleItem}>
              {msg}
            </label>
          </Grid>
        }
        <React.Fragment>
          {!userList ?
            null :
            Object.keys(userList).map(l =>
              <Grid item xs={(12 / Object.keys(userList).length)} key={l}>
                <Paper>
                  <List
                    className={classes.list}
                    dense
                    subheader={
                      <ListSubheader
                        component="div"
                        id="nested-list-subheader"
                        className={classes.listSubheader}
                      >
                        {submitStatus[l]}
                      </ListSubheader>
                    }
                  >
                    {userList
                      ? userList[l].map(u =>
                        <ListItem
                          className={classes.listItem}
                          key={u.userId}
                        >
                          <ListItemText
                            primary={u.userNm}
                          />
                        </ListItem>
                      )
                      : null}
                  </List>
                </Paper>
              </Grid>
            )}
        </React.Fragment>
      </Grid>
    </div>
  )
}

export default CheckUserStat;