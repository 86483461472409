const apiServer = process.env.NODE_ENV === 'development' ? 'http://localhost:5001' : 'https://timesheet.tescom.org:5001';
const urlHead = {
    ADMIN: apiServer + '/admin',
    COMMON: apiServer + '/common',
    USER: apiServer + '/api',
}
const loginUrl = {
    hasSession: urlHead.COMMON + '/hasSession',
    login : urlHead.COMMON + '/login',
    logout: urlHead.COMMON + '/logout',
    getUserRole: urlHead.COMMON + '/getUserRole',
    changePasswordAndSetSession: urlHead.COMMON + '/changePasswordAndSetSession',
    getUserProfileInfo: urlHead.COMMON + '/getUserProfileInfo',
    editUserProfileFromHeader: urlHead.COMMON + '/editUserProfileFromHeader',
}

const userUrl = {
    getUserTableData : urlHead.USER + '/getUserTableData',
    checkUserDataSaved : urlHead.USER + '/checkUserDataSaved',
    saveUserTable : urlHead.USER + '/saveUserTable',
    getProjectList : urlHead.USER + '/getProjectList',  
    editPrjPref : urlHead.USER + '/editPrjPref',
    runSubmit : urlHead.USER + '/runSubmit',
};

const adminUrl = {
    getGroupsForNav: urlHead.ADMIN + '/getGroupsForNav',
    getAdminTabs : urlHead.ADMIN + '/getAdminTabs',
    getGroupsAndUsers: urlHead.ADMIN + '/getGroupsAndUsers',
    getUsersAndMonthlyChecks: urlHead.ADMIN + '/getUsersAndMonthlyChecks',
    initTimesheet : urlHead.ADMIN + '/initTimesheet',
    getPrjInfo : urlHead.ADMIN + '/getPrjInfo',
    getSapPrj : urlHead.ADMIN + '/getSapPrj',
    getAddPrjCdDialogInit : urlHead.ADMIN + '/getAddPrjCdDialogInit',
    getPrjAttrByDept : urlHead.ADMIN + '/getPrjAttrByDept',
    createPrj : urlHead.ADMIN + '/createPrj',
    getPrjLog : urlHead.ADMIN + '/getPrjLog',
    editPrjInfo : urlHead.ADMIN + '/editPrjInfo',
    deletePrjInfo : urlHead.ADMIN + '/deletePrjInfo',
    getInitDataForTable: urlHead.ADMIN + '/getInitDataForTable',
    getUserListForExpModal : urlHead.ADMIN + '/getUserListForExpModal',
    checkExportValid : urlHead.ADMIN + '/checkExportValid',
    runExport : urlHead.ADMIN + '/runExport',
    getExportTasksForView: urlHead.ADMIN + '/getExportTasksForView',
    toggleMailing : urlHead.ADMIN + '/toggleMailing',
    getUsersForUserManageTable: urlHead.ADMIN + '/getUsersForUserManageTable',
    getRnDGroupInfo: urlHead.ADMIN + '/getRnDGroupId',
    getInitEditUserProfileDialog: urlHead.ADMIN + '/getInitEditUserProfileDialog',
    verifyUniqueUserId: urlHead.ADMIN + '/verifyUniqueUserId',
    getEmpNoFromSAP: urlHead.ADMIN + '/getEmpNoFromSAP',
    addUser: urlHead.ADMIN + '/addUser',
    editUser: urlHead.ADMIN + '/editUser',
    getGroupMembers: urlHead.ADMIN + '/getGroupMembers',
    updateUserGroupId: urlHead.ADMIN + '/updateUserGroupId',
    editExportSchedule: urlHead.ADMIN + '/editExportSchedule',
    addGroup: urlHead.ADMIN + '/addGroup',
    deleteGroup: urlHead.ADMIN + '/deleteGroup',
    moveGroup: urlHead.ADMIN + '/moveGroup',
    renameGroup: urlHead.ADMIN + '/renameGroup',
    getGroupDescendants: urlHead.ADMIN + '/getGroupDescendants',

}

export { adminUrl, userUrl, loginUrl};

