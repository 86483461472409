export const style = {
    warningIcons: {
        color: 'orange',
    },
    
    msgContainer : {
        backgroundColor:'lavenderblush',
        padding: '0.5rem',
        margin: '1.2rem',
        borderRadius: '0.2rem'
    },
    msgTitle: {
        display: 'flex',
        alignItems:'center',
        color: 'firebrick',
        marginBottom: '-0.3em'
    },  
    msgTitleItem: {
        marginLeft: '0.5em'
    },
    msgItem : {
        '&:not(:last-child)' : {
            marginBottom: '0.5rem',
        },
    },
    dialogButton: {
        color: 'darkorange',
        fontWeight: 'bold',
        '&:hover':{
            backgroundColor: '#ffead0'
        }
    }, 
    dialogTitle: {
        display: 'flex',
        alignItems:'center',
    },
    dialogTitleIcon: {
        marginRight: "0.4em",
        color: 'orange' 
    }
}