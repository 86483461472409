import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Button, Checkbox, DialogContentText, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Paper, Select, Step,
  StepButton, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip
} from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import { EditUserProfileDialogMsg, loginMsg, userManagementMsg } from '../common/Messages';
import { adminUrl } from '../common/url';
import { checkErr } from '../checkErr';
import { useStyles } from '../styles/admin/EditUserProfileDialogStyle';
import GroupSelect from './GroupSelect';
Axios.defaults.withCredentials = true;

// add snackbar
const getSteps = () => {
  return ['Set Personal Information', 'Select Employee Number', 'Set User Permission'];
}

const emailRegex = /[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,}$/;

const MODE = {
  editUser: "EDIT",
  addUser: "ADD"
}

let groupInfoMaster; // GROUP-SELECT 표시용
const EditUserProfileDialog = props => {
  const { viewId, mode, editUserData } = props;
  const classes = useStyles();
  const steps = getSteps();
  const totalSteps = () => getSteps().length;
  const [activeStep, setActiveStep] = useState(0);
  const [fieldError, setFieldError] = useState({});

  const [fieldValue, setFieldValue] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [addTIMS, setAddTIMS] = useState(true);

  // 화면 표시용 데이터
  const [department, setDepartment] = useState(null);
  const [empList, setEmpList] = useState(null);
  const [roleInfo, setRoleInfo] = useState(null);
  const [permGroupInfo, setPermGroupInfo] = useState([]);
  //수정 전 데이터 저장
  const [preEditState, setPreEditState] = useState(null);

  const fetchInitData = useCallback(async (mode, userRoleSearchEmpId = null, nameSearch = null, editTargetUserData) => {
    try {
      const res = await Axios.post(adminUrl.getInitEditUserProfileDialog, { viewId, mode, userRoleSearchEmpId, nameSearch });
      const { empList, roleInfo, groupInfo, userRoles } = res.data;

      // GROUP 정보를 별도로 저장하였다가 permission 설정 화면에서 이용
      groupInfoMaster = JSON.stringify(groupInfo); //use JSON.stringify to deep-copy
      setEmpList(empList);
      setRoleInfo(roleInfo);
      setDepartment(groupInfo);
      if (mode === MODE.editUser) {
        setPermissions(userRoles);
        userRoles.map(() => JSON.parse(groupInfoMaster));
        setPermGroupInfo(userRoles.map(() => JSON.parse(groupInfoMaster)));
        const { empId, empNm, email, empNo, grId, grNm, path, disabled } = editTargetUserData;

        const initialFieldValue = {
          id: empId,
          email,
          empNo,
          empNm,
          nameSearch: empNm,
          department: { grId, grNm, path },
          disabled
        };
        setFieldValue(initialFieldValue);
        setPreEditState(JSON.stringify({ permissions: userRoles, ...initialFieldValue }));

      }
      return { empList, roleInfo, userRoles }
    } catch (err) {
      console.log(err)
      throw err;
    }
  }, [viewId]);


  //신규 회원 추가 기능 - 초기화면 설정
  useEffect(() => {
    if (mode === MODE.addUser && roleInfo === null && empList === null) {
      fetchInitData(mode).catch(err => checkErr(err, props.history)); //FIXME: checkerr
    }
  }, [mode, roleInfo, empList, fetchInitData, props.history]);

  // 기존 회원 정보 수정 기능 - 초기화면 설정
  useEffect(() => {
    if (mode === MODE.editUser && editUserData) {
      setActiveStep(0)
      const { empId, empNm } = editUserData;

      fetchInitData(mode, empId, empNm, editUserData)
    
    }
  }, [mode, editUserData, fetchInitData])



  const handleChangeInput = e => {
    const { id, value } = e.target;
    
    fieldValue[id] = id !== 'npw' || id !== 'cpw' ? value.trim() : value; // Trim whitespace
    setFieldValue({ ...fieldValue });
  }

  const handleRemoveReadOnly = e => {
    e.target.removeAttribute('readOnly')
  }

  const onSelectDepartment = (selectedGr) => {
    setFieldValue({ ...fieldValue, 'department': selectedGr })
  }


  const handleValidation = {
    password: e => {
      const { id, value } = e.target;
      if (id === 'npw' && !value) {
        fieldError[id] = loginMsg.required;
      }
      // else if (/^\s|\s$/.test(value)) {
        // fieldError[id] = loginMsg.whitespace;
      // } 
      else if (id === 'npw' && value.length < 5) {
        fieldError[id] = loginMsg.length;
      } else if (id === 'cpw' && fieldValue.npw !== fieldValue.cpw) {
        fieldError[id] = loginMsg.unmatched;
      } else {
        delete fieldError[id];
      }
      setFieldError({ ...fieldError })
    },
    email: e => {
      const { id, value } = e.target;
      if (!value) {
        fieldError[id] = loginMsg.required;
      } else if (!emailRegex.test(value)) {
        fieldError[id] = loginMsg.invalidEmail;
      } else {
        delete fieldError[id];
      }
      setFieldError({ ...fieldError });
    },
    id: async (e) => {
      const { id, value } = e.target;

      if (!value) {
        fieldError[id] = loginMsg.required;
      } else {
        const isUniqueId = await verifyUniqueIdOnBlur(value);
        if (!isUniqueId) {
          fieldError[id] = loginMsg.duplicatedId
        } else if (id.match(/[^a-zA-Z0-9]/)) {
          //set
        } else {
          delete fieldError[id];
        }
      }
      setFieldError({ ...fieldError })
    },

  }

  const verifyUniqueIdOnBlur = async (empId) => {
    try {
      const res = await Axios.post(adminUrl.verifyUniqueUserId, { viewId, empId });
      return !res.data || res.data.isUnique
    } catch (err) {
      checkErr(err, props.history)
      console.log(err)
      return true;
    }
  }

  const checkAddTIMS = e => {
    setAddTIMS(e.target.checked)
  }

  //step 2
  const getEmpNoFromSAP = async () => {
    try {
      const empNm = fieldValue?.nameSearch
      const res = await Axios.post(adminUrl.getEmpNoFromSAP, { viewId, empNm });
      setEmpList(res.data.empList)
    } catch (err) {
      checkErr(err, props.history);
      console.log(err);
    }
  }

  const handleClickEmpCheckBox = (empNo, empNm) => e => {
    if (fieldValue.empNo === empNo) {
      setFieldValue({
        ...fieldValue,
        empNo: null,
        empNm: null
      })
    } else {
      setFieldValue({
        ...fieldValue,
        empNo,
        empNm
      })
    }
  }

  //step 3
  const addRowOnTable = () => {
    if (permissions.some(permission => permission.role === 'admin')) {
      return; //FIXME: 메세지 추가
    }
    
    permissions.push({ role: undefined, group: undefined });
    setPermissions([...permissions]);
    setPermGroupInfo([...permGroupInfo, JSON.parse(groupInfoMaster)]);
  }

  const removeRowOnTable = index => e => {
    setPermissions(permissions.filter((p, i) => i !== index));
    setPermGroupInfo(permGroupInfo.filter((p, i) => i !== index));
  }
  
  const getRnDGroupId = async () => {
    try {
      const res = await Axios.post(adminUrl.getRnDGroupInfo);
      return res.data.rndInfo
    } catch (err) {
      return;
    }
  }

  const handleSelectRole = index => async e => {
    const { value } = e.target;
    let newPermissions = permissions;
    //TODO: add validation
    if(permissions.some(permission => 
      ((permission.role && permission.role === value) || ((value !== 'admin' ? false : true) &&  permission.role === 'admin')))) {
      //중복 //TODO: add helper-text
      return
    }

    if (value === 'admin') {
      index = 0;
      newPermissions = [{}]
    }

    const newGroup = value === 'project' || value === 'admin' ? { ...await getRnDGroupId(), disable: true } :
      { ...newPermissions[index].group, disable: false };

    newPermissions[index] = { role: value, group: newGroup };

    setPermissions([...newPermissions]);
  }

  const handleGroupSelect = (selectedGr, index) => {
    permissions[index].group = selectedGr;
    setPermissions([...permissions])
  }

  const addUser = async () => {
    try {
      const res = await Axios.post(adminUrl.addUser,
        { viewId, fieldValue, permissions, addTIMS });

      const { result, message, fieldError } = res.data;
      let msg;
      let type = 'error';
      if (fieldError) {
        msg = [EditUserProfileDialogMsg.fieldError];
        for (let [field, errorType] of Object.entries(fieldError)) {
          msg.push(<br />);
          msg.push(`${EditUserProfileDialogMsg.fieldNm[field]}: `);
          errorType.forEach(e => { msg.push(loginMsg[e]) })
        }
      } else if (result) {
        type = 'success';
        setFieldValue({});
        props.closeDialog();
        props.reloadFlg(prev => !prev);
        setFieldError({});
        setPermissions([]);
        setAddTIMS(true);
        setActiveStep(0);
        setRoleInfo(null);
      } else {
        msg = message && EditUserProfileDialogMsg[message] ? EditUserProfileDialogMsg[message] : userManagementMsg.error('edit')
      }
      props.setSnack({
        open: true, message: msg ?? userManagementMsg[type]('edit'), type
      }); 

    } catch (err) {
      checkErr(err, props.history);
      console.error(err)
    }
  }

  const editUser = async () => {
    try {
      const res = await Axios.post(adminUrl.editUser, { viewId, fieldValue, permissions, preEditState: JSON.parse(preEditState) });
      const { result, message, fieldError } = res.data;
      let msg;
      let type = 'error', open = true;
      if (fieldError) {
        msg = [EditUserProfileDialogMsg.fieldError];
        for (let [field, errorType] of Object.entries(fieldError)) {
          msg.push(<br />);
          msg.push(`${EditUserProfileDialogMsg.fieldNm[field]}: `);
          errorType.forEach(e => { msg.push(loginMsg[e]) })
        }
      } else if (result) {
        type = 'success';
        props.closeDialog();
        props.reloadFlg(prev => !prev);
        setFieldValue({});
        setFieldError({});
        setPermissions([]);
        setAddTIMS(true);
        setActiveStep(0);
        setRoleInfo(null);
      } else {
        msg = message && EditUserProfileDialogMsg[message] ? EditUserProfileDialogMsg[message] : userManagementMsg.error('edit')
      }

      props.setSnack({
        open, message: msg ?? userManagementMsg[type]('edit'), type
      });
    } catch (err) {
      if (!checkErr(err, props.history)) {
        props.setSnack({
          open: true, message: "사용자 정보 수정에 실패했습니다.", type: 'error'
        });
      }
      console.error(err);
    }
  }

  const getContents = (step) => {
    switch (step) {
      // STEP 1: ID, PW, Email, 소속 설정
      case 0:
        return (
          <form>
            <Grid container spacing={3} alignContent="center" justifyContent="center">
              <Grid item xs={12} key="user-id-container">
                <TextField
                  disabled={MODE.editUser === mode}
                  key="user-id-input"
                  label="ID"
                  id="id"
                  value={ (fieldValue.id || '')}
                  required
                  color="primary"
                  variant="filled"
                  onChange={handleChangeInput}
                  onBlur={handleValidation.id}
                  error={Boolean(fieldError?.id)}
                  helperText={fieldError?.id ?? null}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} key="pwd-container">
                <TextField
                  key="password-input"
                  label="비밀번호"
                  id="npw"
                  value={fieldValue.npw || ''}
                  variant="filled"
                  type="password"
                  color="primary"
                  onChange={handleChangeInput}
                  onBlur={handleValidation.password}
                  error={Boolean(fieldError?.npw)}
                  helperText={fieldError?.npw ?? null}
                  inputProps={{
                    readOnly: true,
                    autoComplete: "new-password"
                  }}
                  onFocus={handleRemoveReadOnly}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} key="pwd-confirm-container">
                <TextField
                  key="password-confirm-input"
                  label="비밀번호 확인"
                  id="cpw"
                  value={fieldValue.cpw || ''}
                  variant="filled"
                  type="password"
                  color="primary"
                  onChange={handleChangeInput}
                  onBlur={handleValidation.password}
                  error={Boolean(fieldError?.cpw)}
                  helperText={fieldError?.cpw ?? null}
                  inputProps={{
                    readOnly: true,
                    autoComplete: "new-password-confirm"
                  }}
                  onFocus={handleRemoveReadOnly}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} key="email-container">
                <TextField
                  required
                  key="email-input"
                  label="Email"
                  id="email"
                  value={fieldValue.email || ''}
                  variant="filled"
                  color="primary"
                  onChange={handleChangeInput}
                  onBlur={handleValidation.email}
                  error={Boolean(fieldError?.email)}
                  helperText={fieldError?.email || null}
                  inputProps={{
                    autoComplete: "new-email"
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} key="group-container">
                <Grid container spacing={3} alignContent="center" justifyContent="center">
                  <Grid item xs={2} key="group-label" style={{ marginTop: '7px' }}>
                    소속
                  </Grid>
                  <Grid item xs={10} key="group-select">
                    <GroupSelect
                      type="select"
                      groupInfo={department}
                      getSelectedGr={onSelectDepartment}
                      selectedGr={fieldValue?.department}
                      showHidden={true}
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} key="add-to-TIMS-container">
                {mode === MODE.addUser ?
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" checked={addTIMS} onChange={checkAddTIMS} />
                    }
                    label="TIMS에 회원 정보 추가"
                    labelPlacement="end"
                  /> :
                  <FormControlLabel
                    control={
                      <Checkbox 
                        color="secondary" 
                        checked={Boolean(fieldValue?.disabled) || false} 
                        onChange={e => setFieldValue({ ...fieldValue, disabled: e.target.checked})}
                      />
                    }
                    label="계정 비활성화"
                    labelPlacement="end"
                  />
                }

              </Grid>
            </Grid>
          </form>

        )
      // STEP 2: 사번, 사용자 이름 설정
      case 1:
        return (
          <Grid container spacing={2} alignContent="center" justifyContent="center">
            <Grid item xs={10} sm={5} key="user-name-container">
              <TextField
                key="user-name-input"
                label="이름"
                id="nameSearch"
                required
                color="primary"
                variant="filled"
                onChange={handleChangeInput}
                value={fieldValue.nameSearch || ''}
                fullWidth
                onKeyPress={e => {
                  e.stopPropagation()
                  if (e.key === "Enter") getEmpNoFromSAP()
                }}
              />
            </Grid>
            <Grid item xs={2} sm={2} key="search-user" >
              <IconButton onClick={getEmpNoFromSAP}>
                <SearchRoundedIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={5} key="emp-infos">
              <Grid container justifyContent="center" alignContent="center">
                <Grid item xs={12} key="empNo-container">
                  <div className={classes.labelContainer}>
                    <label className={classes.labelTitle} key="empNo-title-label">
                      사번
                    </label>
                    <label className={classes.labelContent} key="empNo-content-label">
                      {fieldValue.empNo ?? undefined}
                    </label>
                  </div>

                </Grid>
              </Grid>
              <Grid item xs={12} key="empNm-container">
                <div className={classes.labelContainer}>
                  <label className={classes.labelTitle} key="empNm-title-label">
                    사원명
                  </label>
                  <label className={classes.labelContent} key="empNm-content-label">
                    {fieldValue.empNm ?? undefined}
                  </label>
                </div>
              </Grid>
            </Grid>

            {
              empList ?
                <Grid item xs={12} key="emp_info">
                  <Paper className={classes.tableContainer}>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell key="hd-check" padding="checkbox" ></TableCell>
                          <TableCell key="hd-empNo"> 사번 </TableCell>
                          <TableCell key="hd-empNm"> 이름 </TableCell>
                          <TableCell key="hd-deptNm"> 소속 </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {empList.map((emp, index) =>
                          <TableRow key={`tr-emp-${index}`}>
                            <TableCell key="check-emp" padding="checkbox">
                              <Checkbox
                                id="empInfoCheck"
                                onClick={handleClickEmpCheckBox(emp.empNo, emp.empNm)}
                                checked={fieldValue?.empNo === emp.empNo}
                              />
                            </TableCell>
                            <TableCell key={`empNo-${index}`}>{emp.empNo}</TableCell>
                            <TableCell key={`empNm-${index}`}>{emp.empNm}</TableCell>
                            <TableCell key={`deptNm-${index}`}>{emp.deptNm}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
                : null
            }
          </Grid>
        )
      // STEP 3: 사용자 권한 설정 
      case 2:
        return (
          <React.Fragment>
            <DialogContentText>
              추가 버튼을 클릭하여 관리자 접속 권한을 부여하십시오 <em>(선택 사항)</em>
            </DialogContentText>
            <Paper className={classes.tableContainer}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell key="hd-add" width="20%">
                      <Tooltip title="Add Permission">
                        <Button
                          size="small"
                          className={classes.buttonBlue}
                          startIcon={<AddCircleRoundedIcon />}
                          onClick={addRowOnTable}
                        >
                          추가
                        </Button>
                      </Tooltip>
                    </TableCell>
                    <TableCell key="hd-role">권한명</TableCell>
                    <TableCell key="hd-group">권한 범위</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {permissions.map((p, i) => (
                    <TableRow key={`body-tr-${i}`}>
                      <TableCell key={`td-remove-${i}`}>
                        <Button
                          size="small"
                          className={classes.buttonOrange}
                          startIcon={<RemoveCircleRoundedIcon />}
                          onClick={removeRowOnTable(i)}
                        >
                          삭제
                        </Button>
                      </TableCell>
                      <TableCell key={`td-select-role-${i}`}>
                        <FormControl>
                          <Select
                            value={p.role || ''}
                            onChange={handleSelectRole(i)} //FIXME: VALIDATION MESSAGE
                            className={classes.roleSelect}
                            // helperText={}
                          >
                            <MenuItem className={classes.roleItem} value={''} key={`none-select-${i}`}>
                              None
                            </MenuItem>
                            {roleInfo ? roleInfo.map(role =>
                              <MenuItem className={classes.roleItem} value={role.id} key={`${role.id}-select-${i}`}>
                                {role.title}
                              </MenuItem>
                            ) : null}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell key={`td-select-group-${i}`}>
                        <GroupSelect
                          type="select"
                          disable={p.group?.disable || ['admin', 'project'].includes(p.role)}
                          groupInfo={permGroupInfo[i]}
                          selectedGr={p.group}
                          showHidden={false}
                          style={{ width: '10em' }}
                          getSelectedGr={gr => handleGroupSelect(gr, i)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </React.Fragment>
        )
      default:
        break;
    }
  }

  return (
    <React.Fragment >
      <Dialog open={props.openDialog} maxWidth="sm" onClose={props.closeDialog}>
        <DialogTitle>{mode === MODE.editUser ? 'Edit User Information': 'Add New User'}</DialogTitle>
        <DialogContent style={{ display: 'table', padding: '8px 50px' }} >
          <Stepper activeStep={activeStep} nonLinear={true} >
            {steps.map((label, index) => (
              <Step key={`label-${index}`}>
                <StepButton
                  key={`step-${index}`}
                  onClick={() => setActiveStep(index)}
                // completed={false}
                >
                  <StepLabel>
                    {label}
                  </StepLabel>
                </StepButton>
              </Step>


            ))}
          </Stepper>
          <div>
            {getContents(activeStep)}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={activeStep === 0}
            onClick={() => setActiveStep(activeStep - 1)}
          >
            Back
          </Button>
          <Button
            onClick={() => setActiveStep(activeStep + 1)}
            disabled={activeStep === totalSteps() - 1}
          >
            Next
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={mode !== MODE.editUser ? (activeStep < 1) : false}
            onClick={mode===MODE.addUser ? addUser : editUser}
          >
            save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default EditUserProfileDialog;