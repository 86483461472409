import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import { adminUrl } from '../common/url';
import { checkErr } from '../checkErr';
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, Select, Snackbar, TablePagination, TextField, Typography } from '@material-ui/core';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import ReplyIcon from '@material-ui/icons/ReplyRounded';
import MaterialTable, { MTableAction } from '@material-table/core';
import { Alert } from '@material-ui/lab';
import GroupSelect from './GroupSelect';
import { groupManagementMsg } from '../common/Messages';
import NewGroupNav from './NewGroupNav';
Axios.defaults.withCredentials = true;
const makeUserStatusSpan = (isUserDisabled) => {
  const userStatusSpan = {
    height: '1em',
    borderRadius: '8px',
    padding: '2px 7px',
    fontSize: '11px',
    fontWeight: '500'
  }
  const activeSpan = {
    color: '#2d8a8c',
    background: '#cdecec',
  }
  const disabledSpan = {
    color: '#b72136',
    background: 'rgba(255, 72, 66, 0.16)',
  }

  const makeStyle = () => {
    const color = isUserDisabled ? disabledSpan : activeSpan
    return {
      ...userStatusSpan,
      ...color
    }
  }
  return (
    <span style={makeStyle(isUserDisabled)}>
      {isUserDisabled ? 'DISABLED' : 'ACTIVE'}</span>
  )
}

const tableOptions = {
  search: false,
  selection: true,
  maxBodyHeight: 'calc(100vh - 17em)',
  pageSize: 15,
  pageSizeOptions: [15, 20, 25],
  paginationType: 'stepped',
  headerStyle: { position: 'sticky', top: 0, backgroundColor: '#f5f5f5', fontWeight: 550, color: '#6c6c6c' },
}

const tableOptForGrMemberModifyDialog = {
  search: true,
  selection: true,
  filtering: true,
  showTitle: false,
  padding: 'dense',
  maxBodyHeight: 'calc(100vh - 35em)',
  pageSize: 10,
  pageSizeOptions: [10, 15, 20],
  headerStyle: { position: 'sticky', top: 0, backgroundColor: '#f5f5f5', fontWeight: 550, color: '#6c6c6c' },
}

//column options for user data table. Title values will be set as header
const tableColumns = [
  { title: '성명', field: 'empNm' },
  { title: 'ID', field: 'empId' },
  { title: '소속', field: 'grNm' },
  { title: '상태', field: 'disabled', render: rowData => makeUserStatusSpan(rowData.disabled) },
]

const tableTitle = () => (
  <Typography variant="h6" style={{ fontWeight: 600, color: '#6c6c6c' }}>
    그룹원 정보
  </Typography>
)

const GrMemberActions = {
  ADD: 'add',
  MOVE: 'move',
  DELETE: 'delete'
}

const ManageGroups = props => {
  const { viewId } = props;
  const [currentGr, setCurrentGr] = useState();
  const [groupInfo, setGroupInfo] = useState();
  const [grMembers, setGrMembers] = useState();
  const [selectedGrMembers, setSelectedGrMembers] = useState();
  const [reloadGroups, setReloadGroups] = useState(true);
  const [reloadGrMembers, setReloadGrMembers] = useState(true);

  const [dialogState, setDialogState] = useState({});
  const [snackState, setSnackState] = useState({});

  useEffect(() => {
    if (!groupInfo || reloadGroups) {
      Axios.post(adminUrl.getGroupsForNav, { viewId, draggable: true }).then(res => {
        setGroupInfo(res.data.groups)
        setReloadGroups(false)
      }).catch(err => {
        checkErr(err, props.history)
        console.error(err);
      });
    }
  }, [groupInfo, reloadGroups, viewId, props.history]);


  useEffect(() => {
    const getGroupMembers = async () => {
      try {
        const res = await Axios.post(adminUrl.getGroupMembers, { viewId, currentGr: currentGr?.grId, disabled: false });
        console.log(res.data.grMembers)
        setGrMembers(res.data.grMembers);
      } catch (err) {
        checkErr(err, props.history)
      }
    }
    getGroupMembers();
  }, [reloadGrMembers, currentGr, viewId, props.history])

  const modifyGrMembers = async (e, employees, grId) => {
    try {
      const res = await Axios.post(adminUrl.updateUserGroupId, { employees, grId });
      let snackType = res.data.result ? 'success' : 'error';
      setSnackState({ type: snackType, open: true, message: groupManagementMsg[snackType] })
      if (res.data.result) {
        handleDialog.close();
        setReloadGrMembers(prev => !prev)
      }
    } catch (error) {
      checkErr(error, props.history)
      console.log(error)
    }
  }

  const handleDialog = {
    openModifyGr: action => {
      setDialogState({ open: true, action, type: 'modifyGr' })
    },
    openModifyGrMember: action => {
      setDialogState({ open: true, action, type: 'modifyGrMember' })
    },
    close: () => setDialogState({ open: false })
  }

  const actions = [
    {
      icon: 'delete',
      tooltip: '그룹원 삭제',
      onClick: (e, employees) => modifyGrMembers(e, employees, null),
    },
    {
      icon: () => (<ReplyIcon />),
      tooltip: '그룹원 이동',
      onClick: (e, employees) => handleDialog.openModifyGrMember(GrMemberActions.MOVE)
    },
    {
      icon: 'add',
      isFreeAction: true,
      tooltip: '그룹원 추가',
      onClick: () => handleDialog.openModifyGrMember(GrMemberActions.ADD),
    }
  ];

  const handleGroupRowSelect = selectedGr => {
    setCurrentGr(selectedGr)
  }

  const closeSnackBar = () => {
    setSnackState(prev => ({ ...prev, open: false }));
  }

  const onClickDeleteGroup = e => {
    handleDialog.openModifyGr(groupDialogActions.DELETE)
  }


  return (
    <React.Fragment>
      <Grid container>
        <Grid item sm={2} key='group-grid-left'>
          <Grid container alignContent="center" justifyContent="center">
            <Grid item xs={12} key='btn-gr' style={{display:'flex', justifyContent:'center', marginTop:'0.3em'}}>
              <ButtonGroup 
              disabled={(!currentGr?.grId && currentGr?.grId !== 0)} >
                <Button
                  key='btn-add'
                  onClick={() => handleDialog.openModifyGr('add')}>
                  추가
                </Button>
                <Button
                  key='btn-edit'
                  onClick={() => handleDialog.openModifyGr('rename')}>
                  수정
                </Button>
                <Button
                  key='btn-del'
                  onClick={onClickDeleteGroup}>
                  삭제
                </Button>
                <Button
                  key="btn-move"
                  onClick={() => handleDialog.openModifyGr(groupDialogActions.MOVE)}>
                  이동
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12} key='gr-nav'>
              {groupInfo ? <NewGroupNav
                type="select"
                variant="noLabelSelect"
                groups={groupInfo?.groups ?? undefined}
                viewState={groupInfo?.viewState ?? undefined}
                showHidden
                onClickRow={handleGroupRowSelect}
                reloadGroups={reloadGroups}
              /> :
                null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={10} style={{ padding: '5px 40px 12px 30px' }} key='grMembers'>
          <MaterialTable
            options={tableOptions}
            title={tableTitle()}
            columns={JSON.parse(JSON.stringify(tableColumns))}
            data={grMembers || []}
            actions={actions}
            style={{ boxShadow: 'none' }}
            onSelectionChange={setSelectedGrMembers}
            components={{
              Action: props => (props.action?.isFreeAction ?
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={<AddCircleRoundedIcon style={{ color: 'grey' }} />}
                  onClick={props.action.onClick}
                >
                  추가
                </Button> :
                <MTableAction {...props} />),
              Pagination: (props) => (<TablePagination {...props} style={{ ...props.style, background: '#f5f5f5' }} />),
            }}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={snackState?.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={closeSnackBar}
      >
        <Alert severity={snackState?.type || 'info'} onClose={closeSnackBar} >
          {snackState?.message}
        </Alert>
      </Snackbar>
      {dialogState?.open ? <Dialog open={dialogState.open} onClose={handleDialog.close}>
        {dialogState?.type === 'modifyGrMember' ?
          <ModifyEmpDialogContent
            action={dialogState.action}
            viewId={viewId}
            currentGrId={currentGr?.grId}
            selectedGrMembers={selectedGrMembers}
            onClickModifyGrMembers={modifyGrMembers}
          /> :
          <ModifyGroupDialog
            action={dialogState.action}
            viewId={viewId}
            selectedGr={currentGr}
            handleClose={handleDialog.close}
            setReloadGroups={setReloadGroups}
          />
        }
      </Dialog> : null}
    </React.Fragment>
  )
}


const ModifyEmpDialogContent = props => {
  const { viewId, action, currentGrId, selectedGrMembers } = props;
  const [employees, setEmployees] = useState();
  const [selectedEmp, setSelectedEmp] = useState();
  const [transferGrNav, setTransferGrNav] = useState();
  const [transferGr, setTransferGr] = useState();

  useEffect(() => {
    if (action === GrMemberActions.ADD && !employees) {
      const getUsersForTableData = async () => {
        try {
          const res = await Axios.post(adminUrl.getGroupMembers, { viewId, currentGr: 'ALL' });
          setEmployees(res.data.grMembers);
        } catch (err) {
          checkErr(err, props.history)
          console.error(err)
        }
      }

      getUsersForTableData();
    } else if (action === GrMemberActions.MOVE && !transferGrNav) {
      const handleGetGroup = async () => {
        try {
          let res = await Axios.post(adminUrl.getGroupsForNav, { disabled: false, viewId });
          const { groups, selectedGr } = res.data;
          setTransferGrNav(groups)
          setTransferGr(selectedGr);
        } catch (err) {
          console.error(err);
          checkErr(err, props.history);
        }
      }
      handleGetGroup();
    }
  }, [action])

  const onClickModify = e => {
    if (action === GrMemberActions.ADD) {
      props.onClickModifyGrMembers(e, selectedEmp, currentGrId);
    } else if (action === GrMemberActions.MOVE) {
      props.onClickModifyGrMembers(e, selectedGrMembers, transferGr?.grId);
    }
  }
  return (
    <React.Fragment>
      <DialogTitle>
        {action === GrMemberActions.ADD ? '그룹원 추가' : '그룹원 이동'}
      </DialogTitle>
      <DialogContent>
        {action === GrMemberActions.ADD ? <MaterialTable
          options={tableOptForGrMemberModifyDialog}
          columns={JSON.parse(JSON.stringify(tableColumns))}
          data={employees || []}
          style={{ boxShadow: 'none' }}
          onSelectionChange={setSelectedEmp}
        /> :
          <React.Fragment>
            <DialogContentText>
              이동할 그룹을 선택 하시오
            </DialogContentText>
            <GroupSelect
              type="select"
              groupInfo={transferGrNav}
              getSelectedGr={setTransferGr}
              selectedGr={transferGr}
              showHidden
            />
          </React.Fragment>
        }
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClickModify}>
          {action === GrMemberActions.ADD ? '추가' : '이동'}
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

//FIXME: useReducer
const groupDialogActions = {
  ADD: 'add',
  DELETE: 'delete',
  MOVE: 'move',
  RENAME: 'rename',
}

const ModifyGroupDialog = props => {
  const { viewId, selectedGr, action } = props;
  const [newGrNm, setNewGrNm] = useState();
  const [moveGroup, setMoveGroup] = useState();
  const [moveGroupPosition, setMoveGroupPosition] = useState();
  const [mvGrPosSelect, setMvGrPosSelect] = useState();

  const [mvGrInfo, setMvGrInfo] = useState();
  useEffect(() => {
    if (action === groupDialogActions.RENAME) {
      setNewGrNm(selectedGr.grNm)
    } else if (action === groupDialogActions.MOVE) {
      Axios.post(adminUrl.getGroupsForNav, { viewId }).then(res => {
        setMvGrInfo(res.data.groups)
      }).catch(err => {
        checkErr(err, props.history)
        console.error(err);
      });
    }
  }, [action, selectedGr.grNm, viewId, props.history])

  const getDescendantsCount = useCallback(async selectedGr => {
    try {
      const res = await Axios.post(adminUrl.getGroupDescendants, { viewId, selectedGr })
      console.log(res.data)
      const { descendants } = res.data
      setMvGrPosSelect(descendants)
      setMoveGroupPosition(0)

    } catch (error) {
      checkErr(error, props.history)
      console.log(error);
    }
  }, [viewId, props.history])

  useEffect(() => {
    if (moveGroup && (moveGroup.grId || moveGroup.grId === 0)) {
      getDescendantsCount(moveGroup);
    }
  }, [moveGroup, getDescendantsCount])

  const makeMvPositionOptions = () => {
    let positions;
    if (!mvGrPosSelect) {
      return <MenuItem value={''}>none</MenuItem> 
    }
    
    positions = mvGrPosSelect.map((pos, index) => <MenuItem key={`${pos}-pos`} value={index}>{index + 1}</MenuItem>);

    if (String(selectedGr.parent) !== String(moveGroup.grId)) {
      
      positions.push(<MenuItem key={`-pos`} value={mvGrPosSelect.length}>
        {mvGrPosSelect.length + 1}
      </MenuItem>)
    }
    return positions;
  }

  const getTitleText = () => {
    switch (action) {
      case groupDialogActions.ADD:
        return "새로운 그룹의 이름을 입력하시오"
      case groupDialogActions.RENAME:
        return "변경할 이름을 입력하시오"

      case groupDialogActions.DELETE:
        return <>
          <span role="img" aria-label="warning">🛑</span>
          그룹을 삭제하시겠습니까?
        </>
      case groupDialogActions.MOVE:
        return "이동할 위치를 선택하시오"
      default:
        break;
    }
  }

  const getContents = () => {
    switch (action) {
      case groupDialogActions.ADD:
      case groupDialogActions.RENAME:
        return <TextField
          label="그룹명"
          required
          value={newGrNm || ''}
          onChange={e => setNewGrNm(e.target.value)}
          fullWidth
        />
      case groupDialogActions.DELETE:
        return <DialogContentText>
          그룹을 삭제하는 경우 아래와 같은 정보가 초기화 되거나 삭제 될 수 있습니다.
          <ul>
            <li>삭제: 하위 그룹</li>
            <li>삭제: 관리자 화면에 삭제 대상 그룹에 대한 접근 권한을 가진 경우</li>
            <li>삭제: 프로젝트 코드 생성 값에 대한 그룹 속성</li>
            <li>초기화: 해당 그룹 소속원의 그룹</li>
            <li>초기화: 프로젝트 코드의 그룹 정보</li>
            <li>그 외</li>
          </ul>
        </DialogContentText>
      case groupDialogActions.MOVE:
        return <Grid container spacing={2}>
          <Grid item xs="12" sm="6" key="mv-gr-group-select" style={{display: "flex", alignContent: "center"}}>
            <GroupSelect
              type="select"
              groupInfo={mvGrInfo}
              getSelectedGr={setMoveGroup}
              selectedGr={moveGroup}
              showHidden={true}
              style={{width:'10em'}}
            />
            <span style={{ display: "inline-block", width:'3em' }}>
              하위
            </span>
          </Grid>
          <Grid item xs="12" sm="6" key="mv-gr-position-select" style={{ display: "flex", alignContent: "center" }}>
            <Select
              disabled={!mvGrPosSelect}
              onChange={e => {
                setMoveGroupPosition(e.target.value)
              }}
              value={moveGroupPosition ?? ''}
              style={{width:'10em'}}
            >
              {makeMvPositionOptions()}
            </Select>
            <span style={{ display: "inline-block" }}>
              번째
            </span>
          </Grid>
        </Grid>
      default:
        break;
    }
  }

  const onClickActionButton = async () => {
    try {
      let apiUrl = adminUrl[`${action}Group`];;
      let param;
      switch (action) {
        case groupDialogActions.ADD:
        case groupDialogActions.RENAME:
          if (!newGrNm) {
            alert('no group name');
            return;
          }
          param = { selectedGr, newGrNm }
          break;
        case groupDialogActions.DELETE:
          param = { selectedGr }
          break;
        case groupDialogActions.MOVE:
          param = {
            selectedGrStates: {
              ...selectedGr,
            },
            moveGroupState: {
              parent: Number(moveGroup.grId),
              position: moveGroupPosition
            }
          }
          break;
        default:
          break;
      }

      if (!apiUrl || !param) {
        return alert('실패')
      }

      const res = await Axios.post(apiUrl, { viewId, ...param })
      if (res.data.result) {
        props.setReloadGroups(true)
        props.handleClose();
        if (groupDialogActions.DELETE) {
          //change selectedGr as null
        }
        return alert(' 성공 ')
      } else {
        return alert('실패')
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <DialogTitle>
        {getTitleText()}
      </DialogTitle>
      <DialogContent>
        {getContents()}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClickActionButton}>
          OK
        </Button>
        <Button variant="contained" onClick={props.handleClose}>
          Close
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}
export default React.memo(ManageGroups);