import React from 'react';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const styles = {
    checkContainer: {
        width: 290,
        maxHeight: 400,
        overflowY: 'auto',
    },
    selectContainer: {
        width: 230,
        maxHeight: 400,
        overflowY: 'auto',
    },
    nested: {
        paddingLeft: 30,
    },
    nested2: {
        paddingLeft: 30,
    },
    nested3: {
        paddingLeft: 60,
    },
};

// TODO: move to common
// add this to parent
// async handleGetGroup() {
//     try {
//         let res = await Axios.post('/admin/getGroupsForNav');
//         console.log("handleGetGroup")
//         let {groups, viewState} = res.data.groups;
//         setGroups({groups, viewState});
//     } catch (err) {
//         console.log(err);
//     }
// }

class GroupNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: props.groups,
            viewState: props.viewState
        }
    }

    componentDidMount() {
        //this.handleGetGroup();
    }

    handleGetGroup() {
        let { groups, viewState } = this.props;

        if (groups && viewState) {
            this.setState({
                groups, viewState
            })
        }

    }

    handleClickExpand(grId) {
        let { viewState } = this.state;
        viewState[grId].open = !viewState[grId].open;

        this.setState({ viewState });
    }

    handleClickCheck(grId, depth) {
        let { viewState } = this.state;
        let check = !viewState[grId].check;
        //check: true => check all children
        //check: false => uncheck all children and grId
        const path = viewState[grId].path;

        for (let state of Object.values(viewState)) {
            if (grId === "0" || state.path.indexOf(path) === 0) {
                state.check = check;
            }

            if (!check && path.indexOf(state.path) === 0) {
                state.check = check;
            }
        }

        if (check && grId !== "0") {
            const ancestor = path.split("/");
            for (let i = ancestor.length - 2; i >= 0; i--) {
                let p = viewState[ancestor[i]].path;
                let flg = true;
                for (let state of Object.values(viewState)) {
                    if (state.path !== p && state.path.indexOf(p) === 0 && !state.check) {
                        flg = false;
                    }
                }

                if (flg) {
                    viewState[ancestor[i]].check = check;
                }
            }
        }

        this.setState({ viewState });
        if (this.props.onClickCheck) {
            this.getCheckedList(viewState);
        }
    }


    getCheckedList(viewState) {
        let checkedList = [];
        for (let [grId, state] of Object.entries(viewState)) {
            if (state.check) {
                checkedList.push(grId);
            }
        }
        this.props.onClickCheck(checkedList);
    }

    handleClickRow(grId, grNm) {
        if (this.props.type === "select") {
            let { viewState } = this.state;
            grId = String(grId);
            for (let [key, value] of Object.entries(viewState)) {
                if (grId === key) {
                    value.selected = true;
                } else if (value.selected === true) {
                    value.selected = false;
                }
            }
            let selected = {
                grId: grId,
                grNm: grNm,
                path: viewState[grId].path
            }

            this.props.onClickRow(selected);
        }
    }

    render() {
        const { classes, option, showHidden } = this.props;
        const showCheck = this.props.type === 'check';
        let { groups, viewState } = this.state;
        
        return (
            <Paper className={classes[`${this.props.type}Container`]} style={option}>

                {(groups && viewState) ? (
                    <List dense>
                        <ListItem
                            key="depth0"
                            button
                            onClick={this.handleClickRow.bind(this, groups.grId, groups.grNm)}
                            disabled={viewState[groups.grId].disabled}
                            selected={viewState[groups.grId].selected}
                        >
                            {showCheck ?
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={viewState[groups.grId].check}
                                        tabIndex={-1}
                                        disableRipple
                                        onChange={this.handleClickCheck.bind(this, groups.grId, groups.depth)}
                                    />

                                </ListItemIcon>
                                : null}
                            <ListItemText id={groups.grId} primary={groups.grNm} />
                        </ListItem>
                        {!groups.child ? null :
                            (<List dense component="div" disablePadding >
                                {groups.child.map(d1 => !showHidden && d1.hidden ? null : 
                                (
                                        <React.Fragment key={d1.grId}>
                                            <ListItem
                                                key={d1.grId}
                                                disabled={viewState[d1.grId].disabled}
                                                selected={viewState[d1.grId].selected}
                                                button
                                                onClick={this.handleClickRow.bind(this, d1.grId, d1.grNm)}
                                            >
                                                {showCheck ?
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            checked={viewState[d1.grId].check}
                                                            disableRipple
                                                            onChange={this.handleClickCheck.bind(this, d1.grId, d1.depth)}
                                                        />
                                                    </ListItemIcon>
                                                    : null}
                                                <ListItemText id={d1.grId} primary={d1.grNm} />
                                                {!d1.child ? null : <ListItemSecondaryAction>
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="Expand"
                                                        onClick={this.handleClickExpand.bind(this, d1.grId)}
                                                    >
                                                        {viewState[d1.grId].open ? <ExpandLess /> : <ExpandMore />}
                                                    </IconButton>
                                                </ListItemSecondaryAction>}
                                            </ListItem>

                                            {/* depth 2 */}
                                            {!d1.child ? null :
                                                (<Collapse in={viewState[d1.grId].open} timeout="auto" unmountOnExit>
                                                    <List dense component="div" disablePadding >
                                                        {d1.child.map(d2 => !showHidden && d2.hidden ? null : (
                                                            <React.Fragment key={d2.grId}>
                                                                <ListItem
                                                                    button
                                                                    key={d2.grId}
                                                                    onClick={this.handleClickRow.bind(this, d2.grId, d2.grNm)}
                                                                    className={classes[`nested${d2.depth}`]}
                                                                    disabled={viewState[d2.grId].disabled}
                                                                    selected={viewState[d2.grId].selected}
                                                                >
                                                                    {showCheck ? <ListItemIcon>
                                                                        <Checkbox
                                                                            checked={viewState[d2.grId].check}
                                                                            disableRipple
                                                                            onChange={this.handleClickCheck.bind(this, d2.grId, d2.depth)}
                                                                        />
                                                                    </ListItemIcon> : null}
                                                                    <ListItemText id={d2.grId} primary={d2.grNm} />
                                                                    {!d2.child ? null : <ListItemSecondaryAction>
                                                                        <IconButton
                                                                            edge="end"
                                                                            aria-label="comments"
                                                                            onClick={this.handleClickExpand.bind(this, d2.grId)}
                                                                        >   
                                                                            {viewState[d2.grId].open ? <ExpandLess /> : <ExpandMore />}
                                                                        </IconButton>
                                                                    </ListItemSecondaryAction>}
                                                                </ListItem>

                                                                {/* depth3 */}
                                                                {!d2.child ? null : d2.child.map(d3 => !showHidden && d3.hidden ? null : (
                                                                    <Collapse key={d3.grId} in={viewState[d2.grId].open} timeout="auto" unmountOnExit>
                                                                        <List dense component="div" disablePadding>
                                                                            <ListItem
                                                                                button
                                                                                key={d3.grId}
                                                                                onClick={this.handleClickRow.bind(this, d3.grId, d3.grNm)}
                                                                                className={classes[`nested${d3.depth}`]}
                                                                                disabled={viewState[d3.grId].disabled}
                                                                                selected={viewState[d3.grId].selected}
                                                                            >
                                                                                {showCheck ? <ListItemIcon>
                                                                                    <Checkbox
                                                                                        checked={viewState[d3.grId].check}
                                                                                        disableRipple
                                                                                        onChange={this.handleClickCheck.bind(this, d3.grId, d3.depth)}
                                                                                    />
                                                                                </ListItemIcon> : null}
                                                                                <ListItemText id={d3.grId} primary={d3.grNm} />
                                                                                {!d3.child ? null : <ListItemSecondaryAction>
                                                                                    <IconButton
                                                                                        edge="end"
                                                                                        aria-label="comments"
                                                                                        onClick={this.handleClickExpand.bind(this, d3.grId)}
                                                                                    >
                                                                                        {viewState[d3.grId].open ? <ExpandLess /> : <ExpandMore />}
                                                                                    </IconButton>
                                                                                </ListItemSecondaryAction>}
                                                                            </ListItem>

                                                                        </List>
                                                                    </Collapse>
                                                                ))}
                                                            </React.Fragment>
                                                        ))}
                                                    </List>
                                                </Collapse>

                                                )}
                                        </React.Fragment>
                                    )
                                )}
                            </List>)}
                    </List>
                ) : null}
            </Paper>
        )
    }
}

export default withStyles(styles)(GroupNav);