import React, { useState } from 'react';
import {
    makeStyles, DialogContent, Grid, FormControl, FormLabel,
    RadioGroup, FormControlLabel, Radio, Paper, Button, DialogActions, TextField,
    List, ListItem, InputAdornment, Checkbox, ListItemIcon, ListItemSecondaryAction,
    IconButton, Snackbar
} from '@material-ui/core';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import SearchIcon from '@material-ui/icons/Search';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import GroupNav from './GroupNav';
import { adminUrl } from '../common/url';
import Axios from 'axios';
import DateFnsUtils from '@date-io/date-fns';
import koLocale from 'date-fns/locale/ko';
import ClearIcon from '@material-ui/icons/Clear';
import { Alert } from '@material-ui/lab';
import { exptModalMsg } from '../common/Messages';
import { checkErr } from '../checkErr';
import subMonths from 'date-fns/subMonths';
Axios.defaults.withCredentials = true;

const useStyles = makeStyles(theme => ({
    modalList: {
        minHeight: 400
    },
    radioSpan: {
        fontSize: '0.7em',
        color: 'grey',
        marginLeft: '0.5em'
    },
    btnArrowIcon: {
        marginLeft: '-7px'
    },
    addBtn: {
        marginTop: '1em'
    },
    pickerLabel: {
        marginTop: '0',
        marginBottom: '0.5em'
    },
    msgTitle: {
        display: 'flex',
        alignItems: 'center',
        color: '#e8640c',
        marginBottom: '0.3em',
        fontWeight: 'bold',
    },
    msgTitleItem: {
        marginLeft: '0.5em'
    },
    msgContainer: {
        backgroundColor: 'papayawhip',
        padding: '0.5rem',
        marginBottom: '1.2rem',
        borderRadius: '0.2rem'
    },
}))

const ManualExport = props => {
    const classes = useStyles();
    const [snack, setSnack] = useState({ open: false, msg: undefined, type: undefined });
    const [shouldGetUserList, setShouldGetUserList] = useState(true);
    const [timerFlg, setTimerFlg] = useState(false);
    const [groups, setGroups] = useState(props.groups);
    const [options, setOptions] = useState({ yrMon: new Date() });
    const [userList, setUserList] = useState(undefined);
    const [isRequesting, setIsRequesting] = useState(false);
    //FIXME: 그냥 format setFormat으로 바꾸기
    const [selected, setSelected] = useState({
        format: 'rate',
    })
    const [checked, setChecked] = useState({});
    const [exportList, setExportList] = useState({
        keys: [],
        values: {}
    });


    React.useEffect(() => {
        if (!groups) {
            setGroups(props.groups)
        }

        let timer;
        if (shouldGetUserList) {
            timer = setTimeout(() => getUserList(), timerFlg ? 100 : 0);
        }

        return () => {
            clearTimeout(timer)
        };
    }, [groups, options, shouldGetUserList]);

    const getUserList = async () => {
        try {
            const res = await Axios.post(adminUrl.getUserListForExpModal, options);
            if (res.data) {
                setUserList(res.data);
                setChecked({});
            }
        } catch (err) {
            checkErr(err, props.history)
            console.error(err);
        }
    }

    const setExptSnackMsg = {
        noListItem: () => {
            setSnack({
                open: true, msg: exptModalMsg.noListItem, type: 'warning',
                action: undefined
            });
        },
        success: () => {
            setSnack({
                open: true, msg: exptModalMsg.exptSuccess, type: 'success',
                action: undefined
            });
            setShouldGetUserList(true);
            setTimerFlg(false);
            setExportList({ keys: [], values: {} })
        },
        fail: (type) => {
            setSnack({
                open: true, msg: exptModalMsg.error[type], type: 'error',
                action: undefined
            })
        },
        warning: (invalidList, validList) => {
            setSnack({
                open: true, msg: exptModalMsg.exptWarning(invalidList), type: 'warning',
                action: <Button onClick={runExport(validList)}>OK</Button>
            });
        }
    }

    //run export if no error is found after validation check
    const handleExportClick = async () => {
        if (isRequesting) {
            setExptSnackMsg.fail('duplicateReq');
            return;
        } else {
            try {
                if (exportList.keys.length === 0) {
                    setExptSnackMsg.noListItem();
                    return;
                }

                setIsRequesting(true);
                const res = await Axios.post(adminUrl.checkExportValid, { options, exportList, selected });
                const { invalidList, validList, error } = res.data;

                if (invalidList) {
                    validList.length === 0
                        ? setExptSnackMsg.fail('noValidUser')
                        : setExptSnackMsg.warning(invalidList, validList)
                } else if (error) {
                    setExptSnackMsg.fail(res.data.error);
                } else {
                    setExptSnackMsg.success();
                }
                setIsRequesting(false);
            } catch (err) {
                setIsRequesting(false);
                if (!checkErr(err, props.history)) {
                    setExptSnackMsg.fail('internalError');
                }
                console.log(err);
            }
        }
    }


    const runExport = (validList) => async () => {
        if (isRequesting) {
            setExptSnackMsg.fail('duplicateReq');
            return;
        } else {
            try {
                setIsRequesting(true);
                const res = await Axios.post(adminUrl.runExport, { options, exportList, selected, validList });
                const { error } = res.data;
                error ? setExptSnackMsg.fail(error) : setExptSnackMsg.success();
                setIsRequesting(false);
            } catch (err) {
                setIsRequesting(false);
                if (!checkErr(err, props.history)) {
                    setExptSnackMsg.fail('internalError');
                }
                console.log(err);

            }
        }
    }

    //Export Modal
    const handleGrSelect = (group) => {
        let search;
        setOptions({ ...options, search, group })
        setShouldGetUserList(true);
        setTimerFlg(false);

    }

    const handleDateChange = date => {
        setOptions({ ...options, yrMon: date });
        setShouldGetUserList(true);
        setTimerFlg(false);
        setExportList({
            keys: [],
            values: {}
        });
    }

    const handleSearchChange = e => {
        setShouldGetUserList(true);
        setTimerFlg(true);
        const search = e.target.value;
        setOptions({ ...options, search });
    }

    const handleClickList = ([userId, userNm], checkType) => e => {
        let checkedList;
        const checkedIds = Object.keys(checked);
        //전체 선택 체크박스 선택 시
        if (checkType === 'all') {
            checkedList = {};
            let check = false;
            for (let u of userList) {
                if (!u.disabled && !checkedIds.includes(u.userId)) {
                    check = true;
                    break;
                }
            }

            if (check) {
                userList.forEach(u => {
                    if (!u.disabled) {
                        checkedList[u.userId] = u.userNm;
                    }
                })
            }
            setChecked(checkedList);
        } else {
            let idx = checkedIds.indexOf(userId);
            if (idx === -1) {
                checkedList = { ...checked, [userId]: userNm };
            } else {
                const { [userId]: userNm, ...rest } = checked;
                checkedList = rest;
            }

            setChecked(checkedList);
        }
    }

    const getCheckAllState = () => {
        let check = true;
        let haveActivated = false;
        const keys = Object.keys(checked);
        for (let u of userList) {
            if (!u.disabled) {
                haveActivated = true;
                if (keys.indexOf(u.userId) < 0) {
                    check = false;
                }
            }
        }
        return check && haveActivated;
    }

    const arrowBtnClick = btnId => e => {
        let keys = [...exportList.keys];
        let { values } = exportList;
        setTimerFlg(false);
        setShouldGetUserList(false);
        if (btnId === 'group') {
            const { group } = options;
            if (!group || !group.grId) {
                setSnack({ type: 'warning', msg: exptModalMsg.noGrSelected, open: true, action: undefined });
                return;
            }

            for (let [id, value] of Object.entries(exportList.values)) {
                if (value && value.type === 'group') {
                    if (value.path.indexOf(group.path) === 0) {
                        keys.splice(keys.indexOf(id), 1);
                        delete values[id];
                    } else if (group.path.indexOf(value.path) === 0) {
                        return;
                    }
                }
            }

            keys.push(group.grId);
            setExportList({
                keys: Array.from(new Set([...keys])),
                values: { ...values, [group.grId]: { value: group.grNm, type: btnId, path: group.path } }
            });

        } else {
            if (!checked || Object.keys(checked).length === 0) {
                setSnack({ type: 'warning', msg: exptModalMsg.noUserSelected, open: true, action: undefined });
                return;
            }

            let newItem = {};
            Object.keys(checked).forEach(c => {
                keys.push(c);
                newItem[c] = { value: checked[c], type: btnId };
            });

            setExportList({
                keys: Array.from(new Set([...keys])),
                values: { ...exportList.values, ...newItem }
            });
        }
    }

    const removeExportListItem = (id) => e => {
        let { keys, values } = exportList;
        keys.splice(keys.indexOf(id), 1);
        const { [id]: undefined, ...rest } = values;
        setExportList({ keys, values: rest });
    }

    const closeSnack = (e) => {
        setSnack({ ...snack, open: false });
    }

    const today = new Date();
    return (
        <React.Fragment>
            <Snackbar
                open={snack.open}
                onClose={closeSnack}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={snack.action ? undefined : 3000}
            >
                <Alert
                    onClose={closeSnack}
                    action={snack.action ? snack.action : undefined}
                    severity={snack.type ? snack.type : 'info'}
                >
                    {snack.msg}
                </Alert>
            </Snackbar>
            <DialogContent>
                <Grid container spacing={1} key="options">
                    <Grid item xs key="format">
                        <FormControl>
                            <FormLabel>근무율 서식</FormLabel>
                            <RadioGroup
                                defaultValue={selected.format}
                                onChange={e => setSelected({ ...selected, format: e.currentTarget.value })}
                            >
                                <FormControlLabel
                                    value='rate' key='rate'
                                    control={<Radio color='secondary' />}
                                    label={<React.Fragment>비율 <span className={classes.radioSpan}>예) 전사공통 0.95</span></React.Fragment>}
                                />
                                <FormControlLabel
                                    value='pct' key='pct'
                                    control={<Radio color='secondary' />}
                                    label={<React.Fragment>백분율 <span className={classes.radioSpan}>예) 전사공통 95%</span></React.Fragment>}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs key="yrMon">
                        <p className={`MuiFormLabel-root ${classes.pickerLabel}`}>연월</p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
                            <DatePicker
                                autoOk
                                variant="inline"
                                format="yyyy-MM"
                                openTo="month"
                                views={["year", "month"]}
                                value={options.yrMon}
                                minDate={subMonths(new Date(), 6)}
                                minDateMessage={"현재 일자보다 6개월 이전의 데이터는 제출할 수 없습니다."}
                                onChange={handleDateChange}
                                disableFuture={true}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Grid container spacing={2} key="exptList">
                    <Grid item xs={3} key="gr">
                        {groups ?
                            <GroupNav
                                option={{ minHeight: 400 }}
                                groups={groups ? groups.groups : undefined}
                                viewState={groups ? groups.viewState : undefined}
                                type='select'
                                onClickRow={handleGrSelect}
                            /> :
                            null
                        }
                    </Grid>
                    <Grid item xs={4} key="user">
                        <Paper style={{ minHeight: 400, maxHeight: 400, overflowY: 'auto' }}>
                            <List>
                                <ListItem key="searchField">
                                    <TextField
                                        id='search'
                                        value={options.search ? options.search : ''}
                                        variant='outlined'
                                        margin='dense'
                                        onChange={handleSearchChange}
                                        InputProps={{
                                            startAdornment:
                                                (<InputAdornment position='start'>
                                                    <SearchIcon />
                                                </InputAdornment>)
                                        }} />
                                </ListItem>
                                {!userList || !userList.length
                                    ? null
                                    : (
                                        <React.Fragment>
                                            <ListItem
                                                dense
                                                button
                                                key="checkall"
                                                onClick={handleClickList([null, null], 'all')}
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={getCheckAllState()}
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                    전체 선택
                                                </ListItem>
                                            {userList.map(u =>
                                                <ListItem
                                                    dense
                                                    button
                                                    key={u.userId}
                                                    disabled={u.disabled}
                                                    onClick={handleClickList([u.userId, u.userNm], 'single')}
                                                >
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={Object.keys(checked).includes(u.userId)}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            disabled={u.disabled}
                                                        />
                                                    </ListItemIcon>
                                                    {u.userNm}
                                                </ListItem>
                                            )}
                                        </React.Fragment>
                                    )
                                }
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} key="btn">
                        <div>
                            <Button
                                id='group'
                                size='small'
                                variant='contained'
                                color="default"
                                endIcon={<ChevronRightRoundedIcon className={classes.btnArrowIcon} />}
                                onClick={arrowBtnClick('group')}
                            >
                                그룹
                           </Button>
                            <Button
                                id='user'
                                className={classes.addBtn}
                                size='small'
                                variant='contained'
                                endIcon={<ChevronRightRoundedIcon className={classes.btnArrowIcon} />}
                                onClick={arrowBtnClick('user')}
                            >
                                사원
                           </Button>
                        </div>
                    </Grid>
                    <Grid item xs={4} key="selected">
                        <Paper style={{ minHeight: 400, maxHeight: 400, overflowY: 'auto' }}>
                            <List>
                                {!exportList
                                    ? null
                                    : exportList.keys.map(e =>
                                        <ListItem key={e}>
                                            {exportList.values[e].value}
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={removeExportListItem(e)}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>)
                                }
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.onCloseModal}
                >
                    Cancel
                    </Button>
                <Button
                    onClick={handleExportClick}
                >
                    Export
                    </Button>

            </DialogActions>
        </React.Fragment>
    )
}

export default ManualExport;