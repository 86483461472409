import React from 'react';
import Axios from 'axios';
import { withStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import {
  TableBody, TableHead, TableRow, TableCell, Paper,
  IconButton, Popover, Tooltip, Chip, Menu, TextField, InputAdornment, Grid
} from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { adminTimesheetMsg } from '../common/Messages';
import { adminUrl } from '../common/url';
import GroupNav from './GroupNav';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowLeftIcon from '@material-ui/icons/NavigateBefore';
import ArrowRightIcon from '@material-ui/icons/NavigateNext';
import CheckIcon from '@material-ui/icons/Check';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import SearchIcon from '@material-ui/icons/Search';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import { styles, groupTimesheetTableTheme } from '../styles/admin/groupTimesheetStyle';
import { checkErr } from '../checkErr';
import ExportModal from './ExportModal';
Axios.defaults.withCredentials = true;

class AdminTimeTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: new Date().getFullYear(),
      tableMode: 'user',
      showDisabled: true,
      openExportModal: false,
      grAnchorEl: null,
      openScheduleModal: false,
      viewId: this.props.viewId
    }
  }

  getMuiTheme = (theme) => createTheme(groupTimesheetTableTheme);

  componentDidMount() {
    this.handleGetGroup();
    this.handleInit();
  }

  timout = null;
  async handleSearch(e) {
    const search = e.currentTarget.value;
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => {
      this.setState({ search }, this.handleInit)
    }, 500);
  }

  async handleInit() {
    try {
      let { year, selectedGr, tableMode, sort, search, showDisabled, viewId } = this.state;
      let res = await Axios.post(adminUrl.initTimesheet, { year, selectedGr, tableMode, sort, search, showDisabled, viewId });
      const { tableData } = res.data;
      let renderTableMode = tableMode;
      this.setState({ tableData, renderTableMode });
    } catch (err) {
      checkErr(err, this.props.history);
      console.error(err);

    }
  }

  async handleGetGroup() {
    try {
      const { viewId } = this.state;
      let res = await Axios.post(adminUrl.getGroupsForNav, { disabled: true, viewId });
      let groups = res.data.groups;
      let { selectedGr } = res.data;
      //deep copying groups for export-modal
      const expGroups = JSON.parse(JSON.stringify(groups));
      this.setState({ groups, selectedGr, expGroups });
    } catch (err) {
      checkErr(err, this.props.history);
      console.log(err);
    }
  }

  //open GroupNav popover
  handleClickGroupSelect = event => {
    let grAnchorEl = this.state.grAnchorEl ? null : event.currentTarget;
    this.setState({ grAnchorEl });
  }

  //close GroupNav popover
  handleClosePopover = () => {
    this.setState({ grAnchorEl: null });
  }

  handleGroupRowSelect = (selectedGr) => {
    //console.log(selectedGr)
    this.setState({
      selectedGr,
      grAnchorEl: null,
    }, this.handleInit);
  }

  handleChangeYear = (move) => {
    let { year } = this.state;
    this.setState({ year: year + move }, this.handleInit);
  }

  handleSortClick = (panelNm) => {
    let { sort } = this.state;
    sort = !sort || !sort[panelNm]
      ? { [panelNm]: 'ASC' }
      : (sort[panelNm] === 'ASC' ? { [panelNm]: 'DESC' } : undefined);
    this.setState({ sort }, this.handleInit);
  }

  renderTable = () => {
    const { tableMode } = this.state;
    const headTitle = {
      user: { name: "성명", prjCd: "Project Code", sapCd: "SAP Project Code", prjNm: "Project 명칭", pct: "percentage" },
      project: { prjCd: "Project Code", sapCd: "SAP Project Code", prjNm: "Project 명칭", name: "성명", pct: "percentage" }
    }

    const months = [...Array(12)];
    const { classes } = this.props;
    let { tableData } = this.state;

    const renderTableHead = () => {
      let title = Object.keys(headTitle[tableMode]);
      const lastItem = title[title.length - 1];
      const { sort } = this.state;
      return (
        <TableHead>
          <TableRow key="head1">
            {title.map(h =>
              <TableCell
                rowSpan={h !== lastItem ? '2' : null}
                colSpan={h !== lastItem ? null : '12'}
                className={h !== lastItem ? classes.borderTop : `${classes.noBorderBottom} ${classes.borderTop}`}
                key={h}
              >
                {/* sort button*/}
                {h !== 'pct'
                  ? (
                    <div className={classes.sortPanel} onClick={this.handleSortClick.bind(this, h)}>
                      <span className='undraggable'>
                        {headTitle[tableMode][h]}
                      </span>
                      {!sort || !sort[h]
                        ? undefined
                        : <ExpandMoreIcon className={sort[h] === 'ASC' ? `${classes.sort} ${classes.sortDesc}` : classes.sort} />
                      }
                    </div>
                  )
                  : headTitle[tableMode][h]}
              </TableCell>
            )}
          </TableRow>
          <TableRow key="head2">
            {months.map((m, i) =>
              <TableCell key={i + 1} className={classes.tHead2ndRow}>
                {/* sort button for each month's percentage value*/}
                <div className={classes.sortPanel} onClick={this.handleSortClick.bind(this, i + 1)}>
                  <span className='undraggable'>
                    {`${i + 1}월`}
                  </span>
                  {!sort || !sort[i + 1]
                    ? undefined
                    : <ExpandMoreIcon className={sort[i + 1] === 'ASC' ? `${classes.sort} ${classes.sortDesc}` : classes.sort} />
                  }
                </div>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
      )
    }

    const renderTableBody = () => {
      const noRecord = [
        <TableRow key="noRecord">
          <TableCell colSpan="16" className={classes.noRecords}>
            {adminTimesheetMsg.noRecords}
          </TableCell>
        </TableRow>
      ];
      if (tableData.length === 0 || this.state.renderTableMode !== tableMode) {
        return noRecord;
      }

      const makeBody = {
        user: (tbody, row, first, size) => {
          tbody.push(
            <TableRow
              key={row.userId}
              className={tbody.length === 0 ? classes.noBorderBottom : `${classes.noBorderBottom} ${classes.underTotal}`}
            >
              <TableCell rowSpan={row.data.length + 1} key={row.userId} className={classes.lastLine}>{row.userNm}</TableCell>
              <TableCell key={first.prjCd}>{first.prjCd}</TableCell>
              <TableCell key={first.sapCd + 'sapCd'}>{first.sapCd}</TableCell>
              <TableCell key={first.prjNm + 'prjNm'}>{first.prjNm}</TableCell>
              {months.map((m, index) =>
                <TableCell key={index + 1}>{first.pct[index + 1] ? first.pct[index + 1] + '%' : "-"}</TableCell>)}
            </TableRow>
          );

          for (let i = 1; i < row.data.length; i++) {
            let d = row.data[i];
            tbody.push(
              <TableRow className={classes.noBorderBottom} key={`${row.userId} ${d.prjCd}`}>
                <TableCell key={d.prjCd}>{d.prjCd}</TableCell>
                <TableCell key={d.sapCd + 'sapCd'}>{d.sapCd}</TableCell>
                <TableCell key={d.prjNm + 'prjNm'}>{d.prjNm}</TableCell>
                {months.map((m, index) =>
                  <TableCell key={index + 1}>{d.pct[index + 1] ? d.pct[index + 1] + '%' : "-"}</TableCell>)}
              </TableRow>
            );
          }

          //ROW FOR TOTAL OF EACH MONTH AND EACH PERSON
          tbody.push(
            <TableRow key={`${row.userId} total`}>
              <TableCell colSpan="3" align="center" key="total" className={classes.lastLine}>계</TableCell>
              {months.map((m, index) =>
                <TableCell key={index + 1} className={classes.lastLine}>
                  {row.total[index + 1] ? row.total[index + 1] + '%' : "-"}
                </TableCell>)}
            </TableRow>
          )
        },

        project: (tbody, row, first, size) => {
          tbody.push(
            <TableRow key={row.prjCd} className={classes.borderTop} >
              <TableCell
                rowSpan={size}
                key={row.prjCd}
              >
                {row.prjCd}
              </TableCell>
              <TableCell
                rowSpan={size}
                key={row.sapCd + 'sapCd'}
              >
                {row.sapCd}
              </TableCell>
              <TableCell
                rowSpan={size}
                key={row.prjNm + 'prjNm'}
              >
                {row.prjNm}
              </TableCell>
              <TableCell
                key={first.userNm}
              >
                {first.userNm}
              </TableCell>
              {months.map((m, index) =>
                <TableCell key={index + 1}>
                  {first.pct[index + 1] ? first.pct[index + 1] + '%' : "-"}
                </TableCell>
              )}
            </TableRow>
          )
          for (let i = 1; i < row.data.length; i++) {
            let d = row.data[i];
            tbody.push(
              <TableRow className={classes.noBorderBottom}>
                <TableCell key={d.userNm}>{d.userNm}</TableCell>
                {months.map((m, index) =>
                  <TableCell key={index + 1}>{d.pct[index + 1] ? d.pct[index + 1] + '%' : "-"}</TableCell>)}
              </TableRow>
            );
          }
        }
      }

      let tbody = [];
      for (let row of tableData) {
        let size = row.data.length;
        let first = !row.data || size === 0 ? undefined : row.data[0];
        if (!first) {
          return noRecord;
        }
        makeBody[tableMode](tbody, row, first, size);
      }
      return tbody;
    }

    return (
      <Table size="small" className={classes.table}>
        {renderTableHead()}
        <TableBody >
          {renderTableBody()}
        </TableBody>
      </Table>
    )
  }

  handleClickChip = (tableMode) => {
    this.setState({
      tableMode,
      sort: undefined,
      search: undefined,
    }, this.handleInit);
    document.getElementById('searchbar').value = '';
  }

  //open modal
  handleClickSubmit = () => {
    this.setState({
      openExportModal: true,
    })
  }

  //Export Modal
  handleExpGrSelect = (group) => {
    this.setState({
      expSelected: {
        ...this.state.expSelected,
        group
      }
    })
  }

  handleClickVisibility = () => {
    let { showDisabled } = this.state;
    this.setState({
      showDisabled: !showDisabled
    }, this.handleInit);
  }

  render() {
    const grOpen = Boolean(this.state.grAnchorEl);
    const id = grOpen ? 'popover' : undefined;
    const { classes } = this.props;
    const { tableMode } = this.state;
    const tmId = { user: '성명', project: '프로젝트' };
    return (
      <React.Fragment>
        <Grid container direction='row' alignItems='center' className={classes.btnArea} key="btnArea">
          <Grid item xs={9} key="btn-left" className={classes.alignCenterDiv}>
            <div style={{ float: 'left' }} key="year" >
              <IconButton
                onClick={this.handleChangeYear.bind(this, -1)}
              >
                <ArrowLeftIcon />
              </IconButton>
              <label>{this.state.year}</label>
              <IconButton
                onClick={this.handleChangeYear.bind(this, +1)}
              >
                <ArrowRightIcon />
              </IconButton>
            </div>
            <div
              key="groupselect"
              onClick={this.handleClickGroupSelect.bind(this)}
              className={classes.groupSelect}
              style={{ float: 'left' }}
            >
              <span className={classes.groupSelectSpan}>
                {this.state.selectedGr ? this.state.selectedGr.grNm : null}
              </span>
              <ArrowDropDownIcon color="action" className={classes.groupSelectDropDownIcon} />
            </div>
            <Popover
              id={id}
              open={grOpen}
              anchorEl={this.state.grAnchorEl}
              onClose={this.handleClosePopover.bind(this)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              style={{ zIndex: 9999 }}
            >
              <GroupNav
                groups={this.state.groups ? this.state.groups.groups : undefined}
                viewState={this.state.groups ? this.state.groups.viewState : undefined}
                type="select"
                onClickRow={this.handleGroupRowSelect.bind(this)}
              />
            </Popover>
            <TextField
              id='searchbar'
              key='search'
              variant='outlined'
              margin='dense'
              onChange={this.handleSearch.bind(this)}
              placeholder='search...'
              InputProps={{
                startAdornment:
                  (<InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>)
              }}
            />
          </Grid>
          <Grid item xs={3} key="btn-right">
            <Grid container direction='row' alignItems='center'>
              <Grid item xs={9} key="tableMode" className={classes.justifyFlexEnd}>
                <Chip key="labelChip" className={classes.chipGr} label="정렬" />
                {Object.keys(tmId).map(t =>
                  <Chip
                    key={t}
                    icon={tableMode !== t ? undefined : <CheckIcon fontSize="small" />}
                    className={classes.chipGr}
                    variant={tableMode !== t ? undefined : 'outlined'}
                    label={tmId[t]}
                    onClick={this.handleClickChip.bind(this, t)}
                  />
                )}
              </Grid>
              <Grid item xs={3} className={classes.justifyFlexEnd}>
                <Tooltip title="Send Data to ERP" key="exportToERP">
                  <IconButton
                    onClick={this.handleClickSubmit.bind(this)}
                  >
                    <SendRoundedIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={`${this.state.showDisabled ? 'Hide' : 'Show'} disabled user`}
                  key="disabledUser"
                >
                  <IconButton onClick={this.handleClickVisibility.bind(this)}>
                    {this.state.showDisabled ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* end of top-button area */}
        <div key="centerTable" className={classes.centerDivOuter}>
          <Paper key="tableContainer" className={classes.tableContainer}>
            {!this.state.tableData ? null : (
              <MuiThemeProvider theme={this.getMuiTheme()}>
                {this.renderTable()}
              </MuiThemeProvider>
            )}
          </Paper>
        </div>
        {this.state.openExportModal
          && <ExportModal
            history={this.props.history}
            groups={this.state.expGroups}
            openModal={this.state.openExportModal}
            onCloseModal={e => this.setState({ openExportModal: false })}
          />}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AdminTimeTable);