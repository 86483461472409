import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withRouter } from 'react-router';
import { Button, Grid, TextField } from '@material-ui/core';
import { loginUrl } from './url';
import { loginMsg, role_label, UpdateUserProfileMessage } from './Messages';
import { Fragment } from 'react';
import { checkErr } from '../checkErr';

Axios.defaults.withCredentials = true;

const UserProfile = props => {
    const [disableSave, setDisableSave] = useState(true);
    const [userProfileInfo, setUserProfileInfo] = useState(null);
    const [editedInfo, setEditedInfo] = useState({});
    const [fieldError, setFieldError] = useState({});
    const [initDisplay, setInitDisplay] = useState(false);
    const emailRegex = /[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,}$/;
    useEffect(() => {
        if (!userProfileInfo || initDisplay) {
            const getUserInfoFromSession = async () => {
                try {
                    const res = await Axios.post(loginUrl.getUserProfileInfo)
                    setUserProfileInfo(res.data);
                } catch (err) {
                    checkErr(err, props.history)
                    console.error(err);
                }
            }

            getUserInfoFromSession();
            setInitDisplay(false);
        }
    }, [userProfileInfo, initDisplay, props.history])

    // Prevent browser's password autocomplete function
    const handleRemoveReadOnly = e => {
        e.target.removeAttribute('readOnly')
    }

    const handleChangeInput = e => {
        const { id, defaultValue, value } = e.currentTarget;
        if (defaultValue === value) {
            setEditedInfo({ ...editedInfo, [id]: undefined });
        } else {
            setEditedInfo({ ...editedInfo, [id]: value });
        }

        const changedItemSize = Object.keys(editedInfo).filter(info => editedInfo[info] !== undefined).length;
        setDisableSave(!Boolean(changedItemSize))
    }

    //validate input
    const handleValidation = {
        password: e => {
            const { id, value } = e.currentTarget;
            if (id === 'npw' && value.length < 5) {
                fieldError[id] = loginMsg.length;
            } else if (id === 'cpw' && editedInfo.npw !== editedInfo.cpw) {
                fieldError[id] = loginMsg.unmatched;
            } else {
                delete fieldError[id];
            }
            setFieldError({ ...fieldError })
        },
        email: e => {
            const { id, value } = e.currentTarget;
            if (!value) {
                fieldError[id] = loginMsg.required;
            } else if (!emailRegex.test(value)) {
                fieldError[id] = loginMsg.invalidEmail;
            }
            // else if (value.split('@').pop() !== 'tescom.org' && value.split('@').pop() !== 'tescom.co.kr') {
            //     fieldError[id] = loginMsg.noCompanyEmail; 
            // } 
            else {
                delete fieldError[id];
            }
            setFieldError({ ...fieldError });
        }
    }

    //save updated user profile information
    const editUserProfile = async () => {
        try {
            const res = await Axios.post(loginUrl.editUserProfileFromHeader, { editedInfo, userProfileInfo });
            const { fieldError, result, message } = res.data;
            if (fieldError) {
                const fieldErrorMsg = {};
                Object.entries(fieldError).forEach(([field, error]) => {
                    fieldErrorMsg[field] = !error || !error.length
                        ? undefined
                        : (<Fragment>
                            {error.map(e => <p>{loginMsg[e]}</p>)}
                        </Fragment>)
                });
                setFieldError(fieldErrorMsg);
                return;
            }

            if (result === 'success' || result === 'warning') {
                document.querySelector('#npw').value = '';
                document.querySelector('#cpw').value = '';
                setInitDisplay(true);
                props.closeProfile()
            }

            props.setSnack({ open: true, message: UpdateUserProfileMessage[message], type: result })
        } catch (err) {
            console.error(err)
            if (checkErr(err, props.history)) return;
            props.setSnack({ open: true, message: UpdateUserProfileMessage.FailedUpdateUser, type: 'error' })
        }
    }

    //TODO: on Enter press > save
    // const keyPressInput = e => {

    // }


    return (!userProfileInfo ? null :
        <React.Fragment >
            <Dialog open={props.openProfile} maxWidth="sm" onClose={props.closeProfile} >
                <DialogTitle>나의 기본 정보</DialogTitle>
                <DialogContent style={{ display: 'table', padding: '8px 50px' }} >
                    <Grid container spacing={3} alignContent="center" justify="center">
                        <Grid item xs={12} key="user-name-container">
                            <TextField
                                onKeyDown={event => event.stopPropagation()}
                                key="user-name-input"
                                label="이름"
                                color="primary"
                                inputProps={{ disabled: true }}
                                defaultValue={userProfileInfo.userNm}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} key="pwd-container">
                            <TextField
                                key="password-input"
                                label="비밀번호"
                                id="npw"
                                variant="filled"
                                type="password"
                                color="primary"
                                onChange={handleChangeInput}
                                onBlur={handleValidation.password}
                                error={Boolean(fieldError?.npw)}
                                helperText={fieldError?.npw ?? null}
                                inputProps={{
                                    readOnly: true
                                }}
                                onFocus={handleRemoveReadOnly}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} key="pwd-confirm-container">
                            <TextField
                                key="password-confirm-input"
                                label="비밀번호 확인"
                                id="cpw"
                                variant="filled"
                                type="password"
                                color="primary"
                                onChange={handleChangeInput}
                                onBlur={handleValidation.password}
                                error={Boolean(fieldError?.cpw)}
                                helperText={fieldError?.cpw ?? null}
                                inputProps={{
                                    readOnly: true
                                }}
                                onFocus={handleRemoveReadOnly}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} key="email-container">
                            <TextField
                                required
                                key="email-input"
                                label="Email"
                                id="email"
                                variant="filled"
                                type="email"
                                color="primary"
                                defaultValue={userProfileInfo.email}
                                onChange={handleChangeInput}
                                onBlur={handleValidation.email}
                                error={Boolean(fieldError?.email)}
                                helperText={fieldError?.email ?? null}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} key="group-container">
                            <TextField
                                key="group-name-input"
                                label="소속"
                                color="primary"
                                defaultValue={userProfileInfo.grNm}
                                inputProps={{ disabled: true }}
                                fullWidth
                            />
                        </Grid>
                        {userProfileInfo.userRole && Object.keys(userProfileInfo.userRole) ?
                            <Grid item xs={12} key={`user-role-container}`}>
                                <TextField
                                    key={`user-role-input`}
                                    label={`사용자 권한`}
                                    color="primary"
                                    defaultValue={Object.keys(userProfileInfo.userRole).map((role, index) =>
                                        Object.keys(userProfileInfo.userRole).length - 1 === index ?
                                            role_label[role] : role_label[role] + ', '
                                    )}
                                    inputProps={{ disabled: true }}
                                    fullWidth
                                />
                            </Grid>
                            : null}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={disableSave ? "text" : "contained"}
                        color="primary"
                        disabled={disableSave}
                        onClick={editUserProfile}
                    >
                        save
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={props.closeProfile}
                    >
                        cancel
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment >
    )
}

export default withRouter(UserProfile);