import React, { useEffect } from 'react';
import { checkErr } from '../checkErr';
import Button from '@material-ui/core/Button';
import { Paper, Grid, Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Axios from 'axios';
import MaterialTable, { MTableBodyRow } from '@material-table/core';
import { useStyles } from '../styles/user/userBtnBottomStyle';
import { withRouter } from 'react-router-dom';
import { userUrl } from '../common/url';
import { userSubmitButtonTooltipMsg } from '../common/Messages';
Axios.defaults.withCredentials = true;
const tableState = {
  options: {
    pageSize : 15,
    pageSizeOptions: [15, 20, 30],
    paging: true,
    selection: true,
    filtering: true,
    minBodyHeight: 'calc(100vh - 15em)',
    maxBodyHeight: 'calc(100vh - 15em)',
    rowStyle: (data, index) => {
      if (index % 2) {
        return { backgroundColor: "white" }
      }
    },
  },
  choiceColumns: (grLookup) => ([
    {
      field: 'prjCd', title: '프로젝트 Code',
      headerStyle: { width: 100, maxWidth: 100 }, cellStyle: { width: 110, maxWidth: 110 }
    },
    {
      field: 'sapCd', title: 'SAP  프로젝트',
      headerStyle: { width: 130, maxWidth: 130 }, cellStyle: { width: 110, maxWidth: 110 }
    },
    {
      field: 'prjNm', title: '프로젝트 명칭',
      headerStyle: { width: 260, maxWidth: 260 }, cellStyle: { width: 260, maxWidth: 260 }
    },

    {
      field: 'grId', title: '그룹', lookup: grLookup || {},
      headerStyle: { maxWidth: 120, width: 120 }, cellStyle: { maxWidth: 150, width: 150 }
    },
  ]),
  chosenColumns: (grLookup) =>  ([
    {
      field: 'prjCd', title: '프로젝트 Code',
      headerStyle: { width: 100, maxWidth: 100 }, cellStyle: { width: 110, maxWidth: 110 }
    },
    {
      field: 'sapCd', title: 'SAP  프로젝트',
      headerStyle: { width: 130, maxWidth: 130 }, cellStyle: { width: 110, maxWidth: 110 }
    },
    {
      field: 'prjNm', title: '프로젝트 명칭',
      headerStyle: { width: 260, maxWidth: 260 }, cellStyle: { width: 260, maxWidth: 260 }
    },

    {
      field: 'grId', title: '그룹', lookup: grLookup || {},
      headerStyle: { maxWidth: 120, width: 120 }, cellStyle: { maxWidth: 150, width: 150 }
    },
  ])

}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserBtnBottom = (props) => {
  const classes = useStyles();
  const [choicePrj, setChoicePrj] = React.useState(undefined);
  const [chosenPrj, setChosenPrj] = React.useState(undefined);
  const [grLookup, setGrLookup] = React.useState(undefined);
  const [edited, setEdited] = React.useState(false);
  const [openTooltip, setOpenTooltip] = React.useState(true);
  const toShowTableRef = React.useRef();
  const toHideTableRef = React.useRef();

  useEffect(() => {
    if (!grLookup) {
      const url = userUrl.getProjectList;
      Axios.post(url).then(res => {
        const {choice, chosen, grInfo } = res.data;
        setChoicePrj(choice);
        setChosenPrj(chosen);
        setGrLookup(grInfo)
      }).catch(err => {
        console.error(err);
        checkErr(err, props.history);
      });
    }

    const ids = ['choice', 'chosen'];
    for (let id of ids) {
      const btn = document.querySelector(`button#${id}Btn`);
      disableBtn(btn);
    }

    if (!props.hideSubmitBtn) {
      setOpenTooltip(true);
    }

  }, [props.history, grLookup, props.hideSubmitBtn])


  // 모두 표시 / 모두 감추기가 아닐 경우 tableRef의 tableData 값을 보내서 
  // check된 데이터만 서버에서 골라내 수정을 진행
  // check 할 때마다 check된 데이터를 state로 설정하는 경우 scroll 값이 초기화 되기 때문
  const handleClickEditPrjPref = async (direction, type, prjToEdit) => {
    try {
      let res = await Axios.post(userUrl.editPrjPref, { direction, type, prjToEdit });
      let { choice, chosen, error }= res.data;
      if (!error) {
        setEdited(true);
        setChoicePrj(choice);
        setChosenPrj(chosen);
      }
      
    } catch (err) {
      console.error(err);
      checkErr(err, props.history);
    }
  }

  const handleSelectChange = (rows) => {
    checkButtonEnable();

  }

  /** 
   *  check 할 때마다 check된 데이터를 state로 설정하는 경우
   *  scroll 값이 초기화 되기 때문에 편집 버튼 활성 비활성 값 판별 시 
   *  element 값으로 check상태 취득하여 실행
   */
    const checkButtonEnable = () => {
    const ids = ['choice', 'chosen'];
    for (let id of ids) {
      const checkList = document.querySelectorAll(`tr.${id}Table>td.MuiTableCell-paddingNone>span>span>input:checked`);
      const btn = document.querySelector(`button#${id}Btn`);
      if (!btn) {
        return;
      } else if (!checkList || !checkList.length) {
        disableBtn(btn);
      } else {
        btn.removeAttribute('disabled');
        btn.classList.remove('Mui-disabled');
        btn.setAttribute('tabindex', 0);
      }
    }
  }

  const disableBtn = (btn) => {
    if (!btn) {
      return;
    }
    btn.setAttribute('disabled', true);
    btn.classList.add('Mui-disabled', 'Mui-disabled');
    btn.setAttribute('tabindex', -1);
  }

  const handleCloseDialog = () => {
    props.onCloseProjPref(edited);
    setEdited(false);
  }

  return (
    <React.Fragment>
      <Paper className={classes.buttonBtm} key="BtnContainer">
        {props.isFromAdmin ?
          null :
          <div key="btnLeft" style={{ float: 'left' }}>
            <Button
              variant="contained"
              className={classes.button}
              size="large"
              onClick={() => props.onClickProjPref()}
            >
              +/- project
            </Button>
          </div>
        }
        <div key='btnRight' style={{ float: 'right', }}>
          {props.isFromAdmin ?
            null :
            <Button
              key="save"
              variant="contained"
              className={classes.button}
              size="large"
              onClick={props.onClickSave}
              disabled={props.btnDisable}
            >
              save
            </Button>
          }
          {props.hideSubmitBtn ?
            null :
            <Tooltip
              arrow={true}
              open={openTooltip && !props.displayProjPref}
              leaveDelay={500} //open for half second
              onOpen={() => setOpenTooltip(true)}
              onClose={() => setOpenTooltip(false)}
              title={
                <label className={classes.submitTooltipLabel}>
                  {userSubmitButtonTooltipMsg(props.firstDayOfWeek)}
                </label>
              }
            >
              <Button
                key="submit"
                variant="contained"
                className={classes.button}
                size="large"
                onClick={props.onClickSubmit}
                disabled={props.btnDisable}
              >
                submit
              </Button>
            </Tooltip>
          }
        </div>
      </Paper>
      {props.isFromAdmin ?
        null :
        <div key="dialogContainer">
          <Dialog fullScreen open={props.displayProjPref} onClose={handleCloseDialog} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="Close">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h5" className={classes.title}>
                  Show / Hide Project
                </Typography>
              </Toolbar>
            </AppBar>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              className={classes.dialogContentGrid}
            >
              <Grid item xs={5} key="choice">
                <MaterialTable
                  tableRef={toShowTableRef}
                  className="leftTable"
                  id="leftTable"
                  columns={tableState.choiceColumns(grLookup)}
                  options={JSON.parse(JSON.stringify(tableState.options))}
                  data={choicePrj || []}
                  onSelectionChange={(rows) => handleSelectChange(rows, "toShow")}
                  components={{
                    Container: props => <Paper {...props} className={classes.tableContainer} elevation={0} />,
                    Row: props => <MTableBodyRow {...props} className='choiceTable' />
                  }}
                  title='hide'
                />
              </Grid>
              <Grid item xs={1} key="trnsfrBtn" id="trnsfrBtn">
                <div className={classes.btnContainer}>
                  <Tooltip
                    key="showAll"
                    title="모든 프로젝트 표시"
                    disableFocusListener={true}
                    disableTouchListener={true}
                  >
                    <Button
                      className={`${classes.modalBtn} ${classes.button}`}
                      variant="contained"
                      disabled={!(choicePrj?.length)}
                      onClick={() => handleClickEditPrjPref('toShow', 'all', choicePrj)}
                    >
                      ≫
                    </Button>
                  </Tooltip>
                  <Tooltip
                    key="show"
                    disableFocusListener={true}
                    disableTouchListener={true}
                    title="선택 프로젝트 표시"
                  >
                    <Button
                      ref={ref => checkButtonEnable()}
                      id="choiceBtn"
                      className={`${classes.modalBtn} ${classes.button}`}
                      variant="contained"
                      onClick={() => handleClickEditPrjPref('toShow', 'selected', toShowTableRef?.current?.state?.data || [])}
                    >
                      &gt;
                    </Button>
                  </Tooltip>
                  <Tooltip
                    key="hide"
                    disableFocusListener={true}
                    disableTouchListener={true}
                    title="선택 프로젝트 비표시"
                  >
                    <Button
                      id="chosenBtn"
                      className={`${classes.modalBtn} ${classes.button}`}
                      variant="contained"
                      onClick={() => handleClickEditPrjPref('toHide', 'selected', toHideTableRef?.current?.state?.data || [])}
                    >
                      &lt;
                    </Button>
                  </Tooltip>
                  <Tooltip
                    key="hideAll"
                    disableFocusListener={true}
                    disableTouchListener={true}
                    title="모든 프로젝트 비표시"
                  >
                    <Button
                      className={`${classes.modalBtn} ${classes.button}`}
                      variant="contained"
                      disabled={!(chosenPrj?.length)}
                      onClick={() => handleClickEditPrjPref('toHide', 'all', chosenPrj)}
                    >
                      ≪
                    </Button>
                  </Tooltip>
                </div>
              </Grid>
              <Grid item xs={5} key="chosen">
                <MaterialTable
                  tableRef={toHideTableRef}
                  columns={tableState.chosenColumns(grLookup)}
                  options={JSON.parse(JSON.stringify(tableState.options))}
                  data={chosenPrj || []}
                  title='show'
                  onSelectionChange={(rows) => handleSelectChange(rows, "toHide")}
                  components={{
                    Container: props => <Paper {...props} className={classes.tableContainer} elevation={0} />,
                    Row: props => <MTableBodyRow {...props} className='chosenTable' />
                  }}
                />
              </Grid>
            </Grid>
          </Dialog>
        </div>
      }
    </React.Fragment>
  )
}

export default withRouter(UserBtnBottom);