import React from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import koLocale from 'date-fns/locale/ko';
import {
    Divider, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemIcon,
    ListItemText, MenuItem, Popover, Select, Tooltip, Typography, Zoom
} from '@material-ui/core';
import App from '../App'
import Axios from 'axios';
import format from 'date-fns/format';
import GroupNav from './GroupNav';
import { adminUrl } from '../common/url';
import { checkErr } from '../checkErr';
import ArrowLeftIcon from '@material-ui/icons/NavigateBefore';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import GroupIcon from '@material-ui/icons/Group';
import ArrowRightIcon from '@material-ui/icons/NavigateNext';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
Axios.defaults.withCredentials = true;

//TODO: separate stylesheet
function SubmitStatus(props) {
    const date = new Date();
    const year = date.getFullYear();
    const [values, setValues] = React.useState({
        year: year,
        month: date.getMonth() + 1,
        yrMon: new Date(),
        //check 값(제출 상태) -> 'all': 전체, 'checked': 제출 완료, 'unchecked':제출 미완료, 'exported':SAP 송신
        check: 'all',
        hideGr: true,
    });

    const [tableData, setTableData] = React.useState();
    const [userList, setUserList] = React.useState([]);
    const [groups, setGroups] = React.useState(undefined);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [checkedGr, setCheckedGr] = React.useState(null);
    const [changeTableFlg, setChangeTableFlg] = React.useState(false);
    const [showResigned, setShowResigned] = React.useState(false); // 퇴직자는 기본적으로 표시하지 않는다
    //상태 표시 라벨 및 아이콘
    const submitStatus = {
        all: { label: '전체', icon: null },
        unchecked: {
            label: '미완료',
            icon: <CancelIcon color='error' />
        },
        checked: {
            label: '완료',
            icon: <CheckCircleIcon style={{ color: '#90bdbf' }} />
        },
        exported: {
            label: '송신',
            icon: <AssignmentTurnedInIcon style={{ color: '#437490' }} />
        }

        
    }

    //Group 정보 불러오기
    async function handleGetGroup() {
        try {
            let res = await Axios.post(adminUrl.getGroupsForNav,
                { disabled: true, viewId: props.viewId });
            let { groups, viewState } = res.data.groups;
            setGroups({ groups, viewState });
        } catch (err) {
            checkErr(err, props.history);
            console.log(err);
        }
    }

    //Group 리스트 열기
    const handleClickOpenGrBtn = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    //Group list 닫기
    const handleCloseGrPopover = () => {
        setAnchorEl(null);
    }

    //Group 선택 시
    const handleGroupNavCheck = (checkedList) => {
        setCheckedGr(checkedList);
    }

    //when status filtering dropdown is changed 
    const handleChange = (e) => {
        setValues(oldValues => ({
            ...oldValues,
            [e.target.name]: e.target.value,
        }));
        if (e.target.name !== 'check') {
            setChangeTableFlg(true);
        }
    }

    const handleDateBtn = incr => {
        let { yrMon } = values;
        let year = yrMon.getFullYear();
        let month = yrMon.getMonth() + incr;

        if (month < 0) {
            month = 11;
            year += incr;
        } else if (month > 11) {
            month = 0;
            year += incr;
        }

        setValues({
            ...values,
            yrMon: new Date(year, month)
        });
    }

    //퇴사자 보기 / 감추기 버튼 클릭 시 토글
    const handleToggleShowResigned = () => {
        //현재 showResigned 반대로 set
        const show = !showResigned;
        setShowResigned(show);

        //checkedGr이 존재하지 않는 경우 : 모든 그룹 표시
        // show == true인 경우에는 퇴사자 그룹 포함 show == false인 경우에는 퇴사자 그룹 비포함 전체
        if (!checkedGr || !checkedGr.length) {
            return;
        }

        //show == true인 경우에는 현재 선택 그룹(checkedGr)에 퇴사자 그룹(hidden그룹) check 입력
        //show == false인 경우에는 현재 선택 그룹에서 퇴사자 그룹 삭제
        let grList = [];
        grList = [...checkedGr];
        for (let grId of Object.keys(groups.viewState)) {
            if (!groups.viewState[grId].hidden) {
                continue;
            }

            if (show && !grList.includes(grId)) {
                grList.push(grId);
                groups.viewState[grId].check = true;
            }

            if (!show && grList.includes(grId)) {
                grList.filter(item => item !== grId);
                groups.viewState[grId].check = false;
            }
        }
        setCheckedGr(grList)
    }

    //User Timesheet Table에 Table 변경 flag 전송
    const getChangeTableFlg = (flg) => {
        setChangeTableFlg(flg);
    }

    //User 클릭 시 User Timesheet Table 표시
    const handleClickUser = (e, userId, userNm, groupName, isDisabledUser) => {
        e.preventDefault();
        const tableData = {
            userId,
            userNm,
            userDate: format(new Date(values.yrMon), 'yyyy/MM/dd'),
            userGroup: groupName,
            isDisabledUser
        };
        setTableData(tableData);
        setChangeTableFlg(true);
    }

    React.useEffect(() => {
        const param = {
            yrMon: format(values.yrMon, 'yyyyMM'),
            check: values.check,
            checkedGr,
            viewId: props.viewId,
            showResigned,
        }

        //사용자 목록 가져오기
        Axios.post(adminUrl.getUsersAndMonthlyChecks, param)
            .then(res => {
                if (!res.err) {
                    setUserList(res.data.userList);
                } else {
                    console.log(res.err);
                }
            })
            .catch(err => {
                checkErr(err, props.history);
                console.log(err);
            });

        if (!groups) {
            handleGetGroup();
        }

    }, [values, groups, checkedGr, showResigned]);

    //Group List 상태
    const open = Boolean(anchorEl);
    const id = open ? 'popover' : undefined;

    return (
        <Grid container>
            <Grid item style={{ flexGrow: 0, maxWidth: '20%', flexBasis: '20%' }}  >
                <List className="userList" style={{ height: '85vh', overflow: 'auto' }} dense>
                    {/* controller panel */}
                    <ListItem style={{ padding: 8 }}>
                        <IconButton
                            onClick={e => handleDateBtn(-1)}
                            style={{ padding: 2 }}
                        >
                            <ArrowLeftIcon />
                        </IconButton>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
                            <DatePicker
                                name="yrMon"
                                style={{ width: 110, fontSize: '0.8em' }}
                                autoOk
                                variant="inline"
                                format="yyyy년 MM월"
                                openTo="month"
                                views={["year", "month"]}
                                value={values.yrMon}
                                onChange={date => setValues({ ...values, yrMon: date })}
                            />
                        </MuiPickersUtilsProvider>
                        <IconButton onClick={e => handleDateBtn(1)} style={{ padding: 2 }}>
                            <ArrowRightIcon />
                        </IconButton>
                        <FormControl key="status">
                            <InputLabel htmlFor="check-simple">status</InputLabel>
                            <Select
                                value={values.check}
                                onChange={handleChange}
                                name="check"
                                inputProps={{
                                    name: 'check',
                                    id: 'check-simple',
                                }}

                                style={{ margin: '7px', textIndent: '7px', padding: '2px', minWidth: '60px', fontSize: '0.8rem' }}
                            >
                                {Object.entries(submitStatus).map(([key, value]) =>
                                    <MenuItem key={key} value={key}> {value.label} </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <div key="groupSelect">
                            <Tooltip title="그룹 선택" arrow TransitionComponent={Zoom}>
                                <IconButton
                                    aria-describedby={id}
                                    onClick={handleClickOpenGrBtn}
                                >
                                    <GroupIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleCloseGrPopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            style={{ zIndex: 9999 }}
                        >
                            <GroupNav
                                groups={groups ? groups.groups : undefined}
                                viewState={groups ? groups.viewState : undefined}
                                type="check"
                                showHidden={showResigned}
                                onClickCheck={handleGroupNavCheck}
                            />
                        </Popover>
                        <Tooltip title={showResigned ? "퇴직자 숨기기" : "퇴직자 확인"} arrow TransitionComponent={Zoom}>
                            <IconButton onClick={handleToggleShowResigned}>
                                {showResigned ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </Tooltip>
                    </ListItem>
                    <Divider />

                    {/* USER LIST */}
                    {userList.map(u => (
                        <React.Fragment key={u.userId}>
                            <ListItem
                                button
                                onClick={(e) => handleClickUser(e, u.userId, u.userNm, u.group_name, u.disabled)}
                                selected={tableData ? tableData.userId === u.userId : false}
                            >
                                <ListItemText className="userList" primary={u.userNm} secondary={u.group_name} />
                                <ListItemIcon>
                                    {submitStatus[u.status] ? submitStatus[u.status].icon : null}
                                </ListItemIcon>
                            </ListItem>
                        </React.Fragment>)
                    )}
                </List>
            </Grid>
            <Grid item style={{ flexGrow: 0, maxWidth: '80%', flexBasis: '80%' }}>
                {!tableData
                    ? <Typography
                        variant="h6"
                        style={{ position: 'absolute', top: '50%', left: '50%' }}
                    >
                        Select a user to display timesheet data
                    </Typography>
                    :
                    <div style={{ transform: 'scale(0.98)' }}>
                        <App
                            userInfo={tableData}
                            isFromAdmin={true}
                            changeTableFlg={changeTableFlg}
                            setChangeUserDataFlg={getChangeTableFlg}
                            submittable={tableData.submittable}
                        />
                    </div>
                }
            </Grid>
        </Grid>
    );
}


export default React.memo(SubmitStatus);