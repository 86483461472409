import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    button: {
      //margin: theme.spacing(1),
      fontWeight: 'bold',
      backgroundColor: 'orange',
      color: 'white',
      '&:hover' : {
        backgroundColor: 'darkorange'
      },
      '&:not(:last-child)' : {
        marginRight : '0.5em',
      },
    },
    buttonBtm: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      clear: 'both',
      justify: 'center',
      alignItems: 'center',

    },
    appBar: {
      position: 'relative',
      backgroundColor:'cadetblue',
    },
    tableContainer: {
      border: '1px solid lightgrey',
      marginTop: '1em',
      backgroundColor: "#f9f9f9"
    },
    title: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    btnContainer: {
      position: 'fixed',
      top:'40%',
      display:'flex',
      flexDirection: 'column',
      alignItems:'center',
    },
    modalBtn: {
      marginLeft: '2em',
      marginBottom: '1.5em',
      fontSize: '1.1em',
      borderRadius: 100
    },
    dialogContentGrid : {
      padding: 10
    },
    submitTooltipLabel : {
        fontSize: '1.3em',
        fontWeight:'bold'
    },
  }));